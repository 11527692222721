import { validate as validateEmail } from "email-validator";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  ErrorToast,
  Toast,
  ToastType,
} from "@features/ui";
import { Button, Input } from "@transfr-inc/dashboard-components/forms";

import { useCareerPlan } from "@features/career-details/api/use-career-plan";

import { buttonTracking } from "@lib/tracking.helpers";

import shareImage from "@images/dash-share.png?url";

import "./index.scss";

export const Share = ({
  error,
  onClose,
  onDownload,
  onShare,
  onValidateEmail,
}) => {
  const [email, setEmail] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const {
    data: { career },
  } = useCareerPlan();

  useEffect(() => {
    if (error?.type === "email") {
      // Only validate the email when it changes if we already have an error
      // message, otherwise we will validate on blur. This prevents an error
      // message showing up as soon as the user starts typing.
      onValidateEmail(email);
    }
  }, [email, error?.type]);

  useEffect(() => {
    if (isEmailSent) {
      const timeoutId = setTimeout(() => setIsEmailSent(false), 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [isEmailSent]);

  const sendEmail = () => {
    setEmail("");
    setIsEmailSent(true);
    onShare(email);
  };

  return (
    <Card className="share-card">
      <CardHeader
        title={<h3>Share your goals for...</h3>}
        className="share-card-header"
        toolbar={
          <Button
            onClick={onClose}
            icon={["fa-regular", "xmark"]}
            {...buttonTracking("share", "close")}
          />
        }
      >
        <h1>{career?.title}</h1>
      </CardHeader>
      <CardBody className="share-card-body">
        <img src={shareImage} alt="share image" />
        <div className="content">
          <div>
            <p>
              Awesome work! We&rsquo;ve shared your goals with your counselor.
            </p>
            <p>
              Before you...leave... you can also share your progress with
              friends, family, or anyone who might be interested.
            </p>
          </div>
          <ErrorToast open={!!error} message={error?.msg} />
          <Toast
            open={isEmailSent}
            type={ToastType.success}
            message="Your plan has been shared!"
          />
          <Input
            id="share-email"
            label="Email Address"
            value={email}
            errors={error?.type === "email"}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={() => onValidateEmail(email)}
          />

          <div className="actions-container">
            <Button
              primary
              icon={["fasr", "arrow-up-from-bracket"]}
              onClick={sendEmail}
              disabled={!validateEmail(email)}
              {...buttonTracking("share", "submit")}
            >
              Share
            </Button>
            <div>OR</div>
            <Button
              icon={["fasr", "download"]}
              onClick={onDownload}
              {...buttonTracking("share", "download")}
            >
              Download (.pdf)
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

Share.displayName = "Share Card";

Share.propTypes = {
  error: PropTypes.shape({
    type: PropTypes.oneOf(["email", "pdf"]),
    msg: PropTypes.string,
  }),
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  onShare: PropTypes.func,
  onValidateEmail: PropTypes.func,
};
