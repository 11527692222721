import clsx from "clsx";
import PropTypes from "prop-types";

import "./headset-code.scss";

export const HeadsetCode = ({ code, className }) => {
  return (
    <div className={clsx("headset-code", className)}>
      <span>{code}</span>
    </div>
  );
};

HeadsetCode.displayName = "Headset Code";

HeadsetCode.propTypes = {
  code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};
