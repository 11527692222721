import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useSkillsQueryOptions = (careerId) => {
  const queryFn = useApiRequest({
    url: "/skills",
    params: { career_id: careerId },
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["skills", careerId],
    queryFn,
    enabled: !!careerId,
  };
};

export const useSkills = (careerId) => {
  return useQuery(useSkillsQueryOptions(careerId));
};
