import axios from "axios";

export const apiBaseUrl = import.meta.env.APP_SERVICE_URL;
const apiVersion = import.meta.env.APP_SERVICE_VERSION;

export const httpClient = axios.create({
  baseURL: `${apiBaseUrl}/${apiVersion}`,
});

httpClient.defaults.useResponseData = true;

httpClient.interceptors.request.use((config) => {
  // allow for calling non-versioned endpoints
  if (config.unversioned) {
    config.baseURL = apiBaseUrl;
  }
  // allow for overriding the api version
  else if (config.apiVersion) {
    config.baseURL = `${apiBaseUrl}/${config.apiVersion}`;
  }

  return config;
});

httpClient.interceptors.response.use(
  (response) => {
    if (response.config.useResponseData) {
      return response.data;
    }

    return response;
  },
  (error) => {
    if (error.config.passthroughCodes?.includes(error.response.status)) {
      throw error;
    }

    const { url } = error.config || {};
    const errorDetail = error.response ?? error;

    console.error(
      `[Request Error] - An error occurred while making request to ${url}`,
      error,
    );
    throw errorDetail;
  },
);
