import { Button } from "@transfr-inc/dashboard-components/forms";

import { buttonTracking } from "@lib/tracking.helpers";

import "./feedback.button.scss";

const onFeedbackClick = () => {
  window.open("http://bit.ly/trekfeedback", "_blank", "noreferrer");
};

export const FeedbackButton = () => {
  return (
    <div className="feedback-button">
      <Button
        icon={["fasr", "arrow-up-right-from-square"]}
        onClick={onFeedbackClick}
        {...buttonTracking("feedback")}
      >
        Feedback
      </Button>
    </div>
  );
};
