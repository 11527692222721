/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

export function CabinSmall({ hoverAnimation = {}, onClick, ...props }) {
  const { nodes, materials } = useGLTF("/models/cabin-small.glb");

  return (
    <group {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onClick={onClick}
        onUpdate={() => invalidate()}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_chimey.geometry}
          material={materials.MAT_chimey}
          position={[0.105, 0.365, 0.376]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_door_trim.geometry}
          material={materials.MAT_cabin_door_trim}
          position={[0.021, 0.134, -0.31]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_roof.geometry}
          material={materials.MAT_cabin_roof}
          position={[0.022, 0.355, 0.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_roof_snow.geometry}
          material={materials.sneeuw}
          position={[0.022, 0.405, 0.08]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_window.geometry}
          material={materials.MAT_window}
          position={[-0.212, 0.203, 0.072]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_window_trim.geometry}
          material={materials.MAT_cabin_window_trim}
          position={[-0.222, 0.203, 0.072]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_log048.geometry}
          material={materials.MAT_cabin_log}
          position={[0.023, 0.32, -0.289]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_log009.geometry}
          material={materials["MAT_front_cabin_wall-1"]}
          position={[-0.205, 0.452, 0.078]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_cabin_door.geometry}
          material={materials.MAT_cabin_door}
          position={[0.071, 0.069, -0.313]}
        />
      </motion.group>
    </group>
  );
}

useGLTF.preload("/models/cabin-small.glb");

CabinSmall.propTypes = {
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
