import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";

import { NavbarContext } from "./navbar";

import { useUpdateLinkTracking } from "@hooks/use-update-link-tracking";

import "./item.navbar.scss";

export const NavbarItem = ({
  title,
  icon,
  to,
  state,
  badge,
  position,
  className,
  children,
  ...navLinkConfig
}) => {
  const MotionNavLink = useMemo(() => motion(NavLink), []);
  const { isExpanded } = useContext(NavbarContext);

  useUpdateLinkTracking();

  const itemContent = useMemo(
    () =>
      children ?? (
        <>
          {icon && <FontAwesomeIcon className="icon" icon={["fasr", icon]} />}
          <span className="title">{title}</span>
          {badge && <span className="tx-badge-container">{badge}</span>}
        </>
      ),
    [badge, children, icon, title],
  );

  return (
    <MotionNavLink
      layout
      animate={isExpanded ? "expanded" : "collapsed"}
      className={clsx(
        "tx-navbar-item",
        isExpanded && "expanded",
        position,
        className,
      )}
      to={to}
      state={state}
      {...navLinkConfig}
    >
      {itemContent}
    </MotionNavLink>
  );
};

NavbarItem.display = "Navbar Item";

NavbarItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  badge: PropTypes.node,
  position: PropTypes.oneOf(["bottom", "top"]),
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  state: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};
