/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";
import { useEffect, useLayoutEffect } from "react";

import { invalidate, useThree } from "@react-three/fiber";
import { AnimatePresence, useMotionValue } from "framer-motion";
import { motion } from "framer-motion-3d";

import { STEP_TO_MAP } from "@context/journey.context";

import { Case, Switch } from "@features/ui";
import { clamp } from "@lib/app.helpers";
import { MapFour } from "./map-four";
import { MapOne } from "./map-one";
import { MapThree } from "./map-three";
import { MapTwo } from "./map-two";

// Values to calculate the camera settings
const MIN_ZOOM = 0.8;
const MAX_ZOOM = 1;
const MAX_CAMERA_Y = 460;
const MAX_CAMERA_Z = 470;
const MIN_CAMERA_Y = 380;
const MIN_CAMERA_Z = 400;
const MIN_FOV = 45;
const MAX_FOV = 50;

const ZOOM_DIFF = MAX_ZOOM - MIN_ZOOM;
const FOV_DIFF = MAX_FOV - MIN_FOV;
const CAMERA_Y_DIFF = MAX_CAMERA_Y - MIN_CAMERA_Y;
const CAMERA_Z_DIFF = MAX_CAMERA_Z - MIN_CAMERA_Z;

const cameraZoomClamp = clamp(MIN_ZOOM, MAX_ZOOM);

export const JourneyMapCanvas = ({
  dimensions,
  mapToTriggerAnimation,
  onMapAnimationComplete,
  onMoveToMap,
  onMoveToStep,
  scrollAnimationDuration,
  showMap,
  unlockedSteps,
  isPlanShared,
}) => {
  const { viewport, camera } = useThree();

  const { top, bottom } = dimensions;
  const { height: vHeight } = viewport;

  const mapZPosition = useMotionValue(0);

  // Since we do on-demand updates, we need to tell R3F to re-render when
  // animating.
  useEffect(
    () => mapZPosition.on("change", () => invalidate()),
    [mapZPosition],
  );

  const visibleHeight = vHeight + top + bottom;

  // Manipulating camera settings as a way to handle responsiveness
  useLayoutEffect(() => {
    const { aspect } = viewport;
    const zoom = cameraZoomClamp(aspect);
    const factor = (MAX_ZOOM - zoom) / ZOOM_DIFF;
    const fov = FOV_DIFF * factor + MIN_FOV;
    const cameraY = CAMERA_Y_DIFF * factor + MIN_CAMERA_Y;
    const cameraZ = MAX_CAMERA_Z - CAMERA_Z_DIFF * factor;

    // Set new camera settings
    camera.fov = fov;
    camera.position?.set(0, cameraY, cameraZ);
    camera.zoom = zoom;
    camera.lookAt(0, 0, 0);

    // Apply updated settings
    camera.updateProjectionMatrix();
  }, [viewport]);

  return (
    <group>
      <motion.group
        position-z={mapZPosition}
        animate={{
          z: -(showMap - 1) * visibleHeight,
        }}
        transition={{ duration: scrollAnimationDuration }}
      >
        <AnimatePresence mode="popLayout">
          <Switch option={showMap}>
            <Case value={4}>
              <group position-z={3 * visibleHeight}>
                <MapFour
                  isPlanShared={isPlanShared}
                  onMoveToStep={onMoveToStep}
                  unlockedSteps={unlockedSteps}
                />
              </group>
            </Case>
            <Case value={3}>
              <group position-z={2 * visibleHeight}>
                <MapThree onMoveToStep={onMoveToStep} />
              </group>
            </Case>
            <Case value={2}>
              <group position-z={visibleHeight}>
                <MapTwo
                  onMoveToStep={onMoveToStep}
                  triggerMapAnimation={
                    mapToTriggerAnimation === STEP_TO_MAP.MATCHES
                  }
                  onMapAnimationComplete={onMapAnimationComplete}
                />
              </group>
            </Case>
            <Case value={1}>
              <group>
                <MapOne
                  onMoveToStep={onMoveToStep}
                  onMoveToMap={onMoveToMap}
                  triggerMapAnimation={
                    mapToTriggerAnimation === STEP_TO_MAP.INT_INV
                  }
                  onMapAnimationComplete={onMapAnimationComplete}
                />
              </group>
            </Case>
          </Switch>
        </AnimatePresence>
      </motion.group>
    </group>
  );
};

JourneyMapCanvas.defaultProps = {
  scrollAnimationDuration: 0.5,
};

JourneyMapCanvas.propTypes = {
  dimensions: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    height: PropTypes.number,
  }),
  mapToTriggerAnimation: PropTypes.number,
  onMapAnimationComplete: PropTypes.func,
  onMoveToMap: PropTypes.func,
  onMoveToStep: PropTypes.func,
  scrollAnimationDuration: PropTypes.number,
  showMap: PropTypes.number,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
  isPlanShared: PropTypes.bool,
  onViewportChange: PropTypes.func,
};
