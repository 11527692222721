import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation } from "@tanstack/react-query";

export const useSetQuestionAnswer = (surveyId, onSuccess) => {
  const mutationFn = useApiRequest({
    url: `/surveys/${surveyId}/answer`,
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["interest-inventory-question-answer"],
    mutationFn: ({ questionId, answer }) =>
      mutationFn({ data: { question_id: questionId, answer } }),
    onSuccess: () => {
      onSuccess?.();
    },
  });
};
