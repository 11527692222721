import PropTypes from "prop-types";
import clsx from "clsx";

import "./divider.scss";

export const Divider = ({ position = "bottom", className }) => {
  return <div className={clsx("divider", position, className)}></div>;
};

Divider.displayName = "Divider";

Divider.propTypes = {
  position: PropTypes.oneOf(["top", "bottom"]),
  className: PropTypes.string,
};
