import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useUserJourneyStatusQueryOptions = () => {
  const queryFn = useApiRequest({
    url: "/users/me/journey-status",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["user-journey-status"],
    queryFn,
  };
};

export const useUserJourneyStatus = () => {
  return useQuery(useUserJourneyStatusQueryOptions());
};
