import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./range-bar.scss";

export const RangeBar = ({
  min,
  minLabel,
  max,
  maxLabel,
  value,
  className,
}) => {
  const barRef = useRef();
  const [indicatorStyles, setIndicatorStyles] = useState();

  useEffect(() => {
    if (barRef.current) {
      try {
        const { width } = barRef.current.getBoundingClientRect();
        const indicatorPosition = (width / (max - min)) * (value - min);
        setIndicatorStyles(indicatorPosition);
      } catch (error) {
        console.error(
          `Error while calculating range bar indicator position. For more details:`,
          error,
        );
      }
    }
  }, [value]);

  return (
    <div className={clsx("range-bar", className)}>
      <div ref={barRef} className="bar">
        <motion.div
          className="indicator"
          initial={{ x: 0 }}
          animate={{ x: indicatorStyles }}
          transition={{ duration: 1 }}
        ></motion.div>
      </div>
      <div className="labels-container">
        <span>{minLabel}</span>
        <span>{maxLabel}</span>
      </div>
    </div>
  );
};

RangeBar.displayName = "Range Bar";

RangeBar.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  minLabel: PropTypes.string,
  max: PropTypes.number.isRequired,
  maxLabel: PropTypes.string,
  className: PropTypes.string,
};
