import { initLightIcons } from "./light.icons";
import { initRegularIcons } from "./regular.icons";
import { initSharpIcons } from "./sharp.icons";
import { initSolidIcons } from "./solid.icons";

export const initIcons = () => {
  initRegularIcons();
  initSolidIcons();
  initLightIcons();
  initSharpIcons();
};
