import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

import { useCourseQueryOptions } from "@features/high-school/api/use-courses";
import { useCertificationsQueryOptions } from "@features/post-high-school/api/use-certifications";
import { useInstitutionsQueryOptions } from "@features/post-high-school/api/use-institutions";
import { useSkillsQueryOptions } from "@features/skills/api/use-skills";

export const usePrefetchPathway = (careerId) => {
  const queryClient = useQueryClient();

  const skillsQueryOptions = useSkillsQueryOptions(careerId);
  const certificationsQueryOptions = useCertificationsQueryOptions(careerId);
  const institutionsQueryOptions = useInstitutionsQueryOptions(careerId);
  const courseQueryOptions = useCourseQueryOptions(careerId);

  useEffect(() => {
    if (careerId) {
      queryClient.prefetchQuery(skillsQueryOptions);
      queryClient.prefetchQuery(certificationsQueryOptions);
      queryClient.prefetchQuery(institutionsQueryOptions);
      queryClient.prefetchQuery(courseQueryOptions);
    }
  }, [careerId]);
};
