import PropTypes from "prop-types";

import Skeleton from "react-loading-skeleton";

import { ErrorToast } from "@features/ui";

import { EmptyArray } from "@lib/app.helpers";

import "./certification-list.scss";

export const CertificationList = ({ certifications, isError, isPending }) => {
  if (isError) {
    return (
      <div className="error-container">
        <ErrorToast
          open
          message="Not currently available. Please try again later."
        />
      </div>
    );
  }

  return (
    <div className="certifications">
      {isPending
        ? EmptyArray(3).map((_, index) => (
            <Skeleton key={index} width="19rem" height="0.875rem" />
          ))
        : certifications.map((certification, index) => (
            <div key={`${certification}.${index}`}>{certification}</div>
          ))}
    </div>
  );
};

CertificationList.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.string),
  isError: PropTypes.bool,
  isPending: PropTypes.bool,
};
