import { ToastType, Toast } from "./toast";
import PropTypes from "prop-types";

const GeneralErrorMessage = "Something went wrong. Please try again.";

export const ErrorToast = ({ open, message, ...props }) => {
  return (
    <Toast
      type={ToastType.error}
      open={open}
      message={message || GeneralErrorMessage}
      {...props}
    ></Toast>
  );
};

ErrorToast.displayName = "Error Toast";

ErrorToast.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.node,
};
