import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { createPortal } from "react-dom";

import "./loader.scss";

export const Loader = ({ show, text = "loading...", className }) => {
  const portalContainerElement = useMemo(
    () => document.querySelector("body"),
    [],
  );

  return createPortal(
    <AnimatePresence mode="popLayout">
      {show && (
        <motion.div
          key="loader"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={clsx("tx-loader", className)}
        >
          <FontAwesomeIcon
            className="icon"
            icon={["fasl", "arrows-rotate"]}
            spin
          />
          <span>{text}</span>
        </motion.div>
      )}
    </AnimatePresence>,
    portalContainerElement,
    "tx-loader",
  );
};

Loader.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
};
