import clsx from "clsx";
import PropTypes from "prop-types";

import "./section.scss";

export const Section = ({ className, children }) => {
  return <div className={clsx("section-container", className)}>{children}</div>;
};

Section.displayName = "Section";

Section.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
