import { RangeBar } from "@features/ui";

import PropTypes from "prop-types";

import "./payment-range.scss";

export const PaymentRange = ({ min, max, value }) => {
  return (
    <div className="payment-range-container">
      <div className="header">
        <div className="title-container">
          <span>Average Pay</span>
          <span className="title">${value}/hr</span>
        </div>
        <span>*Data gathered from recent job descriptions.</span>
      </div>
      <div className="payment-bar-titles">
        <span>Low</span>
        <span>High</span>
      </div>
      <RangeBar
        min={min}
        minLabel={`$${min}/hr`}
        max={max}
        maxLabel={`$${max}/hr`}
        value={value}
      />
    </div>
  );
};

PaymentRange.displayName = "Payment Range";

PaymentRange.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};
