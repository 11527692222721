/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import PropTypes from "prop-types";
import React from "react";
import { SkeletonUtils } from "three-stdlib";

export function Shire({ ...props }) {
  const { scene } = useGLTF("/models/shire.glb");
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);

  return (
    <group {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_bck_tree.geometry}
        material={materials.MAT_tree2}
        position={[6.089, 0.59, -0.308]}
        rotation={[-Math.PI, 1.485, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_main_tree.geometry}
        material={materials.MAT_tree1}
        position={[2.132, 1.001, -0.186]}
        rotation={[-Math.PI, 1.485, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_floor.geometry}
        material={materials.MAT_shire_ground}
        position={[3.218, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_extra_grass.geometry}
        material={materials.PaletteMaterial001}
        position={[1.803, -0.005, -0.073]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_path_dashed_line.geometry}
        material={materials.PaletteMaterial001}
        position={[-0.471, 0, -0.56]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_ladder.geometry}
        material={materials.MAT_ladder}
        position={[1.099, 0.189, 0.229]}
        rotation={[0.507, 0.786, -0.375]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_door.geometry}
        material={materials.MAT_house_door}
        position={[0.973, 0.212, -0.423]}
        rotation={[0, -0.155, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_roof.geometry}
        material={materials.MAT_house_trim_roof}
        position={[0.934, 0.399, -0.303]}
        rotation={[-0.008, -0.147, 0]}
      />
      <group
        position={[-1.494, -0.004, 0.024]}
        rotation={[Math.PI, -1.51, Math.PI]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001.geometry}
          material={materials["MAT_ground-3"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder001_1.geometry}
          material={materials["MAT_dirt.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_sml_land_pc.geometry}
        material={materials["MAT_ground-2"]}
        position={[-1.497, 0.047, -0.451]}
        rotation={[-3.134, -0.251, -3.135]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_sml_land_pc002.geometry}
        material={materials.MAT_grass1}
        position={[-1.822, 0.031, 0.412]}
        rotation={[-0.01, -0.682, -0.002]}
      />
      <group
        position={[-1.494, -0.004, 0.024]}
        rotation={[Math.PI, -1.51, Math.PI]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003.geometry}
          material={materials["MAT_grass_blade.001"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder003_1.geometry}
          material={materials["MAT_rock.001"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_main_stump.geometry}
        material={materials.MAT_main_stump}
        position={[1.779, 0.001, -0.033]}
        rotation={[Math.PI, -0.2, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_stump-bck"].geometry}
        material={materials["MAT_ground.001"]}
        position={[5.875, -0.017, -0.489]}
        rotation={[-Math.PI, 1.139, -Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_stump_step.geometry}
        material={materials.MAT_tree_step}
        position={[0.921, 0.035, -0.311]}
        rotation={[Math.PI, -1.51, Math.PI]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO-TINY_TREE"].geometry}
        material={materials.MAT_tiny_tree}
        position={[1.173, 0.026, -0.606]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_window_trim.geometry}
        material={materials.MAT_house_trim_window}
        position={[1.416, 0.282, -0.719]}
        rotation={[-1.608, -0.023, -2.593]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_window.geometry}
        material={materials.PaletteMaterial002}
        position={[1.416, 0.281, -0.718]}
        rotation={[0, -1.065, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_door_trim005.geometry}
        material={materials.MAT_house_trim}
        position={[0.976, 0.287, -0.296]}
        rotation={[-0.013, -0.007, 0.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_globe_light_string.geometry}
        material={materials.PaletteMaterial003}
        position={[1.461, 0.722, 0.181]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_bush-1"].geometry}
        material={materials.MAT_shrub}
        position={[1.669, 0.021, 0.876]}
        rotation={[0, 1.051, 0]}
      />
    </group>
  );
}

useGLTF.preload("/models/shire.glb");

Shire.propTypes = {
  onPathClick: PropTypes.func,
};
