import { ErrorToast, ListItemLoader } from "@features/ui";
import { EmptyArray } from "@lib/app.helpers";
import PropTypes from "prop-types";
import { CareerItem } from "./career-item";

export const CareerMatchesList = ({
  careerMatches,
  careerSelected,
  isError,
  isLoading,
  onCareerClick,
}) => {
  const { careerId, submittedAt } = careerSelected;

  if (isLoading) {
    return EmptyArray(5).map((_, index) => (
      <ListItemLoader key={index}></ListItemLoader>
    ));
  }

  const renderItems =
    careerMatches?.map((career, index) => (
      <CareerItem
        career={career}
        index={index + 1}
        key={`career.${career.transfrId}`}
        onClick={onCareerClick}
        selected={careerId === career.id && !!submittedAt}
        hidden={career?.hidden}
      />
    )) ?? [];

  if (isError) {
    renderItems.push(
      <ErrorToast open message="Something went wrong. Please try again." />,
    );
  }

  return renderItems;
};

CareerMatchesList.displayName = "Career Matches List";

CareerMatchesList.propTypes = {
  careerMatches: PropTypes.arrayOf(PropTypes.object),
  careerSelected: PropTypes.object,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCareerClick: PropTypes.func,
};
