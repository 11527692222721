import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useSubmitCareerPlan = (careerPlanId, onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: `career-plans/${careerPlanId}`,
    method: HttpMethod.Post,
    data: { submit: true },
  });

  return useMutation({
    mutationKey: ["submit-career-plan", careerPlanId],
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["career-plan"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["user-journey-status"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["career-matches"],
      });
      onSuccess?.();
    },
  });
};
