import PropTypes from "prop-types";

import {
  InstitutionList,
  SectionWithHeading,
} from "@features/post-high-school/components";

export const ProgramsSection = ({ careerId, onInstitutionClick }) => (
  <>
    <SectionWithHeading
      heading="Programs"
      subheading="We've found the following training programs near you."
    />
    <InstitutionList
      careerId={careerId}
      onInstitutionClick={onInstitutionClick}
    />
  </>
);

ProgramsSection.propTypes = {
  careerId: PropTypes.number,
  onInstitutionClick: PropTypes.func,
};
