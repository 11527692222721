import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import {
  flatMapPaginatedData,
  getNextPageParam,
} from "@lib/pagination.helpers";
import { useInfiniteQuery } from "@tanstack/react-query";

export const useCareerMatches = ({ enabled, limit } = {}) => {
  const url = `users/me/careers?limit=${limit ?? 5}`;
  const queryFn = useApiRequest({
    url,
    method: HttpMethod.Get,
  });

  return useInfiniteQuery({
    queryKey: ["career-matches", limit],
    queryFn: async ({ pageParam: url }) =>
      queryFn({ url, useResponseData: false }),
    select: flatMapPaginatedData,
    initialPageParam: url,
    getNextPageParam,
    enabled: enabled ?? true,
  });
};
