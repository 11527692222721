import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateInterestInventory = ({
  onSuccess,
  invalidateUserJourney,
} = {}) => {
  const queryClient = useQueryClient();

  const mutationFn = useApiRequest({
    url: "/users/me/survey",
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["create-interest-inventory"],
    mutationFn: () => mutationFn({}),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["interest-inventory"],
        exact: true,
      });

      invalidateUserJourney &&
        queryClient.invalidateQueries({
          queryKey: ["user-journey"],
          exact: true,
        });
      onSuccess?.();
    },
  });
};
