import { useCursor } from "@react-three/drei";
import { useState } from "react";

const defaultHoverValues = {
  scale: 1.2,
};

export const useHoverAnimation = ({ values, enabled = true } = {}) => {
  const [isHovered, setIsHovered] = useState();
  const [animate, setAnimate] = useState();
  const [isEnabled, setIsEnabled] = useState(enabled);

  const variants = {
    hover: {
      ...defaultHoverValues,
      ...values,
    },
  };

  useCursor(isHovered);

  const onPointerOver = () => {
    if (isEnabled) {
      setIsHovered(true);
      setAnimate("hover");
    }
  };
  const onPointerOut = () => {
    if (isEnabled) {
      setIsHovered(false);
      setAnimate();
    }
  };

  return {
    animate,
    variants,
    isHovered,
    onPointerOut,
    onPointerOver,
    enabled: setIsEnabled,
  };
};
