import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useDeleteUserPreliminaryCareers = (onSuccess) => {
  const queryClient = useQueryClient();

  const mutationFn = useApiRequest({
    url: "/users/me/preliminary-careers",
    method: HttpMethod.Delete,
  });

  return useMutation({
    mutationKey: ["delete-user-preliminary-careers"],
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-preliminary-careers"],
        exact: true,
      });

      onSuccess?.();
    },
  });
};
