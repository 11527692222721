import { parseLinkHeader } from "@web3-storage/parse-link-header";

import { apiBaseUrl } from "src/api";

/**
 * Helper for useInfiniteQuery's getNextPageParam for paginated endpoints that
 * use the Link header. This will simply parse the header and return the
 * `rel=next` link.
 */
export const getNextPageParam = (lastResponse) => {
  const links = parseLinkHeader(lastResponse.headers.link);
  if (links) {
    const nextLink = links.next;
    if (nextLink) {
      return `${apiBaseUrl}${decodeURIComponent(nextLink.url)}`;
    }
  }
};

/**
 * Helper for useInfiniteQuery to reduce the "page" structure into a flat array
 * of records. This is helpful when pagination is "additive" and you just want
 * to display everything that has been (and will be) retrieved in a single long
 * list. This selector is probably not appropriate if your data is also
 * displayed in a paginated fashion.
 */
export const flatMapPaginatedData = (data) =>
  data?.pages.flatMap((page) => page.data);
