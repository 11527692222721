import { useContext } from "react";

import { JourneyContext } from "@context/journey.context";

export const useJourney = () => {
  const {
    currentMap,
    isJourneyLoading,
    requestMapChange,
    requestStepChange,
    nextMapUnlocked,
    unlockedSteps,
    journeyMapView,
    setJourneyMapView,
  } = useContext(JourneyContext);

  return {
    currentMap,
    isJourneyLoading,
    requestMapChange,
    requestStepChange,
    nextMapUnlocked,
    unlockedSteps,
    journeyMapView,
    setJourneyMapView,
  };
};
