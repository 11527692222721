import PropTypes from "prop-types";

import { ListItem } from "@features/ui";

import "./institution-item.scss";

export const InstitutionItem = ({ index, institution, onClick }) => {
  return (
    <ListItem
      key={institution.name}
      className="institution-item"
      index={index + 1}
      rightIcon={
        institution.webAddr ? ["fasr", "arrow-up-right-from-square"] : []
      }
      onClick={() => onClick(institution)}
    >
      <div className="header">
        <span>{institution.name}</span>
      </div>
      {institution.programs.map((program) => (
        <div className="description" key={program}>
          {program}
        </div>
      ))}
    </ListItem>
  );
};

InstitutionItem.propTypes = {
  index: PropTypes.number,
  institution: PropTypes.shape({
    name: PropTypes.string,
    webAddr: PropTypes.string,
    programs: PropTypes.arrayOf(PropTypes.string),
  }),
  onClick: PropTypes.func,
};
