import clsx from "clsx";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Button, Input } from "@transfr-inc/dashboard-components/forms";
import { ErrorToast } from "@features/ui/toast";

import { buttonTracking } from "@lib/tracking.helpers";

import "./login-form.scss";

export const LoginForm = ({ className, error, loading, onSignIn }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openError, setOpenError] = useState(false);

  useEffect(() => {
    setOpenError(!!error);
  }, [error]);

  return (
    <form
      className={clsx("login-form-content", className)}
      onSubmit={(e) => onSignIn(e, email, password)}
    >
      <ErrorToast
        open={openError}
        onClose={() => setOpenError()}
        message={error?.detail}
        closable
      />
      <div className="login-fields-container">
        <Input
          id="login-email"
          label="Email or Username"
          value={email}
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input
          id="login-password"
          label="Password"
          type="password"
          value={password}
          disabled={loading}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button
        disabled={loading}
        loader={loading}
        type="submit"
        {...buttonTracking("login", "sign-in")}
      >
        Sign In
      </Button>
      <div className="login-form-forgot-password">
        <Link to="/forgot-password">Forgot Password?</Link>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
  error: PropTypes.shape({ detail: PropTypes.string }),
  loading: PropTypes.bool,
  onSignIn: PropTypes.func,
};
