import clsx from "clsx";
import PropTypes from "prop-types";

import { ListItem } from "@features/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./shortcut.list-item.scss";
import { Tooltip } from "@features/ui/tooltip";

const getItemIcon = (completed, unlocked) => {
  if (completed) {
    return "check";
  } else if (!unlocked) {
    return "lock";
  }
};

export const ShortcutListItem = ({ completed, onClick, step, unlocked }) => {
  const itemIcon = getItemIcon(completed, unlocked);

  return (
    <ListItem
      disabled={!unlocked}
      className={clsx("shortcut-list-item", !unlocked && "disabled")}
      onClick={onClick}
    >
      <div className="step-state-icon">
        <span className={clsx(completed && "completed")}>
          {itemIcon && (
            <Tooltip content={step.lockMessage} disabled={unlocked}>
              <FontAwesomeIcon icon={["fasr", itemIcon]} />
            </Tooltip>
          )}
        </span>
      </div>
      <div className="step-summary">
        <h3>{`${step.title} ${step?.required ? "*" : ""}`.trim()}</h3>
        <p>{step.description}</p>
      </div>
    </ListItem>
  );
};

ShortcutListItem.displayName = "Shortcut List Item";

ShortcutListItem.propTypes = {
  completed: PropTypes.bool,
  onClick: PropTypes.func,
  step: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    lockMessage: PropTypes.string,
    code: PropTypes.string,
    required: PropTypes.bool,
  }),
  unlocked: PropTypes.bool,
};
