export const ONE_DIGIT_DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  year: "2-digit",
  month: "numeric",
  day: "numeric",
});

export const TWO_DIGIT_DATE_FORMATTER = new Intl.DateTimeFormat("en-US", {
  year: "2-digit",
  month: "2-digit",
  day: "2-digit",
});

const US_DATE_PART_POSITIONS = ["month", "day", "year"];

const defaults = {
  date: new Date(),
  formatter: TWO_DIGIT_DATE_FORMATTER,
  order: US_DATE_PART_POSITIONS,
  separator: "-",
};

export const formatDate = (args) => {
  const { date, formatter, order, separator } = { ...defaults, ...args };

  const allParts = formatter.formatToParts(date);
  const datePartsInOrder = allParts.reduce((acc, part) => {
    const index = order.indexOf(part.type);
    if (index >= 0) {
      acc[index] = part.value;
    }

    return acc;
  }, []);

  return datePartsInOrder.join(separator);
};
