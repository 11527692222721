import { arrow, flip, shift } from "@floating-ui/core";
import { autoUpdate, offset, useFloating } from "@floating-ui/react-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";

import "./tooltip.scss";

export const Tooltip = ({
  allowInteraction,
  animationHover,
  animationTap,
  children,
  className,
  clickDisabled,
  content,
  disabled,
  hoverDisabled,
  left = 0,
  maxWidth,
  offset: offsetProp = 10,
  open,
  placement: placementProp = "top",
  top = -2,
}) => {
  const [isOpen, setIsOpen] = useState(open ?? false);
  const [arrowRef, setArrowRef] = useState();

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const { refs, floatingStyles, middlewareData, placement } = useFloating({
    placement: placementProp,
    middleware: [
      offset(offsetProp),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { arrow: arrowPosition } = middlewareData;

  const initialTop = useMemo(
    () => (placement == "top" ? offsetProp : -1 * offsetProp),
    [placement],
  );

  const toggleTooltip = (value) => {
    setIsOpen(value);
  };

  const onClickHandler = (event) => {
    if (content && !clickDisabled) {
      event.stopPropagation();
      toggleTooltip(!isOpen);
    }
  };

  const onHoverHandler = (value) => {
    if (content && !hoverDisabled) {
      toggleTooltip(value);
    }
  };

  if (disabled) {
    return children;
  }

  return (
    <div className={clsx("tx-tooltip", className)}>
      <motion.div
        ref={refs.setReference}
        onClick={onClickHandler}
        onHoverStart={() => onHoverHandler(true)}
        onHoverEnd={() => onHoverHandler(false)}
        className="tooltip-reference"
        whileHover={animationHover}
        whileTap={animationTap}
      >
        {children}
      </motion.div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, top: initialTop }}
            animate={{
              opacity: 1,
              top,
              transitionEnd: {
                pointerEvents: allowInteraction ? "auto" : "none",
              },
            }}
            exit={{ opacity: 0, top: initialTop }}
            ref={refs.setFloating}
            style={{ ...floatingStyles, maxWidth, left }}
            className="tooltip-element"
          >
            {content}
            <div
              className="tooltip-element-arrow"
              ref={setArrowRef}
              style={{
                left: arrowPosition?.x - left,
                top: placement === "top" ? arrowPosition?.y : 0,
              }}
            ></div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

Tooltip.displayName = "Tooltip";

Tooltip.propTypes = {
  allowInteraction: PropTypes.bool,
  animationHover: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  animationTap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  clickDisabled: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  hoverDisabled: PropTypes.bool,
  left: PropTypes.number,
  maxWidth: PropTypes.number,
  offset: PropTypes.number,
  open: PropTypes.bool,
  placement: PropTypes.oneOf(["top", "bottom"]),
  top: PropTypes.number,
};
