import PropTypes from "prop-types";

import { ErrorToast, ListItemLoader } from "@features/ui";
import { EmptyArray } from "@lib/app.helpers";

import { useInstitutions } from "../api/use-institutions";
import { InstitutionItem } from "./institution-item";

export const InstitutionList = ({ careerId, onInstitutionClick }) => {
  const {
    data: institutions,
    isError: isInstitutionsError,
    isPending: isInstitutionsPending,
  } = useInstitutions(careerId);

  if (isInstitutionsPending) {
    return EmptyArray(2).map((_, index) => (
      <ListItemLoader key={`institution.${index}`} />
    ));
  } else if (isInstitutionsError) {
    return (
      <div className="error-container">
        <ErrorToast
          open
          message="Not currently available. Please try again later."
        />
      </div>
    );
  }

  return institutions.map((institution, index) => (
    <InstitutionItem
      key={institution.name}
      index={index}
      institution={institution}
      onClick={onInstitutionClick}
    />
  ));
};

InstitutionList.displayName = "Institutions List";

InstitutionList.propTypes = {
  careerId: PropTypes.number,
  onInstitutionClick: PropTypes.func,
};
