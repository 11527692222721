import { trackLinkClick } from "@snowplow/browser-plugin-link-click-tracking";
import PropTypes from "prop-types";

import { Card, CardBody, CardHeader } from "@features/ui";
import { buttonTracking } from "@lib/tracking.helpers";
import { Button } from "@transfr-inc/dashboard-components/forms";

import postHighSchool from "@images/post-high-school.png?url";

import { CertificationsSection } from "./components/certifications.section";
import { ProgramsSection } from "./components/programs.section";
import "./index.scss";

const onInstitutionClick = (institution) => {
  let url = institution.webAddr;
  if (!url) {
    return;
  }

  if (!url.startsWith("http")) {
    // Ideally we'd use https, but in testing at least one website didn't load
    // with https whereas they all loaded with http (and most redirected
    // automatically to https).
    url = `http://${url}`;
  }
  window.open(url, "_blank", "noreferrer");

  trackLinkClick({
    targetUrl: url,
    elementContent: institution.name,
  });
};

export const PostHighSchool = ({ careerId, onClose }) => {
  return (
    <Card className="post-high-school-card">
      <CardHeader
        title={<h1>Extended Learning</h1>}
        toolbar={
          <Button
            onClick={onClose}
            icon={["fa-regular", "xmark"]}
            {...buttonTracking("post-high-school", "close")}
          />
        }
      />
      <CardBody className="post-high-school-card-body">
        <img className="post-high-school-image" src={postHighSchool} />
        <CertificationsSection careerId={careerId} />
        <ProgramsSection
          careerId={careerId}
          onInstitutionClick={onInstitutionClick}
        />
      </CardBody>
    </Card>
  );
};

PostHighSchool.displayName = "Post High School Card";

PostHighSchool.propTypes = {
  careerId: PropTypes.number,
  onClose: PropTypes.func,
};
