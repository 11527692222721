import clsx from "clsx";
import PropTypes from "prop-types";

import "./skill.icon.scss";

const defaultImageUrl = `/images/skills/default.png`;

export const SkillIcon = ({ skill, className }) => {
  const { id, name } = skill;
  const imageUrl = `/images/skills/${id}.png`;

  const handleError = ({ target }) => {
    target.src = defaultImageUrl;
  };

  return (
    <div className={clsx("skill-icon-container", className)}>
      <img
        src={imageUrl}
        alt={`Skill icon for ${name}`}
        onError={handleError}
      />
      <span>{name}</span>
    </div>
  );
};

SkillIcon.displayName = "Skill Icon";

SkillIcon.propTypes = {
  skill: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
};
