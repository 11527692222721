import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useGoals = (careerId) => {
  const queryFn = useApiRequest({
    url: `/careers/${careerId}/goals`,
    method: HttpMethod.Get,
  });

  return useQuery({
    queryKey: ["career-goals", careerId],
    queryFn,
    placeholderData: {
      short: [],
      long: [],
    },
    enabled: !!careerId,
  });
};
