import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDeleteCareerPlan = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: "/users/me/career-plan",
    method: HttpMethod.Delete,
  });

  return useMutation({
    mutationKey: ["delete-career-plan"],
    mutationFn: () => mutationFn({}),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["career-plan"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["user-journey-status"],
        exact: true,
      });
      onSuccess?.();
    },
  });
};
