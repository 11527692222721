import PropTypes from "prop-types";

import { HighSchool } from "@features/high-school";
import { Sheet } from "@features/ui";

import { useUserJourney } from "../api/use-user-journey";

export const HighSchoolSheet = ({ open, onClose }) => {
  const {
    data: { currentJourneyCareer },
  } = useUserJourney();

  return (
    <Sheet open={open} className="high-school-sheet">
      <HighSchool careerId={currentJourneyCareer?.id} onClose={onClose} />
    </Sheet>
  );
};

HighSchoolSheet.displayName = "High School Sheet";

HighSchoolSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
