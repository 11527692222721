import PropTypes from "prop-types";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { Card, CardBody, CardFooter, CardHeader, Modal } from "@features/ui";

import { buttonTracking } from "@lib/tracking.helpers";

import "./goals-start-over-confirmation.modal.scss";

export const GoalsStartOverConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  onCancel,
  careerTitle,
  isNewCareer,
}) => {
  const toolbar = (
    <Button
      icon={["fa-regular", "xmark"]}
      onClick={onClose}
      {...buttonTracking("goals-start-over-confirmation-modal", "close")}
    />
  );

  const message = isNewCareer ? (
    <span>
      Would you like to setup goals for this new career and erase your previous
      goals for <strong>{careerTitle}?</strong> This can&apos;t be undone.
    </span>
  ) : (
    <span>
      Would you like to start Trek from the beginning? We will remove your
      previous pathway and goals for <strong>{careerTitle}</strong>. This
      can&apos;t be undone.
    </span>
  );

  return (
    <Modal
      key="goals-start-over"
      modalClassName="goals-start-over-confirmation-modal"
      open={open}
    >
      <Card>
        <CardHeader title={<h1>Start Over?</h1>} toolbar={toolbar} />
        <CardBody>{message}</CardBody>
        <CardFooter>
          <Button
            destructive
            onClick={onConfirm}
            {...buttonTracking("goals-start-over-confirmation-modal", "yes")}
          >
            Yes, start over
          </Button>
          <Button
            onClick={onCancel}
            {...buttonTracking("goals-start-over-confirmation-modal", "no")}
          >
            No, cancel
          </Button>
        </CardFooter>
      </Card>
    </Modal>
  );
};

GoalsStartOverConfirmationModal.displayName =
  "Goals Start Over Confirmation Modal";

GoalsStartOverConfirmationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  careerTitle: PropTypes.string,
  isNewCareer: PropTypes.bool,
};
