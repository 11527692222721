import { JourneyMapFooter, JourneyMapHeader } from "../components";
import { Loader } from "@features/ui";

export const JourneyMapLoading = () => {
  return (
    <div className="journey-map-page">
      <Loader show />
      <JourneyMapHeader />
      <JourneyMapFooter />
    </div>
  );
};
