import PropTypes from "prop-types";

import { EmptyArray } from "@lib/app.helpers";
import { Section, SkillIcon, SkillIconLoader, SubHeading } from "@features/ui";

import "./skills.section.scss";

export const SkillsSection = ({ skills, loading }) => {
  if (!skills && !loading) {
    return;
  }

  return (
    <Section className="skills-section">
      <SubHeading>Skills</SubHeading>
      <div className="skill-list">
        {loading
          ? EmptyArray(6).map((_, index) => (
              <SkillIconLoader key={`skill-icon-loader.${index}`} />
            ))
          : skills.map((skill) => (
              <SkillIcon key={`skill-icon.${skill.id}`} skill={skill} />
            ))}
      </div>
    </Section>
  );
};

SkillsSection.displayName = "Career Skills Section";

SkillsSection.propTypes = {
  loading: PropTypes.bool,
  skills: PropTypes.array,
};
