import { SubHeading } from "@features/ui";
import PropTypes from "prop-types";

export const ForgotPasswordInstructions = ({ formSubmitted }) => {
  const instructions = formSubmitted ? (
    <>
      If an account exists you will receive an email allowing you to reset your
      password. If you can&apos;t find the email, check your spam folder or
      contact customer support for help.
    </>
  ) : (
    <>Please enter the email associated with your Trek account.</>
  );

  return <SubHeading>{instructions}</SubHeading>;
};

ForgotPasswordInstructions.propTypes = {
  formSubmitted: PropTypes.bool,
};
