/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

export const PineCones = ({ hoverAnimation = {}, onClick, ...props }) => {
  const { nodes, materials } = useGLTF("/models/pine-cones.glb");

  return (
    <group {...props}>
      <motion.mesh
        castShadow
        receiveShadow
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onClick={onClick}
        onUpdate={() => invalidate()}
        geometry={nodes.GEO_pine_cone.geometry}
        material={materials.MAT_pineCone}
        position={[0.1, 0.113, -0.022]}
      />
    </group>
  );
};

useGLTF.preload("/models/pine-cones.glb");

PineCones.propTypes = {
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
