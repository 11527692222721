import { Tag } from "@features/ui";
import PropTypes from "prop-types";

import "./interest-tags.scss";

export const InterestTags = ({ tags }) => {
  if (!tags || tags.length === 0) {
    return;
  }

  return (
    <div className="interest-tags-container">
      {tags.map((tag) => (
        <Tag key={tag} value={tag} />
      ))}
    </div>
  );
};

InterestTags.displayName = "Interest Tags";

InterestTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};
