import clsx from "clsx";
import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import "./overflow.text.scss";

export const OverflowText = ({ value, className }) => {
  const containerRef = useRef();

  useEffect(() => {
    const container = containerRef.current;
    const parent = container?.parentNode;

    if (parent) {
      parent.style.minWidth = 0;
    }
  }, [containerRef.ref]);

  return (
    <div
      ref={containerRef}
      content={value}
      className={clsx("tx-overflow-text", className)}
    >
      <span className="value-container">{value}</span>
    </div>
  );
};

OverflowText.displayName = "Overflow Text";

OverflowText.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string,
};
