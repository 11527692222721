const AppFeaturesPrefix = "FEATURE__";
const AppFeaturesPrefixLength = AppFeaturesPrefix.length;
const EnvVars = import.meta.env;

export const ALT_LOGIN = "ALT_LOGIN";
export const MAP_3D = "MAP_3D";

const appFeaturesMap = new Map(
  Object.entries(EnvVars)
    .map(([key, value]) => {
      if (key.startsWith(AppFeaturesPrefix)) {
        const propKey = key.slice(AppFeaturesPrefixLength);
        const boolValue = value.toLowerCase() === "true";
        return [propKey, boolValue];
      }
    })
    .filter(Boolean),
);

export const isEnabled = (featureKey) =>
  appFeaturesMap.get(featureKey) ?? false;

export const is3DMapsEnabled = () => isEnabled("MAP_3D");
