const vowels = new Set("aeiouAEIOU");

// This should do, but we could always add a large dependency to do this "even
// better" if we wanted: https://github.com/EamonNerbonne/a-vs-an/
// But it would probably be better to just add exceptions if/when we find them.
export const aOrAn = (text, capitalize = false) => {
  if (!text) {
    return "";
  }

  let firstLetter = "a";
  if (capitalize) {
    firstLetter = "A";
  }

  return firstLetter + (vowels.has(text[0]) ? "n " : " ") + text;
};

export const toCamelCase = (str) =>
  str
    ?.toLowerCase()
    .split("_")
    .reduce(
      (acc, part, index) =>
        acc + (index === 0 ? part : part[0].toUpperCase() + part.slice(1)),
      "",
    );
