import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { ButtonVariants } from "@lib/animation.helpers";
import { Tooltip } from "../tooltip/tooltip";

export const InfoIcon = ({ description, className, tooltipProps, variant }) => {
  return (
    <Tooltip
      content={description}
      className={className}
      animationHover={ButtonVariants.hover}
      animationTap={ButtonVariants.tap}
      {...tooltipProps}
    >
      <FontAwesomeIcon icon={[variant ?? "fa-regular", "circle-info"]} />
    </Tooltip>
  );
};

InfoIcon.displayName = "Info Icon";

InfoIcon.propTypes = {
  description: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  tooltipProps: PropTypes.object,
};
