import PropTypes from "prop-types";

import { Case, ErrorToast, Loader, Switch } from "@features/ui";
import { AnimatePresence } from "framer-motion";

import { PreliminaryCareers } from "../components/preliminary-careers";
import { InventoryQuestion } from "../components/question";

import { useSetUserPreliminaryCareers } from "../api/use-set-user-preliminary-careers";
import { SURVEY_STATES } from "../hooks/use-survey-state";

import "./preliminary-careers.view.scss";

const preliminaryCareerQuestion = {
  question: "Do you already have a career in mind?",
  tag: "career",
};

export const PreliminaryCareersView = ({
  surveyStateName,
  onAnswerInitialQuestion,
  onAnswerPreliminaryCareer,
}) => {
  const {
    mutateAsync: setPreliminaryCareer,
    isPending,
    isError,
  } = useSetUserPreliminaryCareers();

  const onSubmitCareer = async ({ answer }) => {
    const { onetCode } = answer;
    await setPreliminaryCareer({ onetCodes: [onetCode] });
    onAnswerPreliminaryCareer(onetCode);
  };

  return (
    <div className="preliminary-careers-view">
      <Loader show={isPending} text="Saving answer..." />
      <ErrorToast open={isError} />
      <AnimatePresence mode="popLayout">
        <Switch option={surveyStateName}>
          <Case value={SURVEY_STATES.askPrelim}>
            <InventoryQuestion
              value={preliminaryCareerQuestion}
              onAnswer={onAnswerInitialQuestion}
            />
          </Case>
          <Case value={SURVEY_STATES.getPrelim}>
            <PreliminaryCareers onAnswer={onSubmitCareer} />
          </Case>
        </Switch>
      </AnimatePresence>
    </div>
  );
};

PreliminaryCareersView.propTypes = {
  surveyStateName: PropTypes.string,
  onAnswerInitialQuestion: PropTypes.func,
  onAnswerPreliminaryCareer: PropTypes.func,
};
