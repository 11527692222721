/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";

import { Html, useTexture } from "@react-three/drei";
import { useEffect, useMemo, useRef, useState } from "react";

import {
  Blueberries,
  DashTheHedgehog,
  PineCones,
  Shire,
} from "@pages/journey-map/3d-models";
import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";
import { JourneyPin } from "@pages/journey-map/components";
import { JourneyTitle3D } from "@pages/journey-map/components/journey.title.3d";
import {
  dashAnimations,
  useDashTheHedgehog,
  useHoverAnimation,
} from "@pages/journey-map/hooks";
import { arrayToVector } from "@pages/journey-map/normalize";

import lock from "@images/journey-map/lock.png";

import { Tooltip } from "@features/ui/tooltip";
import { noPropagationHandler } from "@lib/app.helpers";
import "./map-four.scss";

export const MapFour = ({ isPlanShared, unlockedSteps, onMoveToStep }) => {
  const dashInitialPosition = [170, 0, -350];
  const goalsPathPosition = [175, 0, -290];
  const sharePathPosition = [-120, 0, 190];
  const dashRef = useRef();

  const blueberriesHoverAnimation = useHoverAnimation({ enabled: false });
  const pineconesHoverAnimation = useHoverAnimation();

  const { data: journeySteps } = useJourneySteps();
  const { data: userJourney } = useUserJourney();

  const [showTooltip, setShowTooltip] = useState(false);

  // These points will build the curve where Dash can be moved
  const pathPoints = [
    dashInitialPosition,
    goalsPathPosition,
    [170, 0, -250],
    [160, 0, -230],
    [130, 0, -180],
    [110, 0, -150],
    [130, 0, -90],
    [140, 0, -50],
    [135, 0, -30],
    [120, 0, 0],
    [90, 0, 50],
    [20, 0, 80],
    [-0, 0, 90],
    [-50, 0, 95],
    [-70, 0, 110],
    [-120, 0, 140],
    [-130, 0, 180],
    sharePathPosition,
  ];

  const textures = useTexture({
    lock,
  });

  const isShareLocked = !unlockedSteps[journeySteps.stepsByCode.SHARE.stepNum];

  const shareTooltipContent = useMemo(
    () =>
      isShareLocked
        ? "Set up goals for your pathway before sharing."
        : "We've shared your plan!",
    [isShareLocked],
  );

  useEffect(() => {
    if (!isShareLocked) {
      setShowTooltip(false);
      blueberriesHoverAnimation.enabled(true);
    }
  }, [isShareLocked]);

  const dash = useDashTheHedgehog({
    ref: dashRef,
    initialPosition: dashInitialPosition,
    pathPoints,
    speed: 0.8,
  });

  const onBlueberriesClick = () => {
    if (isShareLocked) {
      setShowTooltip((v) => !v);
    } else {
      dash.setDestination({
        point: arrayToVector(sharePathPosition),
        onDestinationReached: () =>
          onMoveToStep(journeySteps.stepsByCode.SHARE),
      });
    }
  };

  const onPineConesClick = () => {
    dash.setDestination({
      point: arrayToVector(goalsPathPosition),
      onDestinationReached: () => onMoveToStep(journeySteps.stepsByCode.GOALS),
    });
  };

  return (
    <group>
      <group position={[0, 49, -20]} rotation={[0, Math.PI * -0.05, 0]}>
        <DashTheHedgehog
          rotation={[0, Math.PI * 0.3, 0]}
          scale={70}
          animation={{ name: dashAnimations.Relaxed }}
        />

        <DashTheHedgehog
          position={[20, 0, 0]}
          rotation={[0, Math.PI * -0.2, 0]}
          scale={50}
          animation={{ name: dashAnimations.RelaxedTwo }}
        />
      </group>

      <DashTheHedgehog
        position={[-40, 30, -440]}
        rotation={[0, Math.PI * 0.1, 0]}
        scale={35}
        animation={{ name: dashAnimations.Relaxed }}
      />

      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={80}
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
      />

      <JourneyTitle3D
        position={[-135, 80, -200]}
        rotation={[Math.PI * -0.14, Math.PI * 0.08, Math.PI * 0.008]}
        header="Pathway:"
        subheader={userJourney.currentJourneyCareer?.title}
        padding={5}
        fontSize={16}
        maxWidth={200}
        color="#68813c"
        fontColor="white"
      />

      <group
        position={[200, 0, -300]}
        onClick={noPropagationHandler(onPineConesClick)}
      >
        <JourneyPin
          size={[45, 20]}
          padding={5}
          position={[0, 60, 0]}
          rotation={[-0.4, -0.2, 0]}
          text={"Goals"}
          fontSize={12}
          hoverAnimation={pineconesHoverAnimation}
        />
        <PineCones scale={120} hoverAnimation={pineconesHoverAnimation} />
      </group>

      <group
        position={[-120, 0, 170]}
        onClick={noPropagationHandler(onBlueberriesClick)}
      >
        <JourneyPin
          size={[isShareLocked ? 50 : 40, 20]}
          padding={5}
          position={[0, 60, 0]}
          rotation={[-0.4, 0.3, 0]}
          text={"Share"}
          fontSize={10}
          hoverAnimation={blueberriesHoverAnimation}
          iconTexture={isShareLocked ? textures.lock : undefined}
          enabled={!isShareLocked}
        />
        <Html
          position={[0, 80, 0]}
          zIndexRange={[1, 0]}
          wrapperClass="map-four-tooltip-wrapper"
        >
          <Tooltip
            className="share-tooltip"
            content={shareTooltipContent}
            open={isPlanShared || (showTooltip && isShareLocked)}
          >
            <div>&nbsp;</div>
          </Tooltip>
        </Html>
        <Blueberries
          scale={100}
          rotation={[0, Math.PI * 0.2, 0]}
          hoverAnimation={blueberriesHoverAnimation}
        />
      </group>

      <Shire
        position={[20, 0, 130]}
        scale={100}
        rotation={[0, Math.PI * 0.51, 0]}
      />
    </group>
  );
};

MapFour.propTypes = {
  isPlanShared: PropTypes.bool,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
  onMoveToStep: PropTypes.func,
};
