import clsx from "clsx";
import PropTypes from "prop-types";

import { InfoIcon } from "@features/ui";

import "./metadata.item.scss";

export const MetadataItem = ({
  label,
  value,
  description,
  className,
  children,
}) => {
  return (
    <div className={clsx("metadata-item", className)}>
      <div>
        {label}
        {description && (
          <InfoIcon variant="fa-solid" description={description} />
        )}
      </div>
      <div className="metadata-value">{value}</div>
      {children}
    </div>
  );
};

MetadataItem.displayName = "Metadata Item";

MetadataItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
  description: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
};
