import PropTypes from "prop-types";

import { ErrorToast, ListItemLoader } from "@features/ui";

import { FavoriteItem } from "@pages/favorites/components/favorite-item";

import { EmptyArray } from "@lib/app.helpers";
import { minimalCareerType } from "src/types";

export const FavoritesList = ({
  favorites,
  isError,
  isLoading,
  onFavoriteClick,
}) => {
  if (isLoading) {
    return EmptyArray(5).map((_, index) => <ListItemLoader key={index} />);
  }

  if (isError) {
    return (
      <ErrorToast open message="Something went wrong. Please try again." />
    );
  }

  return favorites?.map(({ career, headsetRating }) => (
    <FavoriteItem
      key={career.id}
      career={career}
      headsetRating={headsetRating}
      onClick={onFavoriteClick}
    />
  ));
};

FavoritesList.propTypes = {
  favorites: PropTypes.arrayOf(
    PropTypes.shape({
      career: minimalCareerType,
      headsetRating: PropTypes.number,
    }),
  ),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
};
