import PropTypes from "prop-types";
import { forwardRef } from "react";

import { Question } from "@features/ui";

export const InventoryQuestion = forwardRef(
  ({ value, onAnswer }, elementRef) => {
    const imageName = `${value?.tag?.replaceAll(" ", "-")}.png`;
    const imageUrl = `/images/interest-inventory/${imageName}`;

    return (
      <Question
        ref={elementRef}
        imageUrl={imageUrl}
        text={value?.question}
        onAnswer={(answer) => onAnswer({ ...value, answer })}
      />
    );
  },
);

InventoryQuestion.displayName = "Interest Inventory Question";

InventoryQuestion.propTypes = {
  value: PropTypes.object,
  onAnswer: PropTypes.func,
};
