import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EmptyArray } from "@lib/app.helpers";

import "./headset-rating.scss";

export const HeadsetRating = ({ rating }) => {
  return (
    <div className="headset-rating">
      <div className="from-headset-container">
        <FontAwesomeIcon icon="fa-regular fa-vr-cardboard" />
        <span>From Headset</span>
      </div>
      <div className="stars">
        {EmptyArray(rating).map((_, index) => (
          <FontAwesomeIcon key={index} icon={["fa-solid", "star"]} />
        ))}
      </div>
    </div>
  );
};

HeadsetRating.propTypes = {
  rating: PropTypes.number,
};
