import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";

import { InfoIcon } from "@features/ui";

import "./cluster-info.scss";

export const ClusterInfo = ({ name, description, className }) => {
  const containerRef = useRef();
  const [tooltipProps, setTooltipProps] = useState({});

  useEffect(() => {
    const resizeHandler = () => {
      const { height, width } = containerRef.current.getBoundingClientRect();
      const { width: parentWidth } =
        containerRef.current.parentNode.getBoundingClientRect();

      setTooltipProps({
        maxWidth: width,
        // Reduce 7px of tooltip offset
        top: (height / 2 - 7) * -1,
        // Calculate padding from parent node
        left: ((parentWidth - width) / 2) * -1,
      });
    };

    containerRef.current && resizeHandler();
    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, [containerRef.current]);

  return (
    name && (
      <div
        ref={containerRef}
        className={clsx("cluster-info-container", className)}
      >
        <div>
          <span>Cluster:</span>
          &nbsp;
          {name}
        </div>
        {description && (
          <InfoIcon description={description} tooltipProps={tooltipProps} />
        )}
      </div>
    )
  );
};

ClusterInfo.displayName = "Cluster Info";

ClusterInfo.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  className: PropTypes.string,
};
