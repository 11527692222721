import PropTypes from "prop-types";

import { ListItem, Section } from "@features/ui";
import { ExpertIcon } from "@features/ui/expert-icon";
import { aOrAn } from "@lib/text.helpers";

import "./ask-an-expert.section.scss";

export const AskAnExpertSection = ({ transfrId, title, onClick }) => {
  return (
    <Section className="ask-an-expert-section">
      <ListItem onClick={onClick}>
        <ExpertIcon transfrId={transfrId} />
        <span>Ask {aOrAn(title)}</span>
      </ListItem>
    </Section>
  );
};

AskAnExpertSection.propTypes = {
  transfrId: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};
