import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useCareerSimulationLoginCode = (simulationCode) => {
  const queryFn = useApiRequest({
    url: `/career-simulations/${simulationCode}/login-code`,
    method: HttpMethod.Get,
  });

  return useQuery({
    queryKey: ["career-simulation-login-code", simulationCode],
    queryFn,
    enabled: false,
    // This will delete the data from the cache after 1ms of the query being
    // "inactive". This will make sure we don't display the code if the user
    // leaves the details page and then returns.
    gcTime: 1,
  });
};
