import clsx from "clsx";
import PropTypes from "prop-types";

import "./tag.scss";

export const Tag = ({ value, className }) => {
  return <span className={clsx("tag-container", className)}>{value}</span>;
};

Tag.displayName = "Tag";

Tag.propTypes = {
  value: PropTypes.node,
  className: PropTypes.string,
};
