import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Button, Input } from "@transfr-inc/dashboard-components/forms";
import { ErrorToast } from "@features/ui/toast";

import { buttonTracking } from "@lib/tracking.helpers";
import { isValidEmail } from "@lib/app.helpers";

import "./forgot-password.form.scss";

export const ForgotPasswordForm = ({
  className,
  error,
  loading,
  onResetPassword,
  onCancel,
}) => {
  const [email, setEmail] = useState("");
  const [formError, setFormError] = useState();

  useEffect(() => {
    setFormError({
      open: !!error,
      message: error?.detail,
    });
  }, [error]);

  const onSubmit = (event) => {
    if (isValidEmail(email)) {
      return onResetPassword(event, email);
    } else {
      event.preventDefault();
      setFormError({
        open: true,
        message: "Please enter a valid email address",
      });
    }
  };

  return (
    <form
      className={clsx("forgot-password-form-content", className)}
      onSubmit={onSubmit}
    >
      <ErrorToast
        open={formError?.open}
        onClose={() => setFormError()}
        message={formError?.message}
        closable
      />
      <div className="login-fields-container">
        <Input
          id="login-email"
          label="Email Address"
          value={email}
          disabled={loading}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="tx-container">
        <Button
          disabled={loading || !email}
          loader={loading}
          type="submit"
          primary
          {...buttonTracking("forgot-password", "send-reset-email")}
        >
          Send Reset Email
        </Button>
        <Button
          onClick={onCancel}
          {...buttonTracking("forgot-password", "cancel")}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  className: PropTypes.string,
  error: PropTypes.shape({ detail: PropTypes.string }),
  loading: PropTypes.bool,
  onResetPassword: PropTypes.func,
  onCancel: PropTypes.func,
};
