import { useState } from "react";

export const useSlideShow = (numberSlides = 0) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    if (currentIndex + 1 < numberSlides) {
      setCurrentIndex((i) => i + 1);
    }
  };

  const previous = () => {
    if (currentIndex > 0) {
      setCurrentIndex((i) => i - 1);
    }
  };

  const reset = () => setCurrentIndex(0);

  return {
    currentIndex,
    next,
    previous,
    reset,
  };
};
