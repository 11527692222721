import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useMemo } from "react";
import { createPortal } from "react-dom";

import { useBreakpoints } from "@hooks/use-breakpoints";

import "./sheet.scss";

export const Sheet = ({ open, onCloseCompleted, className, children }) => {
  const { isMobile } = useBreakpoints();

  const portalContainerElement = useMemo(
    () => document.querySelector("body"),
    [],
  );

  return createPortal(
    <AnimatePresence mode="popLayout" onExitComplete={onCloseCompleted}>
      {open && (
        <motion.div
          key="sheet-background"
          className="sheet-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <motion.div
            key="sheet-component"
            initial={{ y: "100vh", opacity: 0 }}
            animate={{ y: isMobile ? 30 : 0, opacity: 1 }}
            exit={{ y: "100vh", opacity: 0 }}
            className={clsx("sheet", className)}
            transition={{
              type: "spring",
              mass: 0.5,
              stiffness: 90,
            }}
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    portalContainerElement,
    "sheet-component",
  );
};

Sheet.displayName = "Sheet";

Sheet.propTypes = {
  open: PropTypes.bool,
  onCloseCompleted: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
