import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { RateCareer } from "@features/interest-inventory/components/rate-career";

import "./rate-careers.view.scss";

export const RateCareersView = forwardRef(
  ({ career, onCareerRated }, elementRef) => {
    return (
      <motion.div
        ref={elementRef}
        className="rate-careers-view"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <RateCareer career={career} onRate={onCareerRated} />
      </motion.div>
    );
  },
);

RateCareersView.displayName = "Rate Careers View";

RateCareersView.propTypes = {
  career: PropTypes.object,
  onCareerRated: PropTypes.func,
};
