import PropTypes from "prop-types";

import { EmptyArray } from "@lib/app.helpers";
import { skillsType } from "@features/skills/types";
import { SkillIcon, SkillIconLoader } from "@features/ui";

import "./skills.container.scss";

export const SkillsContainer = ({ label, skills, loading }) => {
  return (
    <div className="skills-container">
      <div className="header">{label}</div>
      <div className="content">
        {loading
          ? EmptyArray(6).map((_, index) => (
              <SkillIconLoader key={`label.${index}`} />
            ))
          : skills.map((skill) => <SkillIcon key={skill?.id} skill={skill} />)}
      </div>
    </div>
  );
};

SkillsContainer.propTypes = {
  label: PropTypes.string,
  skills: skillsType,
  loading: PropTypes.bool,
};
