import clsx from "clsx";
import PropTypes from "prop-types";

import "./heading.scss";

export const Heading = ({ children, className }) => {
  return <h2 className={clsx("heading", className)}>{children}</h2>;
};

Heading.displayName = "Heading";

Heading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
