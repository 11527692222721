import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { careerSimulationType } from "@features/career-details/types";
import { Badge, Slide, SubHeading } from "@features/ui";

import { ALT_LOGIN, isEnabled } from "@lib/app-features.helpers";
import { formatDate, ONE_DIGIT_DATE_FORMATTER } from "@lib/date.helpers";
import { aOrAn } from "@lib/text.helpers";

import { useCareerSimulationLoginCode } from "@features/career-details/api/use-career-simulation-login-code";
import { HeadsetImage } from "@features/career-details/components/headset-image";
import { SimPassButton } from "@features/career-details/components/sim-pass.button";

export const SimulationSlide = forwardRef(
  (
    { careerSimulation: { relevance, simulation }, completedAt, className },
    elementRef,
  ) => {
    const isAltLoginEnabled = isEnabled(ALT_LOGIN);

    const {
      data: alternativeLoginCode,
      isFetching: isAlternativeLoginCodeFetching,
      refetch: fetchAlternativeLoginCode,
    } = useCareerSimulationLoginCode(simulation.code);

    return (
      <Slide ref={elementRef} className={clsx("simulation-slide", className)}>
        {relevance === "exact" ? (
          <>
            <SubHeading>Get a Closer Look</SubHeading>
            <div className="info">Try this career out in virtual reality!</div>
          </>
        ) : (
          <>
            <SubHeading>Not a perfect match but...</SubHeading>
            <div className="info">
              Practice related skills as {aOrAn(simulation.careerTitle)}.
            </div>
          </>
        )}
        <HeadsetImage simulationCode={simulation.code} relevance={relevance} />
        <p className="simulation-name">Simulation: {simulation.name}</p>
        {isAltLoginEnabled && (
          <SimPassButton
            alternativeLoginCode={alternativeLoginCode}
            isGeneratingCode={isAlternativeLoginCodeFetching}
            onClick={() => fetchAlternativeLoginCode()}
          />
        )}
        {completedAt && (
          <Badge className="completed-badge">
            <FontAwesomeIcon icon={["fa-solid", "fa-check-circle"]} />
            Complete{" "}
            {formatDate({
              date: completedAt,
              formatter: ONE_DIGIT_DATE_FORMATTER,
              separator: "/",
            })}
          </Badge>
        )}
      </Slide>
    );
  },
);

SimulationSlide.displayName = "Simulation Slide";

SimulationSlide.propTypes = {
  careerSimulation: careerSimulationType,
  completedAt: PropTypes.instanceOf(Date),
  className: PropTypes.string,
};
