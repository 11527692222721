// This snowplow custom plugin adds the organization code
// in every event sends by snowplow

export const OrganizationTrackPlugin = (() => {
  let organizationCode;

  const init = () => {
    return {
      contexts: () => {
        // To avoid "bad" events in the dashboard
        if (organizationCode) {
          return [
            {
              schema: "iglu:com.transfrvr/organization/jsonschema/2-0-0",
              data: {
                organization_code: organizationCode,
              },
            },
          ];
        }
      },
    };
  };

  return {
    init,
    setOrganizationCode: (value) => {
      organizationCode = value;
    },
  };
})();
