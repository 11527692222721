import clsx from "clsx";
import PropTypes from "prop-types";

import "./expert.icon.scss";

export const ExpertIcon = ({ transfrId, className }) => {
  const icon = `/images/careers/${transfrId}/expert.png`;

  return (
    <div className={clsx("expert-icon-container", className)}>
      <img src={icon} alt="Expert icon" />
    </div>
  );
};

ExpertIcon.displayName = "Expert Icon";

ExpertIcon.propTypes = {
  transfrId: PropTypes.string,
  className: PropTypes.string,
};
