/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";

export function Desert({ ...props }) {
  const { nodes, materials } = useGLTF("/models/desert.glb");

  return (
    <group {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_desert_ground.geometry}
        material={materials.MAT_desert_ground}
        position={[3.077, 0, 0.459]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_desert_path_dashed_line.geometry}
        material={materials.MAT_desert_map_route_dash}
        position={[-0.471, 0, -0.56]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_back-large_cliffs_rocks"].geometry}
        material={materials["MAT_back-large_cliffs"]}
        position={[-0.48, 0.132, 0.145]}
        rotation={[-Math.PI, 1.179, -Math.PI]}
        scale={0.884}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_back-med_cliffs_rocks"].geometry}
        material={materials["MAT_back-medium_cliffs"]}
        position={[-0.07, 0.237, 0.404]}
        rotation={[-Math.PI, 1.179, -Math.PI]}
        scale={0.884}
      />
      <group
        position={[0.435, 0, 0.926]}
        rotation={[-Math.PI, 1.179, -Math.PI]}
        scale={0.884}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve052.geometry}
          material={materials.MAT_dirt}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve052_1.geometry}
          material={materials["MAT_grass-3"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve052_2.geometry}
          material={materials["MAT_dirt-1"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_sml_plant-1001"].geometry}
        material={materials["MAT_grass_blade.001"]}
        position={[-0.626, -0.009, 0.434]}
        rotation={[-Math.PI, 1.179, -Math.PI]}
        scale={0.884}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_xsmall_cliffs_rocks001.geometry}
        material={materials.MAT_rock}
        position={[-0.532, -0.035, -1.427]}
        rotation={[0, 0.361, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_back-large_cliffs_rocks001"].geometry}
        material={materials["MAT_back-large_cliffs.001"]}
        position={[0.354, 0.122, -1.501]}
        rotation={[0, 1.332, 0]}
        scale={0.856}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_cactus002.geometry}
        material={materials.MAT_cactus}
        position={[0.41, -0.01, -1.203]}
        rotation={[3.084, 0.374, 3.094]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_large_cliffs_rocks.geometry}
        material={materials.MAT_large_cliffs}
        position={[3.031, -0.07, 1.887]}
        rotation={[0, -0.245, 0]}
        scale={0.971}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["GEO_cliffs-med_rocks"].geometry}
        material={materials.MAT_medium_cliffs}
        position={[2.766, 0.063, 1.614]}
        rotation={[0, -0.245, 0]}
        scale={0.971}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_small_cliffs_rocks.geometry}
        material={materials.MAT_cliffs_small_rocks}
        position={[4.033, 0.006, 1.11]}
        rotation={[0, -0.245, 0]}
        scale={0.971}
      />
    </group>
  );
}

useGLTF.preload("/models/desert.glb");
