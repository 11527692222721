import PropTypes from "prop-types";

import {
  CertificationList,
  SectionWithHeading,
} from "@features/post-high-school/components";

import { useCertifications } from "@features/post-high-school/api/use-certifications";

import "./certifications.section.scss";

export const CertificationsSection = ({ careerId }) => {
  const {
    data: certifications,
    isError: isCertificationsError,
    isPending: isCertificationsPending,
  } = useCertifications(careerId);

  return (
    (isCertificationsError ||
      isCertificationsPending ||
      certifications.length > 0) && (
      <SectionWithHeading
        className="certifications-section"
        heading="Certifications"
        subheading="Certificates you will need along the way."
      >
        <CertificationList
          certifications={certifications}
          isError={isCertificationsError}
          isPending={isCertificationsPending}
        />
      </SectionWithHeading>
    )
  );
};

CertificationsSection.propTypes = {
  careerId: PropTypes.number,
};
