import { useEffect, useState } from "react";

export const JourneyMapGuards = ({
  guards,
  activeGuardKey,
  onContinue,
  onReject,
}) => {
  const [activateGuard, setActivateGuard] = useState();

  useEffect(() => {
    if (activeGuardKey) {
      const hasGuard = !!guards[activeGuardKey];

      if (hasGuard) {
        setActivateGuard(activeGuardKey);
      } else {
        onContinue();
      }
    } else {
      setActivateGuard();
    }
  }, [activeGuardKey]);

  return Object.entries(guards)
    .filter(([guardKey]) => guardKey === activateGuard)
    .map(([guardKey, Guard]) => (
      <Guard
        key={`guard.${guardKey}`}
        onContinue={onContinue}
        onReject={onReject}
      />
    ));
};
