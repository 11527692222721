/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

const gblFileUrl = "/models/fishing-pole.glb";

export const FishingPole = ({
  hoverAnimation = {},
  onClick,
  animated,
  ...props
}) => {
  const { nodes, materials } = useGLTF(gblFileUrl);

  return (
    <group {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onUpdate={() => invalidate()}
        onClick={onClick}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.GEO_fishingPole.geometry}
          material={materials.PaletteMaterial001}
          position={[0, 1.004, 0]}
        />
      </motion.group>
    </group>
  );
};

useGLTF.preload(gblFileUrl);

FishingPole.propTypes = {
  animated: PropTypes.bool,
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
