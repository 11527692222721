import { EmptyArray } from "@lib/app.helpers";
import { QuestionCard } from "../components/question.card";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";
import { careerExpertQuestionType } from "src/types";

export const CareerExpertQuestionsList = ({ transfrId, questions }) => {
  return questions && questions.length > 0
    ? questions.map((q) => (
        <QuestionCard
          key={q.orderNum}
          transfrId={transfrId}
          careerExpertQuestion={q}
        />
      ))
    : EmptyArray(3).map((_, index) => (
        <Skeleton
          containerClassName="tx-skeleton-container "
          key={`question-card-loader.${index}`}
          height={"11.25rem"}
        />
      ));
};

CareerExpertQuestionsList.propTypes = {
  transfrId: PropTypes.string,
  questions: PropTypes.arrayOf(careerExpertQuestionType),
};
