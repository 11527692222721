import { motion } from "framer-motion";
import clsx from "clsx";
import PropTypes from "prop-types";

export const Tab = ({ className, children }) => {
  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      className={clsx("tab-container-v2", className)}
    >
      {children}
    </motion.div>
  );
};

Tab.displayName = "Tab";

Tab.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};
