import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthProvider } from "@context/auth.context";
import { JourneyProvider } from "@context/journey.context";
import { AppQueryProvider } from "@context/query.provider";
import { ProtectedRoute } from "@features/ui/protected-route";
import {
  FavoritesPage,
  ForgotPasswordPage,
  JourneyMapPage,
  LoginPage,
  ProfilePage,
  ResetPasswordPage,
  TermsAndConditionsPage,
} from "@pages/index";

import { App } from "./app";

import { ScreenBreakpointsProvider } from "@context/screen-breakpoints.context";
import { AppRoutes } from "./routes";

export const Router = () => {
  const JourneyMapRoute = {
    element: <JourneyMapPage />,
    path: AppRoutes.journeyMap.pathname,
  };

  const ProfileRoute = {
    element: <ProfilePage />,
    path: AppRoutes.profile.pathname,
  };

  const FavoritesRoute = {
    element: <FavoritesPage />,
    path: AppRoutes.favorites.pathname,
  };

  const LoginRoute = {
    element: (
      <AuthProvider>
        <LoginPage />
      </AuthProvider>
    ),
    path: "/login",
  };

  const ForgotPasswordRoute = {
    element: (
      <AuthProvider>
        <ForgotPasswordPage />
      </AuthProvider>
    ),
    path: "/forgot-password",
  };

  const ResetPasswordRoute = {
    element: (
      <AuthProvider>
        <ResetPasswordPage />
      </AuthProvider>
    ),
    path: "/reset-password",
  };

  const TermsRoute = {
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <TermsAndConditionsPage />
        </ProtectedRoute>
      </AuthProvider>
    ),
    path: "/terms",
  };

  const AppRoute = {
    element: (
      <AuthProvider>
        <ProtectedRoute>
          <JourneyProvider>
            <App />
          </JourneyProvider>
        </ProtectedRoute>
      </AuthProvider>
    ),
    path: "/",
    children: [JourneyMapRoute, ProfileRoute, FavoritesRoute],
  };

  const router = createBrowserRouter([
    AppRoute,
    LoginRoute,
    TermsRoute,
    ForgotPasswordRoute,
    ResetPasswordRoute,
  ]);

  return (
    <ScreenBreakpointsProvider>
      <AppQueryProvider>
        <RouterProvider router={router} />
      </AppQueryProvider>
    </ScreenBreakpointsProvider>
  );
};
