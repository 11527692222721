import clsx from "clsx";
import { useDebouncedCallback } from "use-debounce";
import { useRef, useState } from "react";
import PropTypes from "prop-types";

import {
  Dropdown,
  DropdownInput,
  DropdownItems,
  DropdownItem,
} from "@transfr-inc/dashboard-components/forms";

import "./searchbox.scss";

export const SearchBox = ({
  className,
  clearInputEnabled,
  customOptionLabel,
  debounceTime = 250,
  disabled,
  keyProp,
  loading,
  onOptionSelected,
  onTermChange,
  placeholder,
  results,
}) => {
  const lastOptionSelected = useRef();
  const [optionSelected, setOptionSelected] = useState();

  const onInputChangeDebounced = useDebouncedCallback(async (term) => {
    // Workaround to force update for text in searchbox
    // when selecting an option again after removing some letters
    setOptionSelected(term);
    if (!term) {
      onOptionSelectedHandler();
    }
    onTermChange(term);
  }, debounceTime);

  const onOptionSelectedHandler = (option) => {
    if (option) {
      setOptionSelected(customOptionLabel?.(option) ?? option);
    }

    if (option !== lastOptionSelected.current) {
      onOptionSelected?.(option);
      lastOptionSelected.current = option;
    }
  };

  const onBlurSearchbox = () => {
    onOptionSelectedHandler(lastOptionSelected.current);
  };

  return (
    <div
      className={clsx("search-box-container", className)}
      onBlur={onBlurSearchbox}
    >
      <Dropdown
        onOptionSelected={onOptionSelectedHandler}
        onInputChange={onInputChangeDebounced}
        disabled={disabled}
        selected={optionSelected}
      >
        <DropdownInput
          placeholder={placeholder}
          icon={["fa-light", "magnifying-glass"]}
          clearEnabled={clearInputEnabled}
        ></DropdownInput>
        {(results || loading) && (
          <DropdownItems autofocus={false}>
            {results?.map((r, index) => (
              <DropdownItem value={r} key={r[keyProp] ?? `item-${index}`}>
                {customOptionLabel ? customOptionLabel(r) : r}
              </DropdownItem>
            ))}
            {(loading || results?.length === 0) && (
              <span className="options-loader">
                {loading ? "Loading..." : "No Results Found"}
              </span>
            )}
          </DropdownItems>
        )}
      </Dropdown>
    </div>
  );
};

SearchBox.displayName = "Searchbox";

SearchBox.propTypes = {
  className: PropTypes.string,
  clearInputEnabled: PropTypes.bool,
  customOptionLabel: PropTypes.func,
  debounceTime: PropTypes.number,
  disabled: PropTypes.bool,
  keyProp: PropTypes.string,
  loading: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  onTermChange: PropTypes.func,
  placeholder: PropTypes.string,
  results: PropTypes.array,
};
