import clsx from "clsx";
import PropTypes from "prop-types";

import { Heading, Section, SubHeading } from "@features/ui";

import "./section-with-heading.scss";

export const SectionWithHeading = ({
  children,
  className,
  heading,
  subheading,
}) => (
  <Section className={clsx("section-heading", className)}>
    <Heading>{heading}</Heading>
    <SubHeading>{subheading}</SubHeading>
    {children}
  </Section>
);

SectionWithHeading.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  heading: PropTypes.node,
  subheading: PropTypes.node,
};
