import PropTypes from "prop-types";

import { Sheet } from "@features/ui";

import { InterestInventory } from "@features/interest-inventory";

export const InterestInventorySheet = ({ open, onClose, onExploreMatches }) => {
  return (
    <Sheet open={open} className="interest-inventory-sheet">
      <InterestInventory
        onClose={onClose}
        onExploreMatches={onExploreMatches}
      />
    </Sheet>
  );
};

InterestInventorySheet.displayName = "Interest Inventory Sheet";

InterestInventorySheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExploreMatches: PropTypes.func,
};
