/* eslint react/no-unknown-property: 0 */

import poppinsSemiBold from "@fonts/Poppins-SemiBold.ttf?url";
import { Cone, RoundedBox, Text } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { JourneyImage } from "./journey.image";

export const JourneyPin = ({
  color = "#fff",
  enabled = true,
  fontColor = "#000",
  fontSize = 14,
  iconTexture,
  hoverAnimation = {},
  onClick,
  position,
  rotation,
  scale = 1,
  size: [width, height, depth = 10],
  padding = 0,
  text,
}) => {
  const textProps = useMemo(() => {
    const iconSpace = iconTexture ? fontSize : 0;
    return {
      position: [iconSpace, 0, depth / 2 + 1],
      maxWidth: width - padding * 2 - iconSpace,
      iconSpace,
    };
  }, [iconTexture, width, depth, padding]);

  const opacity = useMemo(() => (enabled ? 1 : 0.6), [enabled]);

  return (
    <motion.group
      scale={scale}
      onPointerOver={hoverAnimation.onPointerOver}
      onPointerOut={hoverAnimation.onPointerOut}
      onClick={(e) => enabled && onClick?.(e)}
      position={position}
      rotation={rotation}
      animate={hoverAnimation.animate}
      variants={hoverAnimation.variants}
      onUpdate={() => invalidate()}
    >
      <RoundedBox
        position={[0, 0, 0]}
        args={[width + textProps.iconSpace, height, depth]}
        radius={4}
      >
        <meshPhongMaterial color={color} opacity={opacity} transparent />
      </RoundedBox>
      {iconTexture && (
        <JourneyImage
          position={[
            (width - textProps.iconSpace) / -2,
            (height - textProps.iconSpace) / 2,
            depth / 2 + 1,
          ]}
          size={[textProps.iconSpace, textProps.iconSpace]}
          texture={iconTexture}
          transparent
        />
      )}
      <Text
        font={poppinsSemiBold}
        color={fontColor}
        position={textProps.position}
        fontSize={fontSize}
        anchorX={"center"}
        overflowWrap="break-word"
        maxWidth={textProps.maxWidth}
        text={text}
        textAlign="center"
      />
      <Cone
        args={[depth / 2 - 1, depth / 2]}
        position={[0, -1 * (height / 2), 0]}
        rotation={[Math.PI, 0, 0]}
      >
        <meshPhongMaterial color={color} opacity={opacity} transparent />
      </Cone>
    </motion.group>
  );
};

JourneyPin.propTypes = {
  color: PropTypes.string,
  enabled: PropTypes.bool,
  hoverAnimation: PropTypes.object,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  iconTexture: PropTypes.object,
  onClick: PropTypes.func,
  position: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.arrayOf(PropTypes.number),
  scale: PropTypes.number,
  size: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
  padding: PropTypes.number,
};
