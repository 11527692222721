/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import { noPropagationHandler } from "@lib/app.helpers";
import {
  DashTheHedgehog,
  Desert,
  FannyPack,
} from "@pages/journey-map/3d-models";
import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { JourneyPin } from "@pages/journey-map/components";
import {
  useDashTheHedgehog,
  useHoverAnimation,
} from "@pages/journey-map/hooks";
import { arrayToVector } from "@pages/journey-map/normalize";

export const MapOne = ({
  onMapAnimationComplete,
  onMoveToStep,
  triggerMapAnimation,
}) => {
  const dashInitialPosition = [-40, 0, -300];
  const getReadyPathPosition = [40, 0, 130];
  const dashRef = useRef();

  const logHoverAnimation = useHoverAnimation();

  const { data: journeySteps } = useJourneySteps();

  // These points will build the curve where Dash can be moved
  const pathPoints = [
    dashInitialPosition,
    [-40, 0, -250],
    [-50, 0, -225],
    [-90, 0, -160],
    [-120, 0, -110],
    [-130, 0, -80],
    [-135, 0, -50],
    [-130, 0, -20],
    [-120, 0, 0],
    [-90, 0, 20],
    [-20, 0, 60],
    [30, 0, 90],
    [40, 0, 110],
    getReadyPathPosition,
  ];

  const dash = useDashTheHedgehog({
    ref: dashRef,
    initialPosition: dashInitialPosition,
    pathPoints,
  });

  const openInterestInventorySheet = () => {
    dash.setDestination({
      point: arrayToVector(getReadyPathPosition),
      onDestinationReached: () => {
        onMoveToStep(journeySteps.stepsByCode.INT_INV);
        onMapAnimationComplete?.();
      },
    });
  };

  useEffect(() => {
    if (triggerMapAnimation) {
      const getReadyVector = arrayToVector(getReadyPathPosition);
      if (dash.isNearlytoPoint(getReadyVector)) {
        onMapAnimationComplete?.();
      } else {
        dash.setDestination({ point: getReadyVector });
      }
    }
  }, [triggerMapAnimation]);

  return (
    <group>
      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={90}
        castShadow
        receiveShadow
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
      />

      <group position={[-45, 0, 110]}>
        <JourneyPin
          size={[50, 16]}
          position={[30, 60, 0]}
          rotation={[-0.4, Math.PI * 0.05, 0]}
          text={"Get Ready"}
          fontSize={8}
          hoverAnimation={logHoverAnimation}
          onClick={noPropagationHandler(openInterestInventorySheet)}
        />
        <FannyPack
          position={[30, 0, 20]}
          scale={60}
          rotation={[0, Math.PI * 0.7, 0]}
          hoverAnimation={logHoverAnimation}
          onClick={noPropagationHandler(openInterestInventorySheet)}
          animated
        />
      </group>

      <Desert
        position={[40, 0, 50]}
        scale={100}
        rotation={[0, Math.PI * 0.7, 0]}
      />
    </group>
  );
};

MapOne.propTypes = {
  onMapAnimationComplete: PropTypes.func,
  onMoveToStep: PropTypes.func,
  triggerMapAnimation: PropTypes.bool,
  mapDimensions: PropTypes.object,
};
