/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";

import { JourneyImage, JourneyLabel } from "../components";

import colors from "@styles/colors.module.scss";
import { forwardRef } from "react";

export const JourneyButton = forwardRef(
  (
    {
      children,
      position: [x, y, z] = [0, 0, 0],
      size,
      texture,
      imageOpacity = 1,
      labelSize: [labelWidth, labelHeight] = [-1, -1],
      labelOffset = [0, 0, 0.1],
      text,
      fontSize = 14,
      textAlign = "center",
      textPositionAdjust = [0, 0, 0],
      backgroundColor = colors.offWhite,
      onClick,
    },
    elementRef,
  ) => {
    const width = labelWidth < 0 ? size[0] - labelOffset[0] : labelWidth;
    const height = labelHeight < 0 ? size[1] - labelOffset[1] : labelHeight;

    return (
      <group ref={elementRef} position={[x, y, z]} onClick={onClick}>
        <JourneyImage
          position={[0, 0]}
          size={size}
          transparent
          opacity={imageOpacity}
          texture={texture}
          visible={!!texture}
        />
        <JourneyLabel
          position={[labelOffset[0], -labelOffset[1], labelOffset[2] ?? 0.1]}
          size={[width, height]}
          fontSize={fontSize}
          textAlign={textAlign}
          text={text}
          textPositionAdjust={textPositionAdjust}
          backgroundColor={backgroundColor}
        />
        {children}
      </group>
    );
  },
);

JourneyButton.displayName = "Journey Button";

JourneyButton.propTypes = {
  children: PropTypes.node,
  position: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.arrayOf(PropTypes.number),
  texture: PropTypes.object,
  imageOpacity: PropTypes.number,
  labelSize: PropTypes.arrayOf(PropTypes.number),
  labelOffset: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
  fontSize: PropTypes.number,
  textAlign: PropTypes.oneOf(["center", "left"]),
  textPositionAdjust: PropTypes.arrayOf(PropTypes.number),
  backgroundColor: PropTypes.string,
  onClick: PropTypes.func,
};
