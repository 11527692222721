import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";

import { buttonTracking } from "@lib/tracking.helpers";

import "./list-item.scss";

export const ListItem = ({
  children,
  className,
  disabled,
  index,
  onClick,
  rightIcon = ["fa-regular", "angle-right"],
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        "list-item-container",
        { "list-item-container-no-index": index === undefined },
        className,
      )}
      disabled={disabled}
      {...buttonTracking("list-item", index, children)}
    >
      {index !== undefined && (
        <div className="list-item-index">
          <span>{index}</span>
        </div>
      )}
      <div className="list-item-content">{children}</div>
      {rightIcon.length > 0 && (
        <FontAwesomeIcon className="list-item-icon" icon={rightIcon} />
      )}
    </button>
  );
};

ListItem.displayName = "List Item";

ListItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func,
  rightIcon: PropTypes.arrayOf(PropTypes.string),
};
