import PropTypes from "prop-types";

import { skillsType } from "@features/skills/types";

export const minimalCareerType = PropTypes.shape({
  id: PropTypes.number,
  transfrId: PropTypes.string,
  onetCode: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  shortDescription: PropTypes.string,
});

export const careerRegionDataType = PropTypes.shape({
  earnings: PropTypes.arrayOf(
    PropTypes.shape({
      percentile: PropTypes.number,
      earnings: PropTypes.number,
    }),
  ),
  outlook: PropTypes.number,
});

export const careerType = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  msa: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
  }),
  cluster: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  regional: careerRegionDataType,
  national: careerRegionDataType,
  skills: skillsType,
});

export const pathwayStepType = PropTypes.shape({
  id: PropTypes.number,
  stepNum: PropTypes.number,
  label: PropTypes.string,
  description: PropTypes.string,
});

export const relevanceType = PropTypes.oneOf([
  "exact",
  "high",
  "medium",
  "low",
  "very_low",
]);
export const simulationType = PropTypes.shape({
  code: PropTypes.number,
  name: PropTypes.string,
  careerTitle: PropTypes.string,
});
export const careerSimulationType = PropTypes.shape({
  relevance: relevanceType,
  simulation: simulationType,
});
