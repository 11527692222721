import clsx from "clsx";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { ToggleButton } from "@transfr-inc/dashboard-components/forms";

import "./labeled-toggle.scss";

export const LabeledToggle = forwardRef(function LabeledToggle(
  { className, disabled, label, value, onChange },
  ref,
) {
  return (
    <div ref={ref} className={clsx("labeled-toggle", className, { disabled })}>
      <div className="label">{label}</div>
      <ToggleButton
        affirmText="YES"
        negateText="NO"
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </div>
  );
});

LabeledToggle.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.bool,
  onChange: PropTypes.func,
};
