/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";
import { useRef } from "react";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";

import { useSkills } from "@features/skills/api/use-skills";
import { useAuth } from "@hooks/use-auth";
import { noPropagationHandler } from "@lib/app.helpers";
import {
  CabinMedium,
  CabinSmall,
  Campfire,
  DashTheHedgehog,
  Winter,
} from "@pages/journey-map/3d-models";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";
import { JourneyPin } from "@pages/journey-map/components";
import { useDashTheHedgehog } from "@pages/journey-map/hooks/use-dash-hedgehog";
import { useHoverAnimation } from "@pages/journey-map/hooks/use-hover-animation";
import { arrayToVector } from "@pages/journey-map/normalize";

export const MapThree = ({ onMoveToStep }) => {
  const { user } = useAuth();
  const { data: userJourney } = useUserJourney();
  const { data: skills } = useSkills(userJourney.currentJourneyCareer?.id);

  const { data: journeySteps } = useJourneySteps();

  const highSchoolPathPoint = [-120, 0, -320];
  const skillsPathPoint = [-90, 10, -70];
  const postHighSchoolPathPoint = [-40, 50, 220];

  const dashRef = useRef();
  const postHighSchoolEnabled = !!user.msa;

  const hoverAnimationValues = { values: { scale: 1.1 } };
  const skillsHoverAnimation = useHoverAnimation(hoverAnimationValues);
  const highSchoolHoverAnimation = useHoverAnimation(hoverAnimationValues);
  const postHighSchoolHoverAnimation = useHoverAnimation({
    ...hoverAnimationValues,
    enabled: postHighSchoolEnabled,
  });

  // These points will build the curve where Dash can be moved
  const pathPoints = [
    highSchoolPathPoint,
    [-150, 0, -300],
    [-200, 0, -250],
    [-220, 0, -200],
    [-190, 10, -100],
    [-170, 10, -80],
    [-120, 10, -50],
    skillsPathPoint,
    [-20, 10, -30],
    [-10, 20, 90],
    [-20, 25, 140],
    [-30, 30, 180],
    postHighSchoolPathPoint,
  ];

  const dash = useDashTheHedgehog({
    ref: dashRef,
    initialPosition: highSchoolPathPoint,
    pathPoints,
    speed: 0.8,
  });

  const openSheet = (point, step) => {
    dash.setDestination({
      point,
      onDestinationReached: () => onMoveToStep(step),
    });
  };

  return (
    <group>
      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={70}
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
      />
      <group
        position={[50, 0, -60]}
        scale={1}
        rotation={[Math.PI * -0.005, Math.PI * 0.65, 0]}
      >
        <Winter scale={100} />

        <group
          position={[390, 0, 30]}
          onClick={noPropagationHandler(() => {
            openSheet(
              arrayToVector(highSchoolPathPoint),
              journeySteps.stepsByCode.HIGH_SCH,
            );
          })}
        >
          <JourneyPin
            size={[70, 25]}
            padding={5}
            position={[-60, 80, -30]}
            rotation={[Math.PI * 0.12, Math.PI * 1.35, Math.PI * 0.1]}
            text="High School"
            fontSize={10}
            color="#8a6240"
            fontColor="white"
            hoverAnimation={highSchoolHoverAnimation}
          />
          <CabinMedium
            scale={160}
            position={[0, 0, 0]}
            rotation={[0, 1, 0]}
            hoverAnimation={highSchoolHoverAnimation}
          />
        </group>

        <group
          position={[125, 10, -110]}
          onClick={noPropagationHandler(() => {
            openSheet(
              arrayToVector(skillsPathPoint),
              journeySteps.stepsByCode.SKILLS,
            );
          })}
        >
          <JourneyPin
            size={[50, 20]}
            padding={5}
            position={[0, 50, 0]}
            rotation={[Math.PI * 0.15, Math.PI * 1.4, Math.PI * 0.12]}
            text={`Skills ${skills?.length ?? "..."}`}
            fontSize={10}
            color="#8a6240"
            fontColor="white"
            hoverAnimation={skillsHoverAnimation}
          />
          <Campfire
            scale={120}
            hoverAnimation={skillsHoverAnimation}
            animated
            onClick={({ stopPropagation }) => {
              stopPropagation();
              openSheet(
                arrayToVector(skillsPathPoint),
                journeySteps.stepsByCode.SKILLS,
              );
            }}
          />
        </group>

        <group
          position={[-235, 0, -150]}
          onClick={noPropagationHandler(() => {
            postHighSchoolEnabled &&
              openSheet(
                arrayToVector(postHighSchoolPathPoint),
                journeySteps.stepsByCode.POST_HIGH_SCH,
              );
          })}
        >
          {postHighSchoolEnabled && (
            <JourneyPin
              size={[70, 40]}
              padding={5}
              text="Extended Learning"
              position={[-10, 100, -10]}
              rotation={[Math.PI * 0.15, Math.PI * 1.25, Math.PI * 0.12]}
              color="#8a6240"
              fontColor="white"
              fontSize={10}
              hoverAnimation={postHighSchoolHoverAnimation}
            />
          )}
          <CabinSmall
            scale={150}
            rotation={[0, Math.PI * 0.15, 0]}
            hoverAnimation={postHighSchoolHoverAnimation}
          />
        </group>
      </group>
    </group>
  );
};

MapThree.propTypes = {
  onMoveToStep: PropTypes.func,
};
