import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useRemoveUserFavoriteCareer = (careerId, onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: `users/me/favorite-careers/${careerId}`,
    method: HttpMethod.Delete,
  });

  return useMutation({
    mutationKey: ["remove-user-favorite-career", careerId],
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-favorite-careers"],
        exact: true,
      });
      onSuccess?.();
    },
  });
};
