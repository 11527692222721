import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { Section, SubHeading } from "@features/ui";
import { ClusterInfo } from "../components";

import "./overview.section.scss";

export const OverviewSection = ({
  cluster,
  clusterDescription,
  jobDescription,
  loading,
}) => {
  return (
    <Section className="overview-section">
      <SubHeading>What do they do?</SubHeading>
      {loading ? (
        <>
          <Skeleton
            count={3}
            containerClassName="tx-skeleton-container"
          ></Skeleton>
          <Skeleton
            width={"19.125rem"}
            containerClassName="tx-skeleton-container"
          ></Skeleton>
        </>
      ) : (
        <>
          <p>{jobDescription}</p>
          <ClusterInfo name={cluster} description={clusterDescription} />
        </>
      )}
    </Section>
  );
};

OverviewSection.displayName = "Career Overview Section";

OverviewSection.propTypes = {
  cluster: PropTypes.string,
  clusterDescription: PropTypes.string,
  jobDescription: PropTypes.string,
  loading: PropTypes.bool,
};
