import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useEffect, useLayoutEffect, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { useTimer } from "@hooks/use-timer";
import { OpacityTransitions } from "@lib/animation.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./sim-pass.button.scss";

const SHOW_EXPIRED_MS = 2000;

export const SimPassButton = ({
  alternativeLoginCode,
  isGeneratingCode,
  onClick,
}) => {
  const [showExpired, setShowExpired] = useState(false);

  const timer = useTimer();

  useEffect(() => {
    if (alternativeLoginCode) {
      setShowExpired(true);
      timer.setTimer(alternativeLoginCode.ttlSec);
    }
  }, [alternativeLoginCode]);

  useLayoutEffect(() => {
    if (alternativeLoginCode && timer.isDone) {
      const timeoutId = setTimeout(
        () => setShowExpired(false),
        SHOW_EXPIRED_MS,
      );

      return () => clearTimeout(timeoutId);
    }
  }, [timer.isDone]);

  return (
    <div className="sim-pass-button">
      <AnimatePresence initial={false} mode="popLayout">
        {alternativeLoginCode && !timer.isDone ? (
          <motion.div
            className="code-display"
            key="code-display"
            {...OpacityTransitions}
          >
            <span className="code">
              {alternativeLoginCode.code.padStart(5, "0")}
            </span>
            <div className="time">
              <FontAwesomeIcon icon={["fa-solid", "fa-clock"]} />
              {timer.timeFormatted}
            </div>
          </motion.div>
        ) : showExpired ? (
          <motion.div className="expired" key="expired" {...OpacityTransitions}>
            <FontAwesomeIcon icon={["fa-solid", "fa-clock"]} />
            Pass Expired
          </motion.div>
        ) : (
          <motion.div key="get-sim-pass" {...OpacityTransitions}>
            <Button
              icon={isGeneratingCode ? undefined : ["fa-light", "fa-ticket"]}
              loader={isGeneratingCode}
              onClick={onClick}
              disabled={isGeneratingCode}
              {...buttonTracking("career-details", "get-sim-pass")}
            >
              Get Sim Pass
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

SimPassButton.propTypes = {
  alternativeLoginCode: PropTypes.shape({
    code: PropTypes.string,
    ttlSec: PropTypes.number,
  }),
  isGeneratingCode: PropTypes.bool,
  onClick: PropTypes.func,
};
