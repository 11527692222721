import PropTypes from "prop-types";
import { Children, forwardRef, useMemo } from "react";

export const Switch = forwardRef(({ option, children }, ref) => {
  const childOption = useMemo(
    () =>
      Children.toArray(children).find((c) =>
        Array.isArray(c.props.value)
          ? c.props.value.includes(option)
          : c.props.value === option,
      ),
    [option, children],
  );

  const defaultOption = useMemo(
    () => Children.toArray(children).find((c) => c.props.defaultOption),
    [children],
  );

  return childOption ?? defaultOption;
});

Switch.displayName = "Switch Component";

Switch.propTypes = {
  option: PropTypes.any,
  children: PropTypes.node,
  ref: PropTypes.object,
};
