import clsx from "clsx";
import PropTypes from "prop-types";
import { useMemo } from "react";

import { HiddenTag, ListItem, SelectedTag } from "@features/ui";
import { InterestTags, SalaryRate } from ".";

import "./career-item.scss";

export const CareerItem = ({ index, career, selected, hidden, onClick }) => {
  const { title, shortDescription, tags, hourlyEarningsMedian } = career;

  const highlightedTags = useMemo(() => {
    if (selected || hidden) {
      return (
        <div className="tags-container">
          {selected && <SelectedTag />}
          {hidden && <HiddenTag />}
        </div>
      );
    }
  }, [selected, hidden]);

  const listItem = (
    <ListItem
      index={index}
      className={"career-item"}
      onClick={() => onClick(career, index)}
    >
      <div className="header">
        <span>{title}</span>
        {hourlyEarningsMedian && <SalaryRate value={hourlyEarningsMedian} />}
      </div>
      <div className="description">{shortDescription}</div>
      <InterestTags tags={tags} />
    </ListItem>
  );

  return highlightedTags ? (
    <div
      className={clsx(
        "career-item-highlighted",
        selected && "selected",
        hidden && "hidden",
      )}
    >
      {listItem}
      {highlightedTags}
    </div>
  ) : (
    listItem
  );
};

CareerItem.displayName = "Carrer Item";

CareerItem.propTypes = {
  career: PropTypes.object,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  index: PropTypes.number,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
