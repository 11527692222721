import PropTypes from "prop-types";

import { PostHighSchool } from "@features/post-high-school";
import { Sheet } from "@features/ui";

import { useUserJourney } from "../api/use-user-journey";

import "./post-high-school.sheet.scss";

export const PostHighSchoolSheet = ({ open, onClose }) => {
  const {
    data: { currentJourneyCareer },
  } = useUserJourney();

  return (
    <Sheet open={open} className="post-high-school-sheet">
      <PostHighSchool careerId={currentJourneyCareer?.id} onClose={onClose} />
    </Sheet>
  );
};

PostHighSchoolSheet.displayName = "Post High School Sheet";

PostHighSchoolSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
