import PropTypes from "prop-types";

import { Navigate } from "react-router-dom";

import { useAuth } from "@hooks/use-auth";

export const ProtectedRoute = ({ children }) => {
  const { user, userLoginExpired } = useAuth();
  if (!user) {
    return <Navigate to="/login" state={{ userLoginExpired }} />;
  }

  return children;
};

ProtectedRoute.propTypes = {
  children: PropTypes.node,
};
