import { useEffect, useState } from "react";

const INTERVAL_MS = 5000;
const GRACE_SECONDS = 10;

const isActive = (entry, adjust = 0) => {
  return !entry.expiresAt || Date.now() / 1000 < entry.expiresAt - adjust;
};

const getEntry = (keyName) => {
  const value = window.localStorage.getItem(keyName);
  if (value) {
    return JSON.parse(value);
  }

  return;
};

export const useLocalStorage = (
  keyName,
  { defaultValue, expiresAt, expiredCb } = {},
) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const entry = getEntry(keyName);
      if (entry) {
        if (isActive(entry)) {
          return entry.value;
        } else {
          window.localStorage.removeItem(keyName);
        }
      }

      if (defaultValue !== undefined) {
        window.localStorage.setItem(
          keyName,
          JSON.stringify({ value: defaultValue, expiresAt }),
        );
      }

      return defaultValue;
    } catch {
      return defaultValue;
    }
  });

  const setValue = (newValue, expiresAt) => {
    try {
      if (newValue === undefined) {
        window.localStorage.removeItem(keyName);
      } else {
        if (!expiresAt) {
          const entry = getEntry(keyName);
          expiresAt = entry?.expiresAt;
        }
        window.localStorage.setItem(
          keyName,
          JSON.stringify({ value: newValue, expiresAt }),
        );
      }
    } catch (error) {
      console.log(error);
    }

    setStoredValue(newValue);
  };

  useEffect(() => {
    if (expiredCb) {
      const pruneIntervalId = setInterval(() => {
        const entry = getEntry(keyName);
        if (entry && !isActive(entry, GRACE_SECONDS) && expiredCb(entry)) {
          setValue();
        }
      }, INTERVAL_MS);

      return () => clearInterval(pruneIntervalId);
    }
  }, [expiredCb]);

  return [storedValue, setValue];
};
