import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useMemo, useState } from "react";

import { STEP_TO_MAP } from "@context/journey.context";
import { Card, CardBody, CardHeader } from "@features/ui";
import { toCamelCase } from "@lib/text.helpers";

import { ShortcutListItem } from "../components/shortcut.list-item";
import { CareerPlanGuard, JourneyMapGuards } from "../guards";
import { SHORTCUTS_STEPS } from "../shortcuts";

import { useUserJourneyStatus } from "@pages/journey-map/api/use-user-journey-status";

import "./journey-map-shortcuts.view.scss";

export const JourneyMapShortcutsView = forwardRef(
  ({ journeySteps, unlockedSteps, moveToStep, moveToMap }, ref) => {
    const [activateGuard, setActivateGuard] = useState();

    const { data: userJourneyStatus } = useUserJourneyStatus();

    const shortcutItems = useMemo(() => {
      return SHORTCUTS_STEPS.map((s) => {
        const journeyStep = journeySteps.stepsByCode[s.code] ?? {};
        return {
          ...s,
          ...journeyStep,
          statusKey: toCamelCase(s.code),
        };
      });
    }, [journeySteps]);

    const STEP_GUARDS = {
      GOALS: CareerPlanGuard,
    };

    const onGuardContinue = () => {
      const currentStep = activateGuard;
      setActivateGuard();
      moveToStep(currentStep);
      moveToMap(STEP_TO_MAP[currentStep.code]);
    };

    const onGuardRejected = () => setActivateGuard();

    return (
      <motion.div
        ref={ref}
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="journey-map-shortcuts"
      >
        <Card className="journey-map-shortcuts-card">
          <CardHeader
            title={
              <>
                <h1>Shortcut List</h1>
                <div className="subheader">
                  Quickly jump to a specific page in Trek.
                </div>
                <span className="note-container">Required Step *</span>
              </>
            }
          />
          <CardBody>
            {shortcutItems.map((item) => (
              <ShortcutListItem
                completed={!!userJourneyStatus?.[item.statusKey]}
                key={item.code}
                onClick={() => setActivateGuard(item)}
                step={item}
                unlocked={unlockedSteps[item.stepNum]}
              />
            ))}
          </CardBody>
          <JourneyMapGuards
            guards={STEP_GUARDS}
            activeGuardKey={activateGuard?.code}
            onContinue={onGuardContinue}
            onReject={onGuardRejected}
          />
        </Card>
      </motion.div>
    );
  },
);

JourneyMapShortcutsView.displayName = "Journey Map Shortcuts View";

JourneyMapShortcutsView.propTypes = {
  journeySteps: PropTypes.shape({
    steps: PropTypes.array,
    stepsByCode: PropTypes.object,
  }),
  moveToStep: PropTypes.func,
  moveToMap: PropTypes.func,
  unlockedSteps: PropTypes.array,
};
