import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useDebounce } from "use-debounce";

import { Textarea } from "@transfr-inc/dashboard-components/forms";

import "./custom-goals.container.scss";

export const CustomGoalsContainer = ({
  selectedGoals,
  onBlur,
  onGoalTextChanged,
}) => {
  const [goalText, setGoalText] = useState(selectedGoals?.[0].goalText);
  const [value] = useDebounce(goalText, 250);

  useEffect(() => {
    setGoalText(selectedGoals?.[0].goalText);
  }, [selectedGoals]);

  useEffect(() => {
    if (value !== selectedGoals?.[0].goalText) {
      onGoalTextChanged(value === "" ? undefined : value, 0);
    }
  }, [value]);

  return (
    <div className="custom-goals-container">
      <div className="header">
        Additional Goals
        <span>{goalText?.length ?? 0} / 500</span>
      </div>
      <div className="content">
        <Textarea
          placeholder="Type some other ideas here..."
          value={goalText}
          maxLength={500}
          onBlur={onBlur}
          onInputChange={(newValue) => setGoalText(newValue)}
        />
      </div>
    </div>
  );
};

CustomGoalsContainer.propTypes = {
  selectedGoals: PropTypes.arrayOf(PropTypes.object),
  onBlur: PropTypes.func,
  onGoalTextChanged: PropTypes.func,
};
