import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tag } from "./tag";

import "./selected.tag.scss";

export const SelectedTag = () => {
  return (
    <Tag
      className="tx-selected-tag"
      value={
        <>
          <FontAwesomeIcon icon={["fasr", "circle-check"]} />
          <span>selected</span>
        </>
      }
    />
  );
};
