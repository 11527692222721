import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./job-outlook.icon.scss";

export const JobOutlookIconType = {
  arrowUp: "arrow-trend-up",
  arrowDown: "arrow-trend-down",
  ban: "ban",
};

export const JobOutlookIcon = ({ type, className }) => {
  return (
    <div
      className={clsx(
        "job-outlook-icon",
        type === JobOutlookIconType.ban && "no-border",
        className,
      )}
    >
      <FontAwesomeIcon icon={["fa-solid", type]} />
    </div>
  );
};

JobOutlookIcon.displayName = "Job Outlook Icon";

JobOutlookIcon.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
};
