import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import {
  Children,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { EmptyArray } from "@lib/app.helpers";
import { buttonTracking } from "@lib/tracking.helpers";

import "./slideshow.scss";

export const SlideshowContext = createContext();

export const SlideDirection = {
  right: 1,
  left: -1,
};

export const Slideshow = ({
  index = 0,
  numberSlides = 0,
  onNextSlide,
  onPreviousSlide,
  controlsEnabled,
  indicatorEnabled,
  transitionDuration = 0.3,
  children,
  className,
}) => {
  const indexRef = useRef(index);
  const elementRef = useRef();
  const [movement, setMovement] = useState();

  const slides = Children.toArray(children);
  const [slideIndex, setSlideIndex] = useState(0);

  const { nextEnabled, previousEnbaled } = useMemo(() => {
    const nextEnabled = controlsEnabled && index + 1 < numberSlides;
    const previousEnabled = controlsEnabled && index > 0;
    return { nextEnabled, previousEnbaled: previousEnabled };
  }, [controlsEnabled, index, numberSlides]);

  useEffect(() => {
    const { current: previousIndex } = indexRef;
    const { width } = elementRef.current?.getBoundingClientRect() ?? 0;
    const direction =
      previousIndex > index ? SlideDirection.right : SlideDirection.left;
    indexRef.current = index;
    setMovement({ direction, value: width });
    setSlideIndex(index);
  }, [index]);

  return (
    <SlideshowContext.Provider value={{ movement, transitionDuration }}>
      <motion.div className={clsx("tx-slideshow", className)}>
        {previousEnbaled && (
          <button
            className="slideshow-button previous"
            onClick={onPreviousSlide}
            {...buttonTracking("slideshow", "previous")}
          >
            <FontAwesomeIcon icon={["fa-regular", "arrow-left"]} />
          </button>
        )}
        <div ref={elementRef} className="slideshow-container">
          <AnimatePresence initial={false} mode="popLayout">
            {slides[slideIndex]}
          </AnimatePresence>
        </div>
        {nextEnabled && (
          <button
            className="slideshow-button next"
            onClick={onNextSlide}
            {...buttonTracking("slideshow", "next")}
          >
            <FontAwesomeIcon icon={["fa-regular", "arrow-right"]} />
          </button>
        )}
      </motion.div>
      {indicatorEnabled && numberSlides > 1 && (
        <div className="slide-indicators">
          <ul>
            {EmptyArray(numberSlides).map((_, i) => (
              <li
                key={`indicator.${i}`}
                className={clsx("slide-indicator", i == index && "selected")}
              ></li>
            ))}
          </ul>
        </div>
      )}
    </SlideshowContext.Provider>
  );
};

Slideshow.displayName = "Slideshow";

Slideshow.propTypes = {
  index: PropTypes.number,
  numberSlides: PropTypes.number,
  controlsEnabled: PropTypes.bool,
  indicatorEnabled: PropTypes.bool,
  onNextSlide: PropTypes.func,
  onPreviousSlide: PropTypes.func,
  transitionDuration: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};
