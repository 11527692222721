export const ButtonVariants = {
  hover: (isDisabled) => ({
    scale: isDisabled ? 1 : 1.1,
  }),
  tap: (isDisabled) => ({
    scale: isDisabled ? 1 : 0.9,
  }),
};

export const OpacityTransitions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
