import PropTypes from "prop-types";

import { CareerDetails } from "@features/career-details";
import { CareerExpert } from "@features/career-expert";
import { Sheet, Slide, Slideshow } from "@features/ui";

import { useSlideShow } from "@hooks/index";
import { minimalCareerType } from "src/types";

export const CareerDetailsSheet = ({
  career,
  onClose,
  onBreadcrumbClick,
  onCareerSelected,
}) => {
  const slideshow = useSlideShow(2);

  return (
    <Sheet
      open={!!career}
      className="career-details-sheet"
      onCloseCompleted={() => slideshow.reset()}
    >
      <Slideshow index={slideshow.currentIndex}>
        <Slide key="career-details">
          <CareerDetails
            breadcrumbText="Favorites"
            career={career}
            onBreadcrumbClick={onBreadcrumbClick}
            onCareerSelected={onCareerSelected}
            onClose={onClose}
            onExpertClick={slideshow.next}
          />
        </Slide>
        <Slide key="career-expert">
          <CareerExpert
            career={career}
            onClose={onClose}
            onBreadcrumbClick={slideshow.previous}
          />
        </Slide>
      </Slideshow>
    </Sheet>
  );
};

CareerDetailsSheet.propTypes = {
  career: minimalCareerType,
  onClose: PropTypes.func,
  onBreadcrumbClick: PropTypes.func,
  onCareerSelected: PropTypes.func,
};
