// Any new step/shortcut should be added here
export const SHORTCUTS_STEPS = [
  {
    title: "Get Ready",
    description:
      "Tell us about your likes and dislikes & we can make recommendations.",
    code: "INT_INV",
    required: true,
  },
  {
    title: "Select Career Pathway",
    description:
      "Explore career matches and select a pathway to view more details.",
    code: "MATCHES",
    required: true,
    lockMessage: "Finish get ready to unlock career matches.",
  },
  {
    title: "High School",
    description: "Recommended courses you should take during high school.",
    code: "HIGH_SCH",
    lockMessage: "Select a pathway to view High School details.",
  },
  {
    title: "Skills",
    description: "Recommended job skills you should learn.",
    code: "SKILLS",
    lockMessage: "Select a pathway to view Skills details.",
  },
  {
    title: "Extended Learning",
    description: "Training programs near you.",
    code: "POST_HIGH_SCH",
    lockMessage: "Select a pathway to view Extended Learning details.",
  },
  {
    title: "Setup Goals",
    description: "Setup goals for your pathway.",
    code: "GOALS",
    required: true,
    lockMessage: "Select a pathway to setup goals.",
  },
  {
    title: "Share",
    description: "Share your new career pathway with your parent or guardian!",
    code: "SHARE",
    lockMessage: "Setup goals for your pathway before sharing.",
  },
];
