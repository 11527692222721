import { useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { Badge } from "../badge";
import { NavbarItem } from "./item.navbar";
import { NavbarContext } from "./navbar";
import { OverflowText } from "../text";

import { useBreakpoints } from "@hooks/use-breakpoints";

import "./profile-item.navbar.scss";

export const ProfileItemNavbar = ({ user, ...itemNavbarConfig }) => {
  const { isMobile } = useBreakpoints();

  const { isExpanded } = useContext(NavbarContext);

  const itemContent = useMemo(() => {
    const fullName = `${user?.firstName} ${user?.lastName}`.trim();
    return isExpanded ? (
      <>
        <FontAwesomeIcon
          className="profile-icon"
          icon={["fasr", "circle-user"]}
        />
        <div className="profile-info">
          <OverflowText value={fullName} />
          <Badge>STUDENT</Badge>
        </div>
      </>
    ) : undefined;
  }, [isExpanded]);

  return (
    <NavbarItem
      className={"profile-item-navbar"}
      title={isMobile ? "Profile" : user?.firstName}
      icon="circle-user"
      {...itemNavbarConfig}
    >
      {itemContent}
    </NavbarItem>
  );
};

ProfileItemNavbar.displayName = "Profile Item Navbar";

ProfileItemNavbar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
};
