import { useEffect, useState } from "react";

import { useDeleteCareerMatches } from "@features/career-matches/api/use-delete-career-matches";
import { useDeleteCareerPlan } from "@features/goals/api/use-delete-career-plan";
import { useDeleteJourneyCareer } from "@features/goals/api/use-delete-journey-career";
import { useCreateInterestInventory } from "@features/interest-inventory/api/use-create-interest-inventory";
import { useDeleteUserPreliminaryCareers } from "@features/interest-inventory/api/use-delete-user-preliminary-careers";

export const useStartOver = () => {
  const [isStartingOver, setIsStartingOver] = useState(false);

  const {
    mutateAsync: createInterestInventory,
    isPending: isCreatingInterestInventory,
  } = useCreateInterestInventory();
  const { mutateAsync: deleteCareerPlan, isPending: isDeletingCareerPlan } =
    useDeleteCareerPlan();
  const {
    mutateAsync: deleteJourneyCareer,
    isPending: isDeletingJourneyCareer,
  } = useDeleteJourneyCareer();
  const { mutateAsync: deleteCareerMatches } = useDeleteCareerMatches();

  const {
    mutateAsync: deletePreliminaryCareers,
    isPending: isDeletingPreliminaryCareers,
  } = useDeleteUserPreliminaryCareers();

  useEffect(() => {
    setIsStartingOver(
      isCreatingInterestInventory ||
        isDeletingCareerPlan ||
        isDeletingJourneyCareer ||
        isDeletingPreliminaryCareers,
    );
  }, [
    isCreatingInterestInventory,
    isDeletingCareerPlan,
    isDeletingJourneyCareer,
    isDeletingPreliminaryCareers,
  ]);

  return {
    isStartingOver,
    startOver: async ({ restartInterestInventory } = {}) => {
      // all start over actions need to delete any existing career plan and journey
      // career
      const promises = [deleteCareerPlan(), deleteJourneyCareer()];
      if (restartInterestInventory) {
        // if the interest inventory is being restarted, we need to create a new
        // interest inventory entry and delete any career matches -- deleting career
        // matches will also delete any career ratings
        promises.push(
          createInterestInventory(),
          deleteCareerMatches(),
          deletePreliminaryCareers(),
        );
      }

      await Promise.allSettled(promises);
    },
  };
};
