import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation } from "@tanstack/react-query";

export const useUpdateUserAgreement = (onSuccess) => {
  const mutationFn = useApiRequest({
    url: "/users/me",
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["update-user-agreement"],
    mutationFn: (data) => mutationFn({ data }),
    onSuccess: () => {
      onSuccess?.();
    },
  });
};
