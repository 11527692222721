import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { ExpertIcon } from "@features/ui/expert-icon";
import { minimalCareerType } from "src/types";

import { Section } from "@features/ui";
import { aOrAn } from "@lib/text.helpers";

import "./career-expert.section.scss";

export const CareerExpertSection = ({ career, name }) => {
  if (!name) {
    return (
      <Section className="career-expert-section">
        <Skeleton circle width={"4.375rem"} height={"4.375rem"} />
        <Skeleton width={"11.25rem"} height={"1.25rem"} />
      </Section>
    );
  }

  return (
    <Section className="career-expert-section">
      <ExpertIcon transfrId={career.transfrId} />
      <span>
        <span className="expert-name">{name}</span> answers common questions
        related to being {aOrAn(career.title)}.
      </span>
    </Section>
  );
};

CareerExpertSection.propTypes = {
  name: PropTypes.string,
  career: minimalCareerType,
};
