import PropTypes from "prop-types";
import { useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAuth } from "@hooks/use-auth";
import { useTimer } from "@hooks/use-timer";

import "./impersonation.bar.scss";

export const ImpersonationBar = ({ user }) => {
  const { logout } = useAuth();

  const timer = useTimer();

  useEffect(() => {
    const currentDateTime = Date.now();
    const timeTilExpire = user.claims.exp - Math.trunc(currentDateTime / 1000);

    timer.setTimer(timeTilExpire);
  }, [user.claims.exp]);

  useEffect(() => {
    if (timer.isDone) {
      logout();
    }
  }, [timer.isDone]);

  return (
    <div className="tx-impersonation-bar">
      <div className="user-info">
        <div className="organization">{user.orgName}</div>
        <div className="user">
          Logged in as {user.firstName} {user.lastName}{" "}
          {user.email && <u>{user.email}</u>}
        </div>
      </div>
      <div className="warning-info">
        <FontAwesomeIcon className="mask-icon" icon="fa-regular fa-mask" />
        <div className="warning">Any changes made will be permanent.</div>
        <div className="warning">
          Session expires in{" "}
          <span className="timer">{timer.timeFormatted}</span> minutes.
        </div>
      </div>
    </div>
  );
};

ImpersonationBar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    orgName: PropTypes.string,
    claims: PropTypes.shape({
      exp: PropTypes.number,
    }),
  }),
};
