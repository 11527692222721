export const useThemeColor = () => {
  const themeColorTag = document.querySelector("[name=theme-color]");

  const updateColor = (color) => {
    themeColorTag.setAttribute("content", color);
  };

  const getCurrentColor = () => {
    return themeColorTag.getAttribute("content");
  };

  return {
    updateColor,
    getCurrentColor,
  };
};
