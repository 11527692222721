/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";

export function Winter(props) {
  const { nodes, materials } = useGLTF("/models/winter.glb");
  return (
    <group {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_path_dashed_line.geometry}
        material={materials.MAT_map_route_dash}
        position={[-0.471, 0, -0.56]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_front_mountain.geometry}
        material={materials.MAT_mountain_rock}
        position={[-2.029, -0.002, 0.078]}
        rotation={[-Math.PI, 0.548, -Math.PI]}
      />
      <group position={[-3.002, 0.181, -1.935]} rotation={[0, -0.455, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder054.geometry}
          material={materials.MAT_tree_bark}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cylinder054_1.geometry}
          material={materials.MAT_pine_branch}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_rock_platform001.geometry}
        material={materials["MAT-ground_stone-1"]}
        position={[-2.562, 0.008, -1.786]}
        rotation={[0.003, -0.301, -0.015]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.BézierCurve095.geometry}
        material={materials.MAT_stone1}
        position={[-1.82, 0.048, -1.411]}
        rotation={[0, -0.488, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_winter_rock005.geometry}
        material={materials["MAT_mountain_rock.002"]}
        position={[-3.474, 0.048, -1.519]}
        rotation={[0, -0.525, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_front_mountain_snow.geometry}
        material={materials.sneeuw}
        position={[-0.959, 0.258, -1.832]}
        rotation={[0, -0.281, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_back_mountain.geometry}
        material={materials["MAT_mountain_rock.001"]}
        position={[3.982, -0.073, 0.251]}
        rotation={[0, -0.241, 0]}
        scale={0.891}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_winter_ground.geometry}
        material={materials.MAT_winter_ground}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube185_dmg002.geometry}
        material={materials.MAT_fence}
        position={[2.888, 0.057, 0.879]}
        rotation={[0, -1.135, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_rock_platform.geometry}
        material={materials.MAT_bck_ground_stone}
        position={[1.304, 0.01, -1.192]}
        rotation={[0, 0.495, 0]}
      />
    </group>
  );
}

useGLTF.preload("/winter-transformed.glb");
