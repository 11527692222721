import PropTypes from "prop-types";

import { Badge, InfoIcon, Section } from "@features/ui";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { MetadataItem } from "./metadata.item";

import { buttonTracking } from "@lib/tracking.helpers";

import { ROLE_NAMES, ROLES } from "src/constants";
import "./metadata.section.scss";

export const MetadataSection = ({ onResetPasswordClick, user }) => {
  const {
    claims: { act: role, preferred_username: username },
    email,
    msa,
  } = user;

  return (
    <Section className="metadata-section">
      {username !== email && (
        <MetadataItem
          className="username-item"
          label="Username"
          value={username}
        />
      )}
      {email && (
        <MetadataItem className="email-item" label="Email" value={email} />
      )}
      <MetadataItem
        className="user-type-item"
        label="User Type"
        value={<Badge>{ROLE_NAMES[role]}</Badge>}
      >
        {role !== ROLES.student && (
          <div className="metadata-info">
            Your data will not be saved
            <InfoIcon
              variant="fa-solid"
              description="Data is only saved for Student accounts."
            />
          </div>
        )}
      </MetadataItem>
      <MetadataItem
        label="Password"
        value={
          <Button
            icon={["fa-regular", "lock-keyhole"]}
            onClick={onResetPasswordClick}
            {...buttonTracking("profile", "reset-password")}
          >
            Reset Password
          </Button>
        }
      />
      <MetadataItem
        label="Location"
        description="We'll use your organization's location to show you career data near you."
        value={msa?.name ?? "Not available"}
      />
    </Section>
  );
};

MetadataSection.displayName = "Metadata Section";

MetadataSection.propTypes = {
  onResetPasswordClick: PropTypes.func,
  user: PropTypes.object,
};
