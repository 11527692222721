import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { CopyrightFooter } from "@features/ui";
import { Toast, ToastType } from "@features/ui/toast";

import { HeaderImage } from "./components/header-image";
import { LoginForm } from "./components/login-form";

import { useAuth } from "@hooks/use-auth";

import headerImageUrl from "@images/trek-logo.large.png?url";

import "./index.scss";

const resetPasswordMessage = "Your password has been reset successfully.";
const expiredSessionMessage = (
  <div>
    Your session has expired.
    <br />
    Please try logging in again.
  </div>
);

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [queryParams] = useSearchParams();
  const { login, loading, error } = useAuth();

  const [toastConfig, setToastConfig] = useState();

  useEffect(() => {
    if (queryParams.has("it")) {
      login({ impersonationToken: queryParams.get("it") });
      return;
    }

    if (location.state?.passwordReset) {
      setToastConfig({
        type: ToastType.success,
        message: resetPasswordMessage,
      });
    } else if (location.state?.userLoginExpired) {
      setToastConfig({
        type: ToastType.error,
        message: expiredSessionMessage,
      });
    }

    const timeoutId = setTimeout(() => {
      setToastConfig();
      navigate("/login", { replace: true });
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);

  const handleLogin = async (e, email, password) => {
    e.preventDefault();

    await login({ email, password });
  };

  return (
    <div className="login-page">
      <HeaderImage imageUrl={headerImageUrl} />
      <Toast
        open={!!toastConfig}
        message={toastConfig?.message}
        type={toastConfig?.type}
      />
      <LoginForm loading={loading} error={error} onSignIn={handleLogin} />
      <CopyrightFooter />
    </div>
  );
};
