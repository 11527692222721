import PropTypes from "prop-types";

import { useScreenBreakpoint } from "@transfr-inc/dashboard-components/hooks";
import { createContext } from "react";

import { tablet, desktop } from "@styles/breakpoints.module.scss";

export const ScreenBreakpointsContext = createContext();

export const ScreenBreakpointsProvider = ({ children }) => {
  const breakpoints = useScreenBreakpoint({
    desktop: desktop,
    tablet: tablet,
  });

  return (
    <ScreenBreakpointsContext.Provider value={breakpoints}>
      {children}
    </ScreenBreakpointsContext.Provider>
  );
};

ScreenBreakpointsProvider.displayName = "Screen Breakpoints Provider";

ScreenBreakpointsProvider.propTypes = {
  children: PropTypes.node,
};
