/* eslint react/no-unknown-property: 0 */

import { RoundedBox, Text } from "@react-three/drei";
import PropTypes from "prop-types";
import { useMemo, useState } from "react";
import { Vector3 } from "three";

import poppinsRegular from "@fonts/Poppins-Regular.ttf?url";
import poppinsSemiBold from "@fonts/Poppins-SemiBold.ttf?url";

const DEFAULT_DEPTH = 10;

export const JourneyTitle3D = ({
  color = "#fff",
  fontColor = "#000",
  fontSize = 14,
  header,
  maxWidth: textMaxWidth = Number.POSITIVE_INFINITY,
  padding = 0,
  position,
  rotation,
  scale = 1,
  subheader,
}) => {
  const [headerObject, setHeaderObject] = useState();
  const [subheaderObject, setSubheaderObject] = useState();

  const textProps = useMemo(() => {
    const sizeHeader = new Vector3();
    const sizeSubheader = new Vector3();

    headerObject?.geometry?.boundingBox?.getSize?.(sizeHeader);
    subheaderObject?.geometry?.boundingBox?.getSize?.(sizeSubheader);

    const widerText = Math.max(sizeHeader.x, sizeSubheader.x);
    const width = widerText + padding * 2;
    const height = sizeHeader.y + sizeSubheader.y + 2 * padding;
    const maxWidth = textMaxWidth > 0 ? textMaxWidth : width;

    const containerDimentions = [width, height, DEFAULT_DEPTH];

    const headerPosition = [
      0,
      // fontSize = lineHeight of the subheader
      (sizeSubheader.y + fontSize - padding) / 2,
      DEFAULT_DEPTH / 2 + 1,
    ];
    const subheaderPosition = [
      0,
      (-sizeHeader.y + padding) / 2,
      DEFAULT_DEPTH / 2 + 1,
    ];

    return {
      containerDimentions,
      maxWidth,
      headerPosition,
      subheaderPosition,
    };
  }, [textMaxWidth, padding, subheaderObject, headerObject]);

  return (
    <group scale={scale} position={position} rotation={rotation}>
      <RoundedBox
        position={[0, 0, 0]}
        args={textProps.containerDimentions}
        radius={4}
      >
        <meshPhongMaterial color={color} />
      </RoundedBox>
      <group>
        <Text
          font={poppinsRegular}
          color={fontColor}
          position={textProps.headerPosition}
          fontSize={fontSize}
          anchorX={"center"}
          overflowWrap="break-word"
          text={header}
          maxWidth={textProps.maxWidth}
          onSync={setHeaderObject}
          textAlign="center"
        />
        <Text
          font={poppinsSemiBold}
          color={fontColor}
          position={textProps.subheaderPosition}
          fontSize={fontSize}
          anchorX={"center"}
          overflowWrap="break-word"
          text={subheader}
          onSync={setSubheaderObject}
          maxWidth={textProps.maxWidth}
          textAlign="center"
          lineHeight={1}
        />
      </group>
    </group>
  );
};

JourneyTitle3D.propTypes = {
  color: PropTypes.string,
  fontColor: PropTypes.string,
  fontSize: PropTypes.number,
  header: PropTypes.string,
  maxWidth: PropTypes.number,
  padding: PropTypes.number,
  position: PropTypes.arrayOf(PropTypes.number),
  rotation: PropTypes.arrayOf(PropTypes.number),
  scale: PropTypes.number,
  subheader: PropTypes.string,
};
