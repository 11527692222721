import { Canvas } from "@react-three/fiber";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useLayoutEffect, useRef, useState } from "react";

import {
  JourneyMapBackground,
  JourneyMapFooter,
  JourneyMapHeader,
} from "../../components";

import { JourneyMapCanvas } from "./journey-map.canvas";

export const JourneyMapView = forwardRef(
  (
    {
      pageHeight,
      currentMap,
      triggerMapAnimation,
      moveToMap,
      moveToStep,
      onMapAnimationComplete,
      unlockedSteps,
      isPlanShared,
    },
    ref,
  ) => {
    const headerRef = useRef();
    const footerRef = useRef();

    const [mapDimensions, setMapDimensions] = useState({
      top: 0,
      bottom: 0,
      height: 0,
    });

    useLayoutEffect(() => {
      const handleResize = () => {
        const bottom = footerRef.current?.offsetHeight;
        const top = headerRef.current?.offsetHeight;
        const height = pageHeight - top - bottom;

        setMapDimensions({
          top,
          bottom,
          height,
        });
      };
      handleResize();

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [currentMap, headerRef.current, pageHeight, footerRef.current]);

    return (
      <motion.div
        className="journey-map-view"
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <JourneyMapBackground currentMap={currentMap} />
        <JourneyMapHeader ref={headerRef} />
        <div className="journey-map">
          <Canvas
            orthographic
            frameloop="demand"
            camera={{ near: -10, far: 10_000, position: [0, 0, 500] }}
            flat
          >
            <JourneyMapCanvas
              dimensions={mapDimensions}
              mapToTriggerAnimation={triggerMapAnimation}
              onMoveToStep={moveToStep}
              onMoveToMap={moveToMap}
              onMapAnimationComplete={onMapAnimationComplete}
              showMap={currentMap}
              unlockedSteps={unlockedSteps}
              isPlanShared={isPlanShared}
            />
          </Canvas>
        </div>
        <JourneyMapFooter ref={footerRef} />
      </motion.div>
    );
  },
);
JourneyMapView.displayName = "Journey Map View";
JourneyMapView.propTypes = {
  pageHeight: PropTypes.number,
  currentMap: PropTypes.number,
  triggerMapAnimation: PropTypes.number,
  moveToMap: PropTypes.func,
  moveToStep: PropTypes.func,
  onMapAnimationComplete: PropTypes.func,
  unlockedSteps: PropTypes.array,
  isPlanShared: PropTypes.bool,
};
