import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useUserPreliminaryCareersOptions = () => {
  const queryFn = useApiRequest({
    url: "/users/me/preliminary-careers",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["user-preliminary-careers"],
    queryFn,
    refetchOnMount: "always",
  };
};

export const useUserPreliminaryCareers = () => {
  return useQuery(useUserPreliminaryCareersOptions());
};
