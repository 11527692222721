import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { Box3, CatmullRomCurve3, Vector3 } from "three";
import { arrayToVector } from "../normalize";

export const dashAnimations = {
  MoveHip: "ACT_hip_quad_trans",
  Relaxed: "ANIM_idle-2_relaxed",
  RelaxedTwo: "ANIM_idle-2_relaxed",
  RunFourLegs: "ANIM_4leg_walk",
  WalkTwoLegs: "ANIM_walk_2leg",
  RunTwoLegs: "ANIM_run_2Leg",
  Jump: "ANIM_Jump",
  StartWalking: "ANIM_2leg_to_4leg_transition",
  StopWalking: "ANIM_4leg_to_2leg_transition",
  Talking: "ANIM_talk_to_camera",
};

export const defaultRatioPointTolerance = 10;

export const useDashTheHedgehog = ({
  ref,
  initialPosition: [x, y, z] = [0, 0, 0],
  pathPoints = [],
  ratioPointTolerance = defaultRatioPointTolerance,
  pathPointsSample = 150,
  speed = 1,
  obstacles = [],
}) => {
  const onDestinationReachedRef = useRef();
  const [position, setPosition] = useState(new Vector3(x, y, z));
  const [animation, setAnimation] = useState({
    name: dashAnimations.Relaxed,
  });
  const [willMove, setWillMove] = useState();
  const { camera } = useThree();

  const currentPathIndex = useRef(0);
  const destinationPointIndex = useRef(0);
  const dashDirection = useRef();
  const obstaclesRef = useMemo(
    () => obstacles.map((o) => ({ passed: false, obstacle: o })),
    [...obstacles],
  );

  // Useful for development process to display a Box3Helper reference
  const box3Ref = useRef(new Box3());

  // Define the path that Dash can move through
  const curve = useMemo(() => {
    const vectors = pathPoints?.map((point) => arrayToVector(point));
    return new CatmullRomCurve3(vectors);
  }, [pathPoints]);

  const curvePoints = useMemo(
    () =>
      pathPoints.length > 1
        ? curve?.getSpacedPoints(pathPointsSample / speed)
        : [],
    [curve],
  );

  const isDestinationReached = () =>
    currentPathIndex.current === destinationPointIndex.current;

  const setDestination = ({ point, onDestinationReached, options }) => {
    const { pointTolerance, animation } = {
      pointTolerance: ratioPointTolerance,
      animation: true,
      ...options,
    };
    onDestinationReachedRef.current = onDestinationReached;

    // Look if the point clicked is near of the path defined
    const index = curvePoints.findIndex(
      (curvePoint) => curvePoint.distanceTo(point) <= pointTolerance,
    );

    if (index >= 0) {
      resetObstaclesStatus();

      if (animation) {
        destinationPointIndex.current = index;
        dashDirection.current = index > currentPathIndex.current ? 1 : -1;
        setAnimation({
          name: dashAnimations.StartWalking,
          options: { once: true },
        });
      } else {
        setPosition(curvePoints[index]);
        currentPathIndex.current = index;
      }

      return true;
    }
  };

  const walkNextPathPoint = () => {
    if (willMove) {
      const point = curvePoints[currentPathIndex.current];
      setPosition(point);

      if (isDestinationReached()) {
        setWillMove();
        setAnimation({
          name: dashAnimations.StopWalking,
          options: { once: true },
        });
        // Look at the camera but lower in the Y axis
        return camera.position.clone().setY(20);
      } else {
        currentPathIndex.current += dashDirection.current;
      }

      return point;
    }
  };

  const onAnimationFinished = () => {
    switch (animation.name) {
      case dashAnimations.StartWalking: {
        setWillMove(true);
        setAnimation({
          name: dashAnimations.RunFourLegs,
          options: { duration: 0.5 },
        });
        break;
      }

      case dashAnimations.StopWalking: {
        setAnimation({ name: dashAnimations.Relaxed });
        break;
      }

      case dashAnimations.Jump: {
        if (isDestinationReached()) {
          setAnimation({ name: dashAnimations.Relaxed });
        } else {
          setWillMove(true);
          setAnimation({
            name: dashAnimations.RunFourLegs,
            options: { duration: 0.5 },
          });
        }
      }
    }
  };

  const isNearlytoPoint = (
    point,
    pointTolerance = defaultRatioPointTolerance,
  ) => {
    return position.distanceTo(point) < pointTolerance;
  };

  const resetObstaclesStatus = () => {
    for (const element of obstaclesRef) element.passed = false;
  };

  const getObstacleIndex = () => {
    box3Ref.current = box3Ref.current.setFromObject(ref.current);
    return obstaclesRef.findIndex(
      (element) =>
        element.obstacle &&
        !element.passed &&
        box3Ref.current.intersectsBox(element.obstacle),
    );
  };

  const jumpObstacle = (obstacleIndex) => {
    // Stop and start jump animation
    setWillMove();
    setAnimation({
      name: dashAnimations.Jump,
      options: { once: true, duration: 0.8 },
    });
    // Jump forward effect
    setTimeout(() => {
      setWillMove(true);
    }, 300);
    // Landing effect
    setTimeout(() => {
      setWillMove();
    }, 600);

    // Set obstacle as passed perform jump action once per obstacle
    obstaclesRef[obstacleIndex].passed = true;
  };

  useFrame(() => {
    if (willMove) {
      const pointToLook = walkNextPathPoint();

      // When jumping Dash will keep the looking at last walking point direction
      if (animation.name !== dashAnimations.Jump) {
        ref.current.lookAt(pointToLook);
      }
    } else if (currentPathIndex.current === destinationPointIndex.current) {
      onDestinationReachedRef.current?.();
      onDestinationReachedRef.current = undefined;
    }
  });

  useEffect(() => {
    const obstacleIndex = getObstacleIndex();
    if (obstacleIndex > -1) {
      jumpObstacle(obstacleIndex);
    }
  }, [position]);

  return {
    animation,
    curvePoints,
    isNearlytoPoint,
    onAnimationFinished,
    position,
    setDestination,
    walkNextPathPoint,
    willMove,
    box3: box3Ref,
  };
};
