import clsx from "clsx";
import PropTypes from "prop-types";

export const CardBody = ({ className, children }) => {
  return <div className={clsx("card-body", className)}>{children}</div>;
};

CardBody.displayName = "Card Body";

CardBody.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
