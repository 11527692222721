import PropTypes from "prop-types";

import "./subheading.scss";
import clsx from "clsx";

export const SubHeading = ({ className, children }) => {
  return <h3 className={clsx("subheading", className)}>{children}</h3>;
};

SubHeading.displayName = "Subheading";

SubHeading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
