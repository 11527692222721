import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import { Router } from "./router";

import { initIcons } from "./assets/icons";

import "./index.scss";

import { datadogRum } from "@datadog/browser-rum";
import {
  addSWInfoMessageListener,
  PHANTOM_USER_MESSAGE_TYPES,
  sendPhantomUserMessage,
  SW_INFO_MESSAGE_TYPES,
} from "@lib/sw.helpers";
import { OrganizationTrackPlugin } from "@lib/tracking.plugins";
import {
  ButtonClickTrackingPlugin,
  enableButtonClickTracking,
} from "@snowplow/browser-plugin-button-click-tracking";
import {
  enableLinkClickTracking,
  LinkClickTrackingPlugin,
} from "@snowplow/browser-plugin-link-click-tracking";
import {
  enableActivityTracking,
  newTracker,
  trackPageView,
} from "@snowplow/browser-tracker";

if (import.meta.env.SNOWPLOW_ENABLED?.toLowerCase() === "true") {
  console.info("Enabling snowplow...");

  newTracker("sp1", import.meta.env.SNOWPLOW_COLLECTOR_URL, {
    appId: "ceStudentExperience",
    discoverRootDomain: true,
    cookieSameSite: "Lax",
    contexts: {
      performanceTiming: true,
    },
    plugins: [
      OrganizationTrackPlugin.init(),
      ButtonClickTrackingPlugin(),
      LinkClickTrackingPlugin(),
    ],
  });

  enableActivityTracking({
    minimumVisitLength: 30,
    heartbeatDelay: 10,
  });
  enableButtonClickTracking();
  enableLinkClickTracking({ pseudoClicks: true });

  trackPageView();
}

if (import.meta.env.DATADOG_ENABLED) {
  console.info("Enabling DataDog");
  const datadogConfig = [
    "APPLICATION_ID",
    "CLIENT_TOKEN",
    "SERVICE",
    "ENV",
    "VERSION",
    "SESSION_SAMPLE_RATE",
    "SESSION_REPLAY_RATE",
  ].reduce((acc, varName) => {
    acc[varName] = import.meta.env[`DATADOG_${varName}`];
    return acc;
  }, {});

  datadogRum.init({
    applicationId: datadogConfig.APPLICATION_ID,
    clientToken: datadogConfig.CLIENT_TOKEN,
    service: datadogConfig.SERVICE,
    env: datadogConfig.ENV,
    version: datadogConfig.VERSION,
    sessionSampleRate: Number(datadogConfig.SESSION_SAMPLE_RATE),
    sessionReplaySampleRate: Number(datadogConfig.SESSION_REPLAY_RATE),
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
  });
}

initIcons();

ReactDOM.createRoot(document.querySelector("#root")).render(
  <StrictMode>
    <Router />
  </StrictMode>,
);

// Make sure service worker data is reset on load
sendPhantomUserMessage(PHANTOM_USER_MESSAGE_TYPES.refresh);

addSWInfoMessageListener(({ data: { type, payload } }) => {
  if (type === SW_INFO_MESSAGE_TYPES.error) {
    new Notification(payload);
  } else if (type === SW_INFO_MESSAGE_TYPES.update) {
    // TODO notify user that app has updated
  }
});

try {
  await navigator.serviceWorker.ready;
  // Check if the service worker is controlling the page
  if (!navigator.serviceWorker.controller) {
    // If no service worker, force a reload.
    window.location.reload();
  }
} catch (error) {
  console.error("Service worker registration failed:", error);
}

if (import.meta.env.DEV) {
  // permissions can only be requested in response to a user interaction, so
  // we'll request with the first click
  window.addEventListener("click", () => {
    Notification.requestPermission();
  });
}
