import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useCertificationsQueryOptions = (careerId) => {
  const queryFn = useApiRequest({
    url: "/certifications",
    params: { career_id: careerId },
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["certifications", careerId],
    queryFn,
    enabled: !!careerId,
  };
};

export const useCertifications = (careerId) => {
  return useQuery(useCertificationsQueryOptions(careerId));
};
