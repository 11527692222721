import clsx from "clsx";
import PropTypes from "prop-types";

export const CardFooter = ({ className, children }) => {
  return <div className={clsx("card-footer", className)}>{children}</div>;
};

CardFooter.displayName = "Card Footer";

CardFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
