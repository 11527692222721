import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useUserSimulations = () => {
  const queryFn = useApiRequest({
    url: "users/me/simulations",
    method: HttpMethod.Get,
  });

  return useQuery({
    queryKey: ["simulations"],
    queryFn,
    placeholderData: [],
    select: (data) =>
      data.map((d) => ({ ...d, completedAt: new Date(d.completedAt) })),
  });
};
