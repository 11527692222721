import clsx from "clsx";
import PropTypes from "prop-types";

import "./header-image.scss";

export const HeaderImage = ({ imageUrl, className }) => {
  return (
    <div
      className={clsx("header-image-container", className)}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    ></div>
  );
};

HeaderImage.displayName = "Header Image";

HeaderImage.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  className: PropTypes.string,
};
