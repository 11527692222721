import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useUserFavoriteCareersOptions = () => {
  const queryFn = useApiRequest({
    url: "/users/me/favorite-careers",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["user-favorite-careers"],
    queryFn,
  };
};

export const useUserFavoriteCareers = () => {
  return useQuery(useUserFavoriteCareersOptions());
};
