/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useTexture } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { useMotionValue } from "framer-motion";
import { motion } from "framer-motion-3d";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { JourneyButton, JourneyImage } from "@pages/journey-map/components";

import dash from "@images/journey-map/dash.png";
import getReady from "@images/journey-map/get-ready.png";
import map1 from "@images/journey-map/map-1.png";
import pathToGetReady from "@images/journey-map/path-to-get-ready.png";

const dashAnimation = {
  // eslint-disable-next-line unicorn/no-null
  x: [null, 153, 57.68],
  // eslint-disable-next-line unicorn/no-null
  y: [null, -514, -287.55],
};

export const MapOne = ({
  onMapAnimationComplete,
  onMoveToStep,
  triggerMapAnimation,
}) => {
  const [animate, setAnimate] = useState();
  const textures = useTexture({ dash, map1, getReady, pathToGetReady });
  const dashXPosition = useMotionValue(59);
  const dashYPosition = useMotionValue(-659);

  const { data: journeySteps } = useJourneySteps();

  useEffect(() => {
    const unsubs = [dashYPosition.on("change", () => invalidate())];

    return () => {
      for (const unsub of unsubs) {
        unsub();
      }
    };
  }, [dashXPosition, dashYPosition]);

  useEffect(() => {
    if (triggerMapAnimation) {
      if (dashYPosition.get() == dashAnimation.y.at(-1)) {
        onMapAnimationComplete();
      } else {
        setAnimate(dashAnimation);
      }
    }
  }, [triggerMapAnimation]);

  return (
    <group>
      <JourneyImage
        position={[0, -142]}
        size={[393, 592]}
        texture={textures.map1}
      />
      <JourneyImage
        position={[85, -376.5]}
        size={[130.19, 290.5]}
        texture={textures.pathToGetReady}
        transparent
      />
      <JourneyButton
        position={[47.68, -287.55, 0.1]}
        onClick={() => onMoveToStep(journeySteps.stepsByCode.INT_INV)}
        size={[93.32, 90.45]}
        texture={textures.getReady}
        labelOffset={[2, 66.45]}
        text="Get Ready"
      />
      <motion.group
        position={[dashXPosition, dashYPosition, 1]}
        animate={animate}
        onAnimationComplete={onMapAnimationComplete}
        transition={{ duration: 1.5, times: [0, 0.4, 1] }}
      >
        <JourneyImage
          position={[0, 0]}
          size={[76.84, 68.06]}
          texture={textures.dash}
          transparent
        />
      </motion.group>
    </group>
  );
};

MapOne.propTypes = {
  onMapAnimationComplete: PropTypes.func,
  onMoveToStep: PropTypes.func,
  triggerMapAnimation: PropTypes.bool,
};
