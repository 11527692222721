import PropTypes from "prop-types";

import { Card, CardBody, CardHeader } from "@features/ui";
import { buttonTracking } from "@lib/tracking.helpers";
import { Button } from "@transfr-inc/dashboard-components/forms";

import { CareerCourses } from "./components/career-courses";

import "./index.scss";

export const HighSchool = ({ careerId, onClose }) => {
  return (
    <Card className="high-school-card">
      <CardHeader
        title={<h1>High School</h1>}
        toolbar={
          <Button
            onClick={onClose}
            icon={["fa-regular", "xmark"]}
            {...buttonTracking("high-school", "close")}
          />
        }
      >
        <div className="subheader">
          Talk with your counselor about the following courses that will help
          you on this path.
        </div>
      </CardHeader>
      <CardBody className="high-school-card-body">
        <span className="note-container">
          *All plans of study should meet local & state high school graduation
          and college entrance requirements.
        </span>
        <CareerCourses careerId={careerId} />
      </CardBody>
    </Card>
  );
};

HighSchool.displayName = "High School Card";

HighSchool.propTypes = {
  careerId: PropTypes.number,
  onClose: PropTypes.func,
};
