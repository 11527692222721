import Skeleton from "react-loading-skeleton";

import "./list-item.loader.scss";

export const ListItemLoader = () => {
  return (
    <div className="list-item-loader">
      <Skeleton className="index-loader"></Skeleton>
      <div className="content">
        <Skeleton width={"11.25rem"} />
        <Skeleton width={"19.125rem"} />
        <div className="tags">
          <Skeleton width={"4.3125rem"} />
          <Skeleton width={"4.3125rem"} />
        </div>
      </div>
    </div>
  );
};
