import PropTypes from "prop-types";

import { CubicBezierLine } from "@react-three/drei";

export const CubicBezierLines = ({ points, ...rest }) => {
  const lines = [];

  const workingPoints = [...points];
  let start = workingPoints.shift();
  while (workingPoints.length > 0) {
    const midA = workingPoints.shift();
    const midB = workingPoints.shift();
    const end = workingPoints.shift();

    lines.push(
      <CubicBezierLine
        key={lines.length}
        start={start}
        end={end}
        midA={midA}
        midB={midB}
        {...rest}
      />,
    );

    start = end;
  }

  return lines;
};

CubicBezierLines.propTypes = {
  points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};
