import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateCareerPlanGoal = (careerPlanId, onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: `/career-plans/${careerPlanId}/goals`,
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["create-career-plan-goal", careerPlanId],
    mutationFn: (goal) => mutationFn({ data: goal }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ["career-plan"], exact: true });
      onSuccess?.(data, variables);
    },
  });
};
