import PropTypes from "prop-types";

import { Slideshow } from "./slideshow";

export const SlideshowControlled = ({
  slideshow,
  numberSlides,
  className,
  children,
}) => {
  return (
    <Slideshow
      className={className}
      numberSlides={numberSlides}
      index={slideshow.currentIndex}
      indicatorEnabled
      controlsEnabled
      onNextSlide={slideshow.next}
      onPreviousSlide={slideshow.previous}
    >
      {children}
    </Slideshow>
  );
};

SlideshowControlled.propTypes = {
  slideshow: PropTypes.object,
  numberSlides: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node,
};
