import PropTypes from "prop-types";

import { Skills } from "@features/skills";
import { Sheet } from "@features/ui";

import { useUserJourney } from "../api/use-user-journey";

import "./skills.sheet.scss";

export const SkillsSheet = ({ open, onClose }) => {
  const {
    data: { currentJourneyCareer },
  } = useUserJourney();

  return (
    <Sheet open={open} className="skills-sheet">
      <Skills careerId={currentJourneyCareer?.id} onClose={onClose} />
    </Sheet>
  );
};

SkillsSheet.displayName = "Skills Sheet";

SkillsSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
