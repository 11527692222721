import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";

const submitPassthroughCodes = [HttpStatusCode.Conflict];

export const useCreateCareerPlan = (transfrId, onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: "users/me/career-plan",
    method: HttpMethod.Post,
    useResponseData: false,
    passthroughCodes: submitPassthroughCodes,
  });

  return useMutation({
    mutationKey: ["create-career-plan", transfrId],
    mutationFn: () => mutationFn({ data: { transfr_id: transfrId } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["career-plan"], exact: true });
      queryClient.invalidateQueries({
        queryKey: ["user-journey-status"],
        exact: true,
      });
      onSuccess?.();
    },
  });
};
