import { decodeJwt } from "jose";
import PropTypes from "prop-types";
import { createContext, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";

import { useApiRequest } from "@hooks/use-api-request";
import { useLocalStorage } from "@hooks/use-local-storage";
import { HttpMethod } from "@lib/http.helpers";
import {
  PHANTOM_USER_MESSAGE_TYPES,
  sendPhantomUserMessage,
} from "@lib/sw.helpers";
import { OrganizationTrackPlugin } from "@lib/tracking.plugins";
import { setUserId } from "@snowplow/browser-tracker";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const queryClient = useQueryClient();
  const [authError, setAuthError] = useState();
  const [userLoginExpired, setUserLoginExpired] = useState(false);
  const [user, setUser] = useLocalStorage("user", {
    expiredCb: () => {
      setUserLoginExpired(true);
      logout();
      return true;
    },
  });
  const navigate = useNavigate();

  const mutationFn = useApiRequest({
    url: "auth/login",
    method: HttpMethod.Post,
    unversioned: true,
  });

  const {
    data,
    isPending,
    mutateAsync: doLogin,
  } = useMutation({
    mutationKey: ["login"],
    mutationFn,
  });

  const navigateHome = () => navigate("/", { state: { className: "map" } });
  const navigateTerms = () => navigate("/terms", { replace: true });

  useEffect(() => {
    if (user) {
      // let service worker know what role the user has
      // this is done immediately after login but is repeated here to handle
      // things like page refreshes
      sendPhantomUserMessage(PHANTOM_USER_MESSAGE_TYPES.role, {
        role: user.claims.act,
      });

      // Set tracked user id and organization code for snowplow
      setUserId(user.userId);
      OrganizationTrackPlugin.setOrganizationCode(user.orgCode);

      if (!user.agreementAcceptedAt) {
        navigateTerms();
      }
    }
  }, [user]);

  useEffect(() => {
    if (data) {
      if (data.token) {
        data.claims = decodeJwt(data.token);
        setUser(data, data.claims.exp);
        setUserLoginExpired(false);

        // let service worker know what role the user has
        // do this ASAP after login so _all_ post-login requests will be
        // intercepted if necesary
        sendPhantomUserMessage(PHANTOM_USER_MESSAGE_TYPES.role, {
          role: data.claims.act,
        });

        if (data.agreementAcceptedAt) {
          navigateHome();
        } else {
          navigateTerms();
        }
      } else {
        setAuthError({ detail: "Incorrect email or password" });
        setUser();
        setUserId();
        OrganizationTrackPlugin.setOrganizationCode();
      }
    }
  }, [data]);

  const login = async ({ email, password, impersonationToken }) => {
    setAuthError();
    setUser();

    await doLogin(
      {
        data: {
          email,
          password,
          impersonation_token: impersonationToken,
        },
      },
      {
        onError: (err) => {
          const detail = err.data?.detail;
          if (
            typeof detail === "string" &&
            ["unauthorized", "forbidden"].includes(detail.toLowerCase())
          ) {
            setAuthError({ detail: "Incorrect email or password" });
          } else {
            setAuthError({
              detail:
                typeof detail === "string"
                  ? detail
                  : err.message ?? "Unknown Error",
            });
          }
        },
      },
    );
  };

  const logout = () => {
    setUser();
    queryClient.cancelQueries();
    queryClient.clear();
    // reset role to re-enable tracking and disable intercepting
    sendPhantomUserMessage(PHANTOM_USER_MESSAGE_TYPES.role);
    // Clear tracked user id and organization code for snowplow
    setUserId();
    OrganizationTrackPlugin.setOrganizationCode();
  };

  const value = useMemo(
    () => ({
      user,
      userLoginExpired,
      login,
      logout,
      navigateHome,
      loading: isPending,
      error: authError,
    }),
    [authError, isPending, user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};
