import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const usePathwaySteps = (transfrId) => {
  const queryFn = useApiRequest({
    url: "/pathway-steps",
    method: HttpMethod.Get,
    params: { transfr_id: transfrId },
  });

  return useQuery({
    queryKey: ["pathway-steps", transfrId],
    queryFn,
  });
};
