import clsx from "clsx";
import PropTypes from "prop-types";

import "./badge.scss";

export const Badge = ({ className, children }) => {
  return <span className={clsx("badge-element", className)}>{children}</span>;
};

Badge.displayName = "Badge";

Badge.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
