import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAddUserFavoriteCareer = ({
  careerId,
  invalidateUserCareerMatches,
  onSuccess,
}) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: `users/me/favorite-careers/${careerId}`,
    method: HttpMethod.Put,
  });

  return useMutation({
    mutationKey: ["add-user-favorite-career", careerId],
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-favorite-careers"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["user-career-ratings"],
        exact: true,
      });

      if (invalidateUserCareerMatches) {
        queryClient.invalidateQueries({
          queryKey: ["career-matches"],
          refetchType: "inactive",
        });
      }

      onSuccess?.();
    },
  });
};
