import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { STEP_TO_MAP } from "@context/journey.context";
import { Favorites } from "@pages/favorites/components/favorites";
import { CareerDetailsSheet } from "@pages/favorites/views/career-details.sheet";

import { useJourney } from "@pages/journey-map/hooks/use-journey";

import "./index.scss";

export const FavoritesPage = () => {
  const [favoriteSelected, setFavoriteSelected] = useState();
  const navigate = useNavigate();

  const { requestMapChange } = useJourney();

  const onCloseHandler = () => {
    setFavoriteSelected();
  };

  const onFavoriteClick = (career) => {
    setFavoriteSelected(career);
  };

  const onCareerSelected = () => {
    navigate("/");
    requestMapChange(STEP_TO_MAP.HIGH_SCH);
  };

  return (
    <div className="favorites-page">
      <Favorites onFavoriteClick={onFavoriteClick} />
      <CareerDetailsSheet
        career={favoriteSelected}
        onClose={onCloseHandler}
        onBreadcrumbClick={onCloseHandler}
        onCareerSelected={onCareerSelected}
      />
    </div>
  );
};
