import { useApiRequest } from "@hooks/use-api-request";
import { useMutation } from "@tanstack/react-query";

export const useDownloadPathwayPdf = (userId) => {
  const mutationFn = useApiRequest({
    url: "/reports/user-pathway",
    params: { user_id: userId },
    responseType: "blob",
  });

  return useMutation({
    mutationKey: ["download-pathway-pdf"],
    mutationFn,
  });
};
