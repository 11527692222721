export const NUM_INITIAL_QUESTIONS = 1;
export const NUM_RATINGS_REQUIRED = 3;

export const SURVEY_STATES = {
  initializing: "initializing",
  initialized: "initialized",
  askPrelim: "askPrelim",
  getPrelim: "getPrelim",
  survey: "survey",
  loadingCareerMatches: "loadingCareerMatches",
  ratingCareers: "ratingCareers",
  complete: "complete",
};

export const initialContext = {
  preliminaryCareersLoaded: false,
  preliminaryCareers: [],
  preliminaryCareersSkipped: false,
  surveyQuestionsLoaded: false,
  surveyQuestions: [],
  currentSurveyQuestionIndex: 0,
  careerMatchesLoaded: false,
  careerMatches: [],
  currentCareerMatchIndex: 0,
  careerRatingsLoaded: false,
  careerRatings: [],
};
