import { useContext } from "react";

import { ScreenBreakpointsContext } from "@context/screen-breakpoints.context";

export const useBreakpoints = () => {
  const { isMobile, isTablet, isDesktop } = useContext(
    ScreenBreakpointsContext,
  );
  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};
