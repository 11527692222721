import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useCourseQueryOptions = (careerId) => {
  const queryFn = useApiRequest({
    url: "/courses",
    params: { career_id: careerId },
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["courses", careerId],
    queryFn,
    enabled: !!careerId,
  };
};

export const useCourses = (careerId) => {
  return useQuery(useCourseQueryOptions(careerId));
};
