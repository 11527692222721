import { Children } from "react";

import { onlyText } from "react-children-utilities";

// eslint-disable-next-line unicorn/no-null
const EMPTY_VALS = new Set(["", null, undefined]);

export const buttonTracking = (...hierarchy) => {
  const str = hierarchy
    .map((h) =>
      // convert react elements to text
      Children.map(h, (child) => onlyText(child))?.find(
        // find first entry that isn't empty
        (child) => !EMPTY_VALS.has(child),
      ),
    )
    // the map/filter above could still result in undefined values
    .filter((h) => h !== undefined)
    // normalize the text
    .map((h) => h.toLowerCase().replaceAll(" ", "-"))
    .join(":");

  return {
    "data-sp-button-label": str,
  };
};
