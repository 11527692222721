import clsx from "clsx";
import PropTypes from "prop-types";
import { relevanceType } from "../types";

import { Badge } from "@features/ui";

import "./headset-image.scss";

export const HeadsetImage = ({ className, simulationCode, relevance }) => {
  const imageUrl = `/images/simulations/${simulationCode}.png`;

  return (
    <div
      className={clsx("headset-image-container", className)}
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      <div className="headset-image-nose"></div>
      {relevance !== "exact" && (
        <Badge className="related-skills-badge">Related Skills</Badge>
      )}
    </div>
  );
};

HeadsetImage.displayName = "Headset Image";

HeadsetImage.propTypes = {
  className: PropTypes.string,
  simulationCode: PropTypes.number,
  relevance: relevanceType,
};
