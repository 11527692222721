/* eslint react/no-unknown-property: 0 */

import { useTexture } from "@react-three/drei";
import PropTypes from "prop-types";

import dash from "@images/journey-map/dash.png";
import map2 from "@images/journey-map/map-2.png";
import colors from "@styles/colors.module.scss";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { JourneyButton, JourneyImage } from "@pages/journey-map/components";

export const MapTwo = ({ onMoveToStep }) => {
  const textures = useTexture({ dash, map2 });

  const { data: journeySteps } = useJourneySteps();

  return (
    <group>
      <JourneyImage
        position={[0, -143]}
        size={[393, 592]}
        texture={textures.map2}
      />
      <JourneyButton
        position={[274, -549, 0.1]}
        onClick={() => onMoveToStep(journeySteps.stepsByCode.MATCHES)}
        size={[79, 106]}
        labelOffset={[1, 82]}
        text="Matches"
        backgroundColor={colors.slightlyGreenOffWhite}
      />
      <JourneyImage
        position={[174, -577, 0.2]}
        size={[76.84, 68.06]}
        texture={textures.dash}
        transparent
      />
    </group>
  );
};

MapTwo.propTypes = {
  onMoveToStep: PropTypes.func,
};
