import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef } from "react";

import { Badge } from "@features/ui";

import { QuestionListItem } from "./question.list-item";

import "./survey.completed.scss";

export const SurveyCompleted = forwardRef(
  ({ questions, userCareerRatings }, elementRef) => {
    const imageUrl = "/images/interest-inventory/end.png";

    return (
      <motion.div
        ref={elementRef}
        className="interest-inventory-completed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <div>
          <img src={imageUrl} alt="survey completed image" />
          <h1>All packed and ready for your journey!</h1>
        </div>
        <div className="interest-inventory-questions-list-container">
          <h3>
            My Answers{" "}
            <Badge>{questions?.length + userCareerRatings?.length || ""}</Badge>
          </h3>
          <div className="interest-inventory-questions-list">
            {userCareerRatings?.map((rating) => (
              <QuestionListItem
                key={`career-rating.${rating.careerId}`}
                value={{
                  question: rating.careerTitle,
                  answer: rating.rating > 0,
                }}
              />
            ))}
            {questions.map((question) => (
              <QuestionListItem
                key={`question-item.${question.id}`}
                value={question}
              />
            ))}
          </div>
        </div>
      </motion.div>
    );
  },
);

SurveyCompleted.displayName = "Interest Inventory Completed";

SurveyCompleted.propTypes = {
  questions: PropTypes.array,
  userCareerRatings: PropTypes.arrayOf(
    PropTypes.shape({
      careerId: PropTypes.number,
      rating: PropTypes.number,
    }),
  ),
};
