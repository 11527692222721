import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { Section, SubHeading, Tab, Tabs } from "@features/ui";
import { EmploymentStatsTab } from "./employment-stats.tab";

import "./employment.section.scss";

const percentileIndicators = {
  min: 10,
  max: 90,
  average: 50,
};

const getEarningsValue = (data, percentile) => {
  const earning = data?.find((d) => d.percentile === percentile)?.earnings ?? 0;
  return Math.trunc(earning);
};

const getEarningsRange = (data) => {
  return {
    min: getEarningsValue(data, percentileIndicators.min),
    max: getEarningsValue(data, percentileIndicators.max),
    average: getEarningsValue(data, percentileIndicators.average),
  };
};

export const EmploymentSection = ({ regionalData, nationalData, loading }) => {
  const regionalEarnings = getEarningsRange(regionalData?.earnings);
  const nationalEarnings = getEarningsRange(nationalData?.earnings);
  const titleLoader = <Skeleton width={"3.75rem"} height={"1.25rem"} />;

  if (!loading && !nationalData && !regionalData) {
    return;
  }

  return (
    <Section className="employment-section">
      <SubHeading>Employment</SubHeading>
      <Tabs className={"employment-tabs"}>
        {regionalData && (
          <Tab title={"Near me"}>
            <EmploymentStatsTab
              earnings={regionalEarnings}
              outlookIndicator={regionalData?.outlook}
            />
          </Tab>
        )}
        <Tab title={loading ? titleLoader : "National"}>
          <EmploymentStatsTab
            earnings={nationalEarnings}
            national={true}
            outlookIndicator={nationalData?.outlook}
          />
        </Tab>
      </Tabs>
    </Section>
  );
};

EmploymentSection.displayName = "Career Employment Section";

EmploymentSection.propTypes = {
  loading: PropTypes.bool,
  nationalData: PropTypes.shape({
    earnings: PropTypes.array,
    outlook: PropTypes.number,
  }),
  regionalData: PropTypes.shape({
    earnings: PropTypes.array,
    outlook: PropTypes.number,
  }),
};
