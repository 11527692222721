import { useMemo } from "react";
import PropTypes from "prop-types";

import { CourseList } from "./course.list";
import { EmptyArray } from "@lib/app.helpers";
import { ErrorToast } from "@features/ui";
import { useCourses } from "../api/use-courses";

const CoreCoursesKey = "core";

const CoursesPlaceholder = {
  tech: EmptyArray(2),
  general: EmptyArray(2),
  core: EmptyArray(2),
};

export const CareerCourses = ({ careerId }) => {
  const { data, isError, isPending } = useCourses(careerId);

  const courses = useMemo(
    () => (isPending ? CoursesPlaceholder : data),
    [isPending],
  );

  if (isError) {
    return <ErrorToast open />;
  }

  return (
    <>
      {Object.entries(courses).map(
        ([key, value]) =>
          key !== CoreCoursesKey && (
            <CourseList
              key={`course-type-${key}`}
              courses={value}
              courseType={key}
            />
          ),
      )}
      {courses[CoreCoursesKey] && (
        <CourseList
          courseType={CoreCoursesKey}
          courses={courses[CoreCoursesKey]}
          className="core-course-list"
        />
      )}
    </>
  );
};

CareerCourses.displayName = "Career Courses";

CareerCourses.propTypes = {
  careerId: PropTypes.number,
};
