import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useCareerPlanQueryOptions = () => {
  const queryFn = useApiRequest({
    url: "users/me/career-plan",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["career-plan"],
    queryFn,
    placeholderData: {},
    select: (data) => (data === "" ? {} : data),
  };
};

export const useCareerPlan = () => {
  return useQuery(useCareerPlanQueryOptions());
};
