/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";

import { useLayoutEffect, useMemo, useRef } from "react";

import { Color } from "three";
import ThreeMeshUI from "three-mesh-ui";

import poppinsSemiBoldFamily from "@fonts/Poppins-SemiBold-msdf.json";
import poppinsSemiBoldTexture from "@fonts/Poppins-SemiBold.png";
import colors from "@styles/colors.module.scss";

export const JourneyLabel = ({
  backgroundColor,
  backgroundOpacity = 1,
  fontSize,
  justifyContent = "center",
  position: [x, y, z] = [0, 0, 0],
  size: [width, height],
  text,
  textAlign = "center",
  textPositionAdjust,
}) => {
  const ref = useRef();

  const blockArgs = useMemo(() => {
    return [
      {
        width,
        height,
        backgroundColor: new Color(backgroundColor).convertLinearToSRGB(),
        backgroundOpacity,
        borderRadius: 4,
      },
    ];
  }, [width, height, backgroundColor, backgroundOpacity]);

  useLayoutEffect(() => {
    const textBlock = new ThreeMeshUI.Text({
      content: text,
      fontColor: new Color(colors.black).convertLinearToSRGB(),
      fontSize,
      fontFamily: poppinsSemiBoldFamily,
      fontTexture: poppinsSemiBoldTexture,
      autoLayout: textPositionAdjust ? false : true,
    });
    if (textPositionAdjust) {
      const [x, y, z = 0] = textPositionAdjust;
      textBlock.position.set(x, y, z);
    }
    ref.current.add(textBlock);

    return () => {
      ref.current.remove(textBlock);
    };
  }, [fontSize, text, textPositionAdjust]);

  return (
    <block
      ref={ref}
      position={[x + width / 2, y - height / 2, z]}
      justifyContent={justifyContent}
      textAlign={textAlign}
      args={blockArgs}
    />
  );
};

JourneyLabel.propTypes = {
  backgroundColor: PropTypes.string,
  backgroundOpacity: PropTypes.number,
  fontSize: PropTypes.number,
  justifyContent: PropTypes.string,
  position: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
  textAlign: PropTypes.oneOf(["center", "left"]),
  textPositionAdjust: PropTypes.arrayOf(PropTypes.number),
};
