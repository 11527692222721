import PropTypes from "prop-types";
import { useState } from "react";

import { buttonTracking } from "@lib/tracking.helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ToggleButton } from "@transfr-inc/dashboard-components/forms";

import "./journey-view.toggle.scss";

export const JourneyViewToggle = ({ onToggle }) => {
  const [buttonTrackingSelection, setButtonTrackingSelection] =
    useState("map-view");

  const onChange = (value) => {
    setButtonTrackingSelection(value ? "shortcuts-view" : "map-view");
    onToggle?.();
  };

  return (
    <ToggleButton
      className="journey-view-toggle"
      onChange={onChange}
      affirmText={<FontAwesomeIcon icon={["fasr", "location-dot"]} />}
      negateText={<FontAwesomeIcon icon={["fasr", "list-check"]} />}
      {...buttonTracking("explore", buttonTrackingSelection)}
    />
  );
};

JourneyViewToggle.displayName = "Journey View Toggle Button";

JourneyViewToggle.propTypes = {
  onToggle: PropTypes.func,
};
