import {
  PageHeader,
  PageLayout,
} from "@transfr-inc/dashboard-components/layouts";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { Section } from "@features/ui";
import { useAuth } from "@hooks/use-auth";
import { buttonTracking } from "@lib/tracking.helpers";

import { MetadataSection, UserInfoSection } from "./components";

import "./index.scss";

export const ProfilePage = () => {
  const { logout, user } = useAuth();

  const handleLogout = () => {
    logout();
  };

  const handleResetPassword = () => {
    console.log("Reset Password Handler", user);
  };

  const header = (
    <PageHeader className="profile-page-header" title={"Profile"} />
  );

  return (
    <PageLayout className={"profile-page"} header={header}>
      <Section>
        <UserInfoSection user={user} />
        <Button
          primary
          icon={["fa-regular", "door-open"]}
          onClick={handleLogout}
          {...buttonTracking("profile", "sign-out")}
        >
          Sign Out
        </Button>
      </Section>
      <MetadataSection onResetPasswordClick={handleResetPassword} user={user} />
    </PageLayout>
  );
};
