export const PHANTOM_USER_MESSAGE_TYPES = {
  refresh: "refresh",
  role: "role",
};
export const SW_INFO_MESSAGE_TYPES = {
  error: "error",
  update: "update",
};

const phantomUserBroadcastChannel = new BroadcastChannel("phantom-user");
const swInfoBroadcastChannel = new BroadcastChannel("sw-info");

const addMessageListener = (channel, listener) =>
  channel.addEventListener("message", listener);

const sendMessage = (channel, type, payload) =>
  channel.postMessage({ type, payload });

export const addPhantomUserMessageListener = (listener) =>
  addMessageListener(phantomUserBroadcastChannel, listener);

export const sendPhantomUserMessage = (type, payload) =>
  sendMessage(phantomUserBroadcastChannel, type, payload);

export const addSWInfoMessageListener = (listener) =>
  addMessageListener(swInfoBroadcastChannel, listener);

export const sendSWInfoMessage = (type, payload) =>
  sendMessage(swInfoBroadcastChannel, type, payload);
