export const AppRoutes = {
  favorites: {
    pathname: "/favorites",
    className: "favorites",
  },
  journeyMap: {
    pathname: "/",
    className: "map",
  },
  profile: {
    pathname: "/profile",
    className: "profile",
  },
};
