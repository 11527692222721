import { useMutation } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useSetUserPreliminaryCareers = (onSuccess) => {
  const mutationFn = useApiRequest({
    url: `/users/me/preliminary-careers`,
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["set-user-preliminary-careers"],
    mutationFn: ({ onetCodes }) =>
      mutationFn({ data: { onet_codes: onetCodes } }),
    onSuccess: () => {
      onSuccess?.();
    },
  });
};
