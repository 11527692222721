/* eslint react/no-unknown-property: 0 */

import { Canvas } from "@react-three/fiber";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useLayoutEffect, useRef, useState } from "react";

import {
  JourneyMapBackground,
  JourneyMapFooter,
  JourneyMapHeader,
} from "../../components";
import { JourneyMapCanvas } from "./journey-map.canvas";

import {
  Bloom,
  EffectComposer,
  N8AO,
  Vignette,
} from "@react-three/postprocessing";

import { BlendFunction } from "postprocessing";

export const JourneyMapView = forwardRef(
  (
    {
      pageHeight,
      currentMap,
      triggerMapAnimation,
      moveToMap,
      moveToStep,
      onMapAnimationComplete,
      unlockedSteps,
      isPlanShared,
    },
    ref,
  ) => {
    const headerRef = useRef();
    const footerRef = useRef();

    const [mapDimensions, setMapDimensions] = useState({
      top: 0,
      bottom: 0,
      height: 0,
    });

    useLayoutEffect(() => {
      const handleResize = () => {
        const bottom = footerRef.current?.offsetHeight;
        const top = headerRef.current?.offsetHeight;
        const height = pageHeight - top - bottom;

        setMapDimensions({
          top,
          bottom,
          height,
        });
      };
      handleResize();

      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [currentMap, headerRef.current, pageHeight, footerRef.current]);

    return (
      <motion.div
        className="journey-map-view mode-3d"
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        layout
      >
        <JourneyMapBackground currentMap={currentMap} isMap3DEnabled />
        <JourneyMapHeader ref={headerRef} inverted />
        <div className="journey-map">
          <Canvas
            frameloop="demand"
            shadows
            gl={{ antialias: false, depth: false, stencil: false }}
            camera={{ near: 10, far: 3000 }}
          >
            <fog attach="fog" args={["white", 850, 1200]} />
            <color attach="background" args={["white"]} />
            {/*lighting*/}
            <directionalLight
              castShadow
              intensity={3}
              position={[80, 60, 50]}
              shadow-mapSize={1024}
              shadow-bias={-0.0001}
            >
              <orthographicCamera
                attach="shadow-camera"
                args={[-400, 400, 400, -400, 1, 100_000]}
              />
            </directionalLight>
            <ambientLight intensity={1} />
            <JourneyMapCanvas
              dimensions={mapDimensions}
              mapToTriggerAnimation={triggerMapAnimation}
              onMoveToStep={moveToStep}
              onMoveToMap={moveToMap}
              onMapAnimationComplete={onMapAnimationComplete}
              showMap={currentMap}
              unlockedSteps={unlockedSteps}
              isPlanShared={isPlanShared}
            />
            <EffectComposer multisampling={0} enableNormalPass={false}>
              <Bloom
                blendFunction={BlendFunction.SCREEN}
                intensity={1.25}
                luminanceThreshold={1}
                levels={4}
                luminanceSmoothing={0.4}
                mipmapBlur
              />
              <Vignette eskil={false} offset={0.1} darkness={0.6} />
              <N8AO aoRadius={5} distanceFalloff={0.5} intensity={5} halfRes />
            </EffectComposer>
          </Canvas>
        </div>
        <JourneyMapFooter ref={footerRef} inverted />
      </motion.div>
    );
  },
);
JourneyMapView.displayName = "Journey Map View";
JourneyMapView.propTypes = {
  pageHeight: PropTypes.number,
  currentMap: PropTypes.number,
  triggerMapAnimation: PropTypes.number,
  moveToMap: PropTypes.func,
  moveToStep: PropTypes.func,
  onMapAnimationComplete: PropTypes.func,
  unlockedSteps: PropTypes.array,
  isPlanShared: PropTypes.bool,
};
