import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useUserCareerRatings = () => {
  const queryFn = useApiRequest({
    url: "/users/me/career-ratings",
    method: HttpMethod.Get,
    refetchOnMount: "always",
  });

  return useQuery({
    queryKey: ["user-career-ratings"],
    queryFn,
  });
};
