import PropTypes from "prop-types";
import { useLayoutEffect } from "react";

import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownItems,
} from "@transfr-inc/dashboard-components/forms";

import { EmptyArray } from "@lib/app.helpers";

import "./goals.container.scss";
import Skeleton from "react-loading-skeleton";

export const GoalsContainer = ({
  type,
  goals,
  careerPlanGoals,
  onGoalSelected,
}) => {
  useLayoutEffect(() => {
    // `DropdownItems` calculates the list size and position incorrectly on
    // first load so that it is placed above the field. It recalculates on
    // resize and scroll, so forcing a resize after the goals are actually
    // rendered seems to fix the issue.
    setTimeout(() => window.dispatchEvent(new Event("resize")), 250);
  }, [goals]);

  const goalsLoader =
    goals?.length === 0
      ? EmptyArray(3).map((_, index) => (
          <Skeleton
            key={`${type}.${index}`}
            height={"2.5rem"}
            containerClassName="tx-skeleton-container"
          />
        ))
      : undefined;

  const selectedGoals = (careerPlanGoals ?? []).reduce(
    (acc, careerPlanGoal) => {
      const goal = goals.find(
        (goal) => goal.id === careerPlanGoal.careerGoalId,
      );
      acc[careerPlanGoal.orderNum] = goal;
      return acc;
    },
    [],
  );
  const availableGoals = (goals ?? []).filter(
    (goal) =>
      !selectedGoals.some((selectedGoal) => selectedGoal.id === goal.id),
  );

  return (
    <div className="goals-container">
      <div className="header">
        {type === "short" ? "Short Term (0-6 Months)" : "Long Term (6+ Months)"}
      </div>
      <div className="content">
        {goalsLoader ??
          EmptyArray(3).map((_, index) => {
            const selectedGoal = selectedGoals[index];

            return (
              <Dropdown
                key={index}
                onOptionSelected={(goal) => onGoalSelected(type, goal, index)}
                selected={selectedGoal}
              >
                <DropdownButton
                  placeholder="Select a goal..."
                  required={index === 0}
                  clearEnabled
                >
                  {selectedGoal?.text}
                </DropdownButton>
                <DropdownItems>
                  {selectedGoal && (
                    <DropdownItem value={selectedGoal} propName="id">
                      {selectedGoal.text}
                    </DropdownItem>
                  )}
                  {availableGoals.map((goal) => (
                    <DropdownItem key={goal.id} value={goal} propName="id">
                      {goal.text}
                    </DropdownItem>
                  ))}
                </DropdownItems>
              </Dropdown>
            );
          })}
      </div>
    </div>
  );
};

GoalsContainer.propTypes = {
  type: PropTypes.oneOf(["short", "long"]),
  goals: PropTypes.arrayOf(PropTypes.object),
  careerPlanGoals: PropTypes.arrayOf(PropTypes.object),
  onGoalSelected: PropTypes.func,
};
