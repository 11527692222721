import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useJourneyStepsQueryOptions = () => {
  const queryFn = useApiRequest({
    url: "/journey-steps",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["journey-steps"],
    queryFn,
    placeholderData: [],
    select: (journeySteps) => ({
      steps: journeySteps,
      stepsByCode: journeySteps.reduce((acc, journeyStep) => {
        acc[journeyStep.code] = journeyStep;
        return acc;
      }, {}),
    }),
    // only re-render when the selected data changes
    notifyOnChangeProps: ["data", "error"],
  };
};

export const useJourneySteps = () => {
  return useQuery(useJourneyStepsQueryOptions());
};
