import Skeleton from "react-loading-skeleton";

import "./skill.icon.scss";

export const SkillIconLoader = () => {
  return (
    <div className="skill-icon-container">
      <Skeleton height={"5rem"} width={"5rem"} />
      <Skeleton width={"4.5695rem"} height={"0.625rem"} />
    </div>
  );
};

SkillIconLoader.displayName = "Skill Icon Loader";
