import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faArrowsRotate,
  faCircleUser,
} from "@fortawesome/sharp-light-svg-icons";

import { faTicket } from "@fortawesome/pro-light-svg-icons";

export const initLightIcons = () => {
  library.add(faArrowsRotate, faCircleUser, faTicket);
};
