import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useQuery } from "@tanstack/react-query";

export const useCareerExpert = (transfrId) => {
  const queryFn = useApiRequest({
    url: "/career-experts",
    method: HttpMethod.Get,
    params: {
      transfr_id: transfrId,
    },
  });

  return useQuery({
    queryKey: ["career-expert", transfrId],
    queryFn,
  });
};
