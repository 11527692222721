import PropTypes from "prop-types";
import { useEffect } from "react";

import { Loader } from "@features/ui";

import { useCareerPlan } from "@features/career-details/api/use-career-plan";
import { useCreateCareerPlan } from "@features/career-details/api/use-create-career-plan";

import { useUserJourney } from "../api/use-user-journey";

import { GoalsStartOverConfirmationModal } from "../views/goals-start-over-confirmation.modal";

export const CareerPlanGuard = ({ onContinue, onReject }) => {
  const {
    data: { currentJourneyCareer },
  } = useUserJourney();

  const {
    data: { career },
  } = useCareerPlan();

  const isSameCareer = currentJourneyCareer?.id === career?.id;

  const { mutateAsync: createCareerPlan, isPending: creatingCareerPlan } =
    useCreateCareerPlan(currentJourneyCareer?.transfrId);

  const activateNewCareerPlan = async () => {
    await createCareerPlan();
    onContinue();
  };

  const validateCurrentJourneyCareer = async () => {
    if (isSameCareer) {
      onContinue();
    } else if (!career) {
      activateNewCareerPlan();
    }
  };

  useEffect(() => {
    validateCurrentJourneyCareer();
  }, []);

  return (
    <>
      <GoalsStartOverConfirmationModal
        careerTitle={career?.title}
        open={!isSameCareer && !!career}
        onClose={onReject}
        onConfirm={activateNewCareerPlan}
        onCancel={onReject}
        isNewCareer
      />
      <Loader show={creatingCareerPlan}></Loader>
    </>
  );
};

CareerPlanGuard.displayName = "Replace Career Plan Guard";

CareerPlanGuard.propTypes = {
  onContinue: PropTypes.func,
  onReject: PropTypes.func,
};
