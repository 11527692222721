/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";

import { useTexture } from "@react-three/drei";

import dash from "@images/journey-map/dash.png";
import fire from "@images/journey-map/fire.png";
import map3 from "@images/journey-map/map-3.png";
import pathFromAfterHighSchool from "@images/journey-map/path-from-after-high-school.png";
import colors from "@styles/colors.module.scss";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import {
  JourneyButton,
  JourneyImage,
  JourneyLabel,
  JourneyTitle,
} from "@pages/journey-map/components";

import { useSkills } from "@features/skills/api/use-skills";
import { useAuth } from "@hooks/use-auth";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";

export const MapThree = ({ onMoveToStep }) => {
  const { user } = useAuth();
  const { data: userJourney } = useUserJourney();
  const { data: skills } = useSkills(userJourney.currentJourneyCareer?.id);
  const textures = useTexture({ dash, map3, fire, pathFromAfterHighSchool });

  const { data: journeySteps } = useJourneySteps();

  return (
    <group>
      <JourneyTitle
        description="Pathway:"
        careerTitle={userJourney.currentJourneyCareer?.title}
        backgroundColor={colors.offWhite}
      />
      <JourneyImage
        position={[57, -127.66]}
        size={[144, 151.5]}
        texture={textures.pathFromAfterHighSchool}
        transparent
      />
      <JourneyImage
        position={[45, -206]}
        size={[324, 487]}
        texture={textures.map3}
      />
      {user.msa && (
        <JourneyButton
          position={[143, -284, 0.1]}
          size={[138, 98]}
          labelOffset={[0, 74]}
          text="Extended Learning"
          onClick={() => onMoveToStep(journeySteps.stepsByCode.POST_HIGH_SCH)}
        />
      )}
      <JourneyButton
        position={[145, -461, 0.1]}
        size={[129, 129]}
        labelSize={[100, 24]}
        labelOffset={[15, 94]}
        text="High School"
        onClick={() => onMoveToStep(journeySteps.stepsByCode.HIGH_SCH)}
      />
      <group
        position={[35, -359, 0.1]}
        onClick={() => onMoveToStep(journeySteps.stepsByCode.SKILLS)}
      >
        <JourneyButton
          size={[81, 81]}
          texture={textures.fire}
          labelSize={[83, 24]}
          labelOffset={[1, 73]}
          text="Skills"
          textAlign="left"
          textPositionAdjust={[10, 0]}
        />
        <JourneyLabel
          position={[53, -73, 0.1]}
          size={[22, 24]}
          text={`${skills?.length ?? "..."}`}
          fontSize={12}
          backgroundColor={colors.evenMoreOffWhite}
        />
      </group>
      <JourneyImage
        position={[284, -664, 0.2]}
        scale={[-1, 1]}
        size={[76.84, 68.06]}
        texture={textures.dash}
        transparent
      />
    </group>
  );
};

MapThree.propTypes = {
  onMoveToStep: PropTypes.func,
};
