import PropTypes from "prop-types";
import { useState } from "react";

import { CareerDetails } from "@features/career-details";
import { CareerExpert } from "@features/career-expert";
import { CareerMatches } from "@features/career-matches";
import { Sheet, Slide, Slideshow } from "@features/ui";
import { useSlideShow } from "@hooks/index";

import "./career-matches.sheet.scss";

export const CareerMatchesSheet = ({
  open,
  onClose,
  onCareerSelected,
  onStartOver,
}) => {
  const slideshow = useSlideShow(3);
  const [careerSelected, setCareerSelected] = useState();
  const [careerSelectedIndex, setCareerSelectedIndex] = useState();

  const onCloseHandler = () => {
    onClose?.();
  };

  const onCareerMatchSelected = (career, index) => {
    setCareerSelected(career);
    setCareerSelectedIndex(index);
    slideshow.next();
  };

  const onExpertClick = () => {
    slideshow.next();
  };

  return (
    <Sheet
      open={open}
      className="career-matches-sheet"
      onCloseCompleted={() => slideshow.reset()}
    >
      <Slideshow index={slideshow.currentIndex}>
        <Slide key="career-matches">
          <CareerMatches
            onCareerSelected={onCareerMatchSelected}
            onClose={onCloseHandler}
            onStartOver={onStartOver}
          />
        </Slide>
        <Slide key="career-details">
          <CareerDetails
            onExpertClick={onExpertClick}
            onClose={onCloseHandler}
            onBreadcrumbClick={slideshow.previous}
            career={careerSelected}
            index={careerSelectedIndex}
            onCareerSelected={onCareerSelected}
          />
        </Slide>
        <Slide key="career-expert">
          <CareerExpert
            career={careerSelected}
            onClose={onCloseHandler}
            onBreadcrumbClick={slideshow.previous}
          />
        </Slide>
      </Slideshow>
    </Sheet>
  );
};

CareerMatchesSheet.displayName = "Career Matches Sheet";

CareerMatchesSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCareerSelected: PropTypes.func,
  onStartOver: PropTypes.func,
};
