import PropTypes from "prop-types";

export const Case = ({ value, defaultOption, children }) => {
  return children;
};

Case.propTypes = {
  value: PropTypes.any,
  children: PropTypes.node,
};
