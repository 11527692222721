/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";
import { useLayoutEffect, useMemo, useRef, useState } from "react";

import { Color } from "three";
import ThreeMeshUI from "three-mesh-ui";

import poppinsRegularFamily from "@fonts/Poppins-Regular-msdf.json";
import poppinsRegularTexture from "@fonts/Poppins-Regular.png";
import poppinsSemiBoldFamily from "@fonts/Poppins-SemiBold-msdf.json";
import poppinsSemiBoldTexture from "@fonts/Poppins-SemiBold.png";
import colors from "@styles/colors.module.scss";

import { PROTO_DIMENSIONS } from "../normalize";

const FONT_COLOR = new Color(colors.black).convertLinearToSRGB();
const MAX_WIDTH = 250;
const MIN_WIDTH = 90;
const MIN_HEIGHT = 25;

const DESCRIPTION_BLOCK_ARGS = {
  width: MIN_WIDTH,
  height: 30,
  backgroundOpacity: 0,
  justifyContent: "center",
};

export const JourneyTitle = ({ backgroundColor, careerTitle, description }) => {
  const [size, setSize] = useState({ width: MAX_WIDTH, height: MIN_HEIGHT });

  const containerRef = useRef();

  const containerArgs = useMemo(() => {
    return [
      {
        backgroundColor: new Color(backgroundColor).convertLinearToSRGB(),
        backgroundOpacity: 1,
        borderRadius: 4,
        fontSize: 14,
        fontFamily: poppinsRegularFamily,
        fontTexture: poppinsRegularTexture,
        width: MAX_WIDTH,
        height: DESCRIPTION_BLOCK_ARGS.height + MIN_HEIGHT,
      },
    ];
  }, [backgroundColor, careerTitle]);
  const careerTitleBlockArgs = useMemo(() => {
    return {
      width: MAX_WIDTH,
      height: MIN_HEIGHT,
      backgroundOpacity: 0,
      justifyContent: "start",
      fontFamily: poppinsSemiBoldFamily,
      fontTexture: poppinsSemiBoldTexture,
    };
  }, [size]);

  useLayoutEffect(() => {
    const descriptionBlock = new ThreeMeshUI.Block(DESCRIPTION_BLOCK_ARGS);
    descriptionBlock.add(
      new ThreeMeshUI.Text({
        content: description,
        fontColor: FONT_COLOR,
        fontFamily: poppinsRegularFamily,
        fontTexture: poppinsRegularTexture,
      }),
    );

    const careerTitleBlock = new ThreeMeshUI.Block(careerTitleBlockArgs);
    careerTitleBlock.add(
      new ThreeMeshUI.Text({
        content: careerTitle,
        fontColor: FONT_COLOR,
        fontFamily: poppinsSemiBoldFamily,
        fontTexture: poppinsSemiBoldTexture,
      }),
    );

    containerRef.current.add(descriptionBlock, careerTitleBlock);

    // Adjust the size of the container to better fit the text.
    careerTitleBlock.onAfterUpdate = function () {
      const block = careerTitleBlock;
      if ((block.lines?.length ?? 0) === 0) {
        return;
      }

      let maxWidth = 0;
      let totalHeight = 0;
      for (let i = 0; i < block.lines.length; ++i) {
        if (block.lines[i].width > maxWidth) {
          maxWidth = block.lines[i].width;
        }

        totalHeight += block.lines[i].lineHeight;
      }

      // Padding: top/bottom 5px, left/right 10px
      totalHeight += 10;
      maxWidth += 20;

      // Resize the main container
      containerRef.current.set({
        width: Math.max(MIN_WIDTH, maxWidth),
        height: DESCRIPTION_BLOCK_ARGS.height + totalHeight,
      });
      // Resize the career title container
      setSize({ width: maxWidth, height: totalHeight });
    };

    return () => {
      careerTitleBlock.onAfterUpdate = () => {};
    };
  }, [containerArgs]);

  return (
    <group position={[PROTO_DIMENSIONS.width / 2, -200, 0.1]}>
      <block ref={containerRef} args={containerArgs} />
    </group>
  );
};

JourneyTitle.propTypes = {
  backgroundColor: PropTypes.string,
  careerTitle: PropTypes.string,
  description: PropTypes.string,
};
