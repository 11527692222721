import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const CareerRating = {
  zero: 0,
  one: 1,
};

export const useSetUserCareerRating = ({
  careerId,
  onSuccess,
  invalidateUserCareerMatches,
}) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: `/users/me/career-ratings/${careerId}`,
    method: HttpMethod.Put,
  });

  return useMutation({
    mutationKey: ["set-user-career-ratings"],
    mutationFn: (rating) => mutationFn({ data: String(rating) }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-career-ratings"],
        exact: true,
        refetchType: invalidateUserCareerMatches ? undefined : "inactive",
      });

      if (invalidateUserCareerMatches) {
        queryClient.invalidateQueries({
          queryKey: ["career-matches"],
          refetchType: "inactive",
        });
      }

      onSuccess?.();
    },
  });
};
