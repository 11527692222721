import Skeleton from "react-loading-skeleton";

import "@features/ui/question/question.scss";

export const QuestionLoader = () => {
  return (
    <div className="question loader">
      <Skeleton
        height={"13.75rem"}
        width={"13.75rem"}
        circle
        containerClassName="tx-skeleton-container"
      />
      <Skeleton
        height={"1.625rem"}
        containerClassName="tx-skeleton-container"
      />
      <Skeleton
        width={"80%"}
        height={"1.625rem"}
        containerClassName="tx-skeleton-container"
      />
      <div className="answer-container">
        <Skeleton
          height={"5rem"}
          width={"5rem"}
          containerClassName="tx-skeleton-container"
        />
        <Skeleton
          height={"5rem"}
          width={"5rem"}
          containerClassName="tx-skeleton-container"
        />
      </div>
      <Skeleton
        width={"80%"}
        height={"0.9375rem"}
        containerClassName="tx-skeleton-container"
      />
    </div>
  );
};
