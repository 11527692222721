import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";

import { Button } from "@transfr-inc/dashboard-components/forms";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  ErrorToast,
  Loader,
} from "@features/ui";
import { useUpdateUserJourney } from "@pages/journey-map/api/use-update-user-journey";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";

import { buttonTracking } from "@lib/tracking.helpers";
import { useStartOver } from "@pages/journey-map/api/use-start-over";
import { useCreateInterestInventory } from "./api/use-create-interest-inventory";
import { useInterestInventory } from "./api/use-interest-inventory";
import { SURVEY_STATES, useSurveyState } from "./hooks/use-survey-state";

import { QuestionLoader } from "./components/question.loader";
import { Survey } from "./components/survey";
import { InterestInventoryStartOverConfirmationModal } from "./views/start-over-confirmation.modal";

import "./index.scss";

export const InterestInventory = ({ onExploreMatches, onClose }) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState();
  const [showFooter, setShowFooter] = useState();

  const {
    data: interestInventory,
    isError,
    refetch: refetchInterestInventory,
  } = useInterestInventory();

  const {
    mutateAsync: createInterestInventory,
    isPending: interestInventoryCreating,
  } = useCreateInterestInventory({ invalidateUserJourney: true });

  const { mutateAsync: updateUserJourney, isPending: userJourneyUpdating } =
    useUpdateUserJourney();

  const {
    data: { surveyCompletedAt },
    isFetching: isUserJourneyFetching,
  } = useUserJourney();

  const { isStartingOver, startOver } = useStartOver();

  const surveyState = useSurveyState();

  const {
    state: { name: surveyStateName },
    progress,
    cancelPreliminaryCareer,
  } = surveyState;

  const surveyLoading = useMemo(
    () =>
      !!interestInventory?.notFound ||
      interestInventoryCreating ||
      userJourneyUpdating,
    [interestInventory, interestInventoryCreating, userJourneyUpdating],
  );

  const loadingMessage = useMemo(() => {
    if (interestInventoryCreating || isStartingOver) {
      return openConfirmationModal
        ? "Resetting survey..."
        : "Creating survey...";
    } else if (userJourneyUpdating) {
      return "Submitting survey...";
    }
  }, [surveyLoading]);

  useEffect(() => {
    if (interestInventory?.questions) {
      surveyState.surveyQuestionsFetched(interestInventory.questions);
    }
  }, [interestInventory]);

  const onSurveyCompleted = async () => {
    if (!surveyCompletedAt && !isUserJourneyFetching) {
      await updateUserJourney({ surveyCompleted: true });
      await refetchInterestInventory();
    }
  };

  const closeStartOverConfirmation = () => setOpenConfirmationModal();

  const onConfirmStartOver = async () => {
    surveyState.startOver();
    await startOver({ restartInterestInventory: true });
    closeStartOverConfirmation();
  };

  useEffect(() => {
    if (interestInventory?.notFound) {
      createInterestInventory();
    }
  }, [interestInventory]);

  useEffect(() => {
    setShowFooter(progress > 0 || surveyStateName === SURVEY_STATES.getPrelim);
  }, [progress, surveyStateName]);

  return (
    <>
      <Card
        className={clsx("interest-inventory-card", !showFooter && "no-footer")}
      >
        <Loader show={isStartingOver || surveyLoading} text={loadingMessage} />
        <CardHeader
          title={<h1>Get Ready</h1>}
          toolbar={
            <Button
              onClick={onClose}
              icon={["fa-regular", "xmark"]}
              {...buttonTracking("interest-inventory", "close")}
            />
          }
        >
          <div className="subheader">Help us find career matches!</div>
        </CardHeader>
        <CardBody className="interest-inventory-card-body">
          {isError && <ErrorToast open />}
          {surveyLoading ? (
            <QuestionLoader />
          ) : (
            <Survey
              surveyId={interestInventory?.id}
              surveyState={surveyState}
              onQuestionAnswered={() => setShowFooter(true)}
              onSurveyCompleted={onSurveyCompleted}
            />
          )}
        </CardBody>
        {showFooter && (
          <CardFooter>
            {surveyCompletedAt && (
              <Button
                primary
                icon={["fa-regular", "stars"]}
                onClick={onExploreMatches}
                {...buttonTracking("interest-inventory", "explore-matches")}
              >
                Explore Matches
              </Button>
            )}
            {surveyStateName === SURVEY_STATES.getPrelim && (
              <Button
                onClick={cancelPreliminaryCareer}
                {...buttonTracking(
                  "interest-inventory",
                  "cancel-preliminary-career",
                )}
              >
                Cancel
              </Button>
            )}
            {progress > 0 && (
              <Button
                icon={["fa-regular", "arrow-rotate-left"]}
                onClick={() => setOpenConfirmationModal(true)}
                {...buttonTracking("interest-inventory", "start-over")}
              >
                Start Over
              </Button>
            )}
          </CardFooter>
        )}
      </Card>
      <InterestInventoryStartOverConfirmationModal
        open={openConfirmationModal}
        onClose={closeStartOverConfirmation}
        onCancel={closeStartOverConfirmation}
        onConfirm={onConfirmStartOver}
      />
    </>
  );
};

InterestInventory.displayName = "Interest Inventory Card";

InterestInventory.propTypes = {
  onClose: PropTypes.func,
  onExploreMatches: PropTypes.func,
};
