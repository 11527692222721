import PropTypes from "prop-types";

import { Section, SlideshowControlled } from "@features/ui";

import { useSlideShow } from "@hooks/use-slideshow";

import { SimulationSlide } from "@features/career-details/components";

import { careerSimulationType } from "../types";

import "./closer-look.section.scss";

export const CloserLookSection = ({ careerSimulations, userSimulations }) => {
  const slideshow = useSlideShow(careerSimulations?.length);
  const userSimulationsByCode = userSimulations.reduce((acc, m) => {
    acc[m.code] = m;
    return acc;
  }, {});

  return (
    careerSimulations?.length > 0 && (
      <Section className="closer-look-section">
        <SlideshowControlled
          className="simulations-slideshow"
          slideshow={slideshow}
          numberSlides={careerSimulations.length}
        >
          {careerSimulations.map((careerSimulation) => (
            <SimulationSlide
              key={careerSimulation.simulation.code}
              careerSimulation={careerSimulation}
              completedAt={
                userSimulationsByCode[careerSimulation.simulation.code]
                  ?.completedAt
              }
            />
          ))}
        </SlideshowControlled>
      </Section>
    )
  );
};

CloserLookSection.displayName = "Closer Look Section";

CloserLookSection.propTypes = {
  careerSimulations: PropTypes.arrayOf(careerSimulationType),
  userSimulations: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.number,
      completedAt: PropTypes.instanceOf(Date),
    }),
  ),
};
