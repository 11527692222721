import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { Heading, Section } from "@features/ui";

import "./user-info.section.scss";

export const UserInfoSection = ({ user }) => {
  const { firstName, lastName } = user;
  const fullName = `${firstName} ${lastName}`.trim();
  return (
    <Section className="user-info-section">
      <div className="avatar-container">
        <FontAwesomeIcon
          className="avatar-icon"
          icon={["fasl", "circle-user"]}
        />
      </div>
      <div className="info-container">
        <Heading>{fullName}</Heading>
      </div>
    </Section>
  );
};

UserInfoSection.displayName = "User Info Section";

UserInfoSection.propTypes = {
  user: PropTypes.object,
};
