/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { invalidate, useFrame, useGraph } from "@react-three/fiber";
import PropTypes from "prop-types";
import { forwardRef, useLayoutEffect, useMemo, useRef } from "react";
import { mergeRefs } from "react-merge-refs";
import { LoopOnce } from "three";
import { SkeletonUtils } from "three-stdlib";

import { dashAnimations } from "../hooks";

export const DashTheHedgehog = forwardRef(
  ({ animation, onActionFinished, ...props }, ref) => {
    const lastAnimation = useRef();
    const groupRef = useRef();

    const { scene, animations } = useGLTF("/models/dash-the-hedgehog.glb");
    const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
    const { nodes, materials } = useGraph(clone);
    const { actions } = useAnimations(animations, groupRef);

    const stopLastAction = () => {
      lastAnimation.current?.reset().stop();
    };

    const playAnimation = (animation) => {
      const { name, options = {} } = animation;
      lastAnimation.current = actions[name]?.play();

      if (options.once) {
        const mixer = lastAnimation.current.getMixer();
        const onFinished = () => {
          onActionFinished?.();
          stopLastAction();
          mixer.removeEventListener("finished", onFinished);
        };
        lastAnimation.current.setLoop(LoopOnce, 1);
        mixer.addEventListener("finished", onFinished);
      }

      if (options.duration) {
        lastAnimation.current.setDuration(options.duration);
      }
    };

    const onClickAnimation = ({ stopPropagation }) => {
      stopPropagation();
      playAnimation({ name: dashAnimations.Talking, options: { once: true } });
    };

    useFrame(() => {
      lastAnimation.current?.isRunning() && invalidate();
    });

    useLayoutEffect(() => {
      stopLastAction(animation);

      if (animation) {
        playAnimation(animation);
      }
    }, [animation]);

    return (
      <group ref={mergeRefs([groupRef, ref])} {...props}>
        <group name="Scene" onClick={onClickAnimation}>
          <group name="dash-RIG">
            <primitive object={nodes.root} />
            <primitive object={nodes["MCH-torsoparent"]} />
            <primitive object={nodes["MCH-hand_ikparentL"]} />
            <primitive object={nodes["MCH-upper_arm_ik_targetparentL"]} />
            <primitive object={nodes["MCH-hand_ikparentR"]} />
            <primitive object={nodes["MCH-upper_arm_ik_targetparentR"]} />
            <primitive object={nodes["MCH-foot_ikparentL"]} />
            <primitive object={nodes["MCH-thigh_ik_targetparentL"]} />
            <primitive object={nodes["MCH-foot_ikparentR"]} />
            <primitive object={nodes["MCH-thigh_ik_targetparentR"]} />
            <primitive object={nodes["MCH-lip_armBL001"]} />
            <primitive object={nodes["MCH-lip_armBR001"]} />
            <primitive object={nodes["MCH-lip_armTL001"]} />
            <primitive object={nodes["MCH-lip_armTR001"]} />
            <primitive object={nodes["MCH-nose_end_mix001"]} />
          </group>
          {/* Commented to avoid extra space in the Box3 size */}
          {/* <group name="fanny_pack-RIG003">
            <primitive object={nodes.root_1} />
            <primitive object={nodes["MCH-pack_strap_start01parentL"]} />
            <primitive object={nodes["MCH-pack_strap_mid01parentL"]} />
            <primitive object={nodes["MCH-pack_strap_mid02parentL"]} />
            <primitive object={nodes["MCH-pack_strap_mid03parentL"]} />
            <primitive object={nodes["MCH-pack_strap_endparentL"]} />
            <primitive object={nodes["MCH-pack_strap_end02parentL"]} />
            <primitive object={nodes["MCH-pack_strap_006parentL001"]} />
            <primitive object={nodes["MCH-pack_strap_start01parentR"]} />
            <primitive object={nodes["MCH-pack_strap_mid01parentR"]} />
            <primitive object={nodes["MCH-pack_strap_mid02parentR"]} />
            <primitive object={nodes["MCH-pack_strap_mid03parentR"]} />
            <primitive object={nodes["MCH-pack_strap_endparentR"]} />
            <primitive object={nodes["MCH-pack_strap_end02parentR"]} />
            <primitive object={nodes["MCH-pack_strap_006parentR001"]} />
          </group> */}
          {/* <group name="fishing_pole-RIG001">
            <primitive object={nodes.root_2} />
            <primitive object={nodes["MCH-string-start01parent"]} />
            <primitive object={nodes["MCH-string-mid01parent"]} />
            <primitive object={nodes["MCH-string-mid02parent"]} />
            <primitive object={nodes["MCH-string-endparent"]} />
            <primitive object={nodes["MCH-string-end02parent"]} />
            <primitive object={nodes["MCH-stringparent009"]} />
            <primitive object={nodes["MCH-strap-start01parent"]} />
            <primitive object={nodes["MCH-strap-mid01parent"]} />
            <primitive object={nodes["MCH-strap-mid02parent"]} />
            <primitive object={nodes["MCH-strap-mid03parent"]} />
            <primitive object={nodes["MCH-strap-end01parent"]} />
            <primitive object={nodes["MCH-strap-endparent"]} />
          </group> */}
          {/* <group name="fishing_pole-RIGstrapspline" /> */}
          <group name="GEO_dash_botTeeth001">
            <skinnedMesh
              name="Cylinder003"
              geometry={nodes.Cylinder003.geometry}
              material={materials.MAT_botTeeth}
              skeleton={nodes.Cylinder003.skeleton}
            />
            <skinnedMesh
              name="Cylinder003_1"
              geometry={nodes.Cylinder003_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cylinder003_1.skeleton}
            />
          </group>
          <skinnedMesh
            name="GEO_dash_cheek_fringeL"
            geometry={nodes.GEO_dash_cheek_fringeL.geometry}
            material={materials["MAT_face_fringe-2"]}
            skeleton={nodes.GEO_dash_cheek_fringeL.skeleton}
          />
          <skinnedMesh
            name="GEO_dash_cheek_fringeR"
            geometry={nodes.GEO_dash_cheek_fringeR.geometry}
            material={materials["MAT_face_fringe-2"]}
            skeleton={nodes.GEO_dash_cheek_fringeR.skeleton}
          />
          <group name="GEO_dash_earL">
            <skinnedMesh
              name="Plane007"
              geometry={nodes.Plane007.geometry}
              material={materials.MAT_ear}
              skeleton={nodes.Plane007.skeleton}
            />
            <skinnedMesh
              name="Plane007_1"
              geometry={nodes.Plane007_1.geometry}
              material={materials["MAT_inner-ear"]}
              skeleton={nodes.Plane007_1.skeleton}
            />
          </group>
          <group name="GEO_dash_earR">
            <skinnedMesh
              name="Plane003"
              geometry={nodes.Plane003.geometry}
              material={materials.MAT_ear}
              skeleton={nodes.Plane003.skeleton}
            />
            <skinnedMesh
              name="Plane003_1"
              geometry={nodes.Plane003_1.geometry}
              material={materials["MAT_inner-ear"]}
              skeleton={nodes.Plane003_1.skeleton}
            />
          </group>
          <skinnedMesh
            name="GEO_dash_face_fringe"
            geometry={nodes.GEO_dash_face_fringe.geometry}
            material={materials.MAT_face_fringe}
            skeleton={nodes.GEO_dash_face_fringe.skeleton}
          />
          <skinnedMesh
            name="GEO_dash_feetL"
            geometry={nodes.GEO_dash_feetL.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_dash_feetL.skeleton}
          />
          <skinnedMesh
            name="GEO_dash_feetR"
            geometry={nodes.GEO_dash_feetR.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_dash_feetR.skeleton}
          />
          <skinnedMesh
            name="GEO_dash_tongue"
            geometry={nodes.GEO_dash_tongue.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_dash_tongue.skeleton}
          />
          <group name="GEO_dash_topTeeth001">
            <skinnedMesh
              name="Cylinder001"
              geometry={nodes.Cylinder001.geometry}
              material={materials.MAT_topTeeth}
              skeleton={nodes.Cylinder001.skeleton}
            />
            <skinnedMesh
              name="Cylinder001_1"
              geometry={nodes.Cylinder001_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cylinder001_1.skeleton}
            />
          </group>
          {/* Commented to avoid extra space in the Box3 size */}
          {/* <group name="GEO_fanny_pack008">
            <skinnedMesh
              name="Cube004"
              geometry={nodes.Cube004.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube004.skeleton}
            />
            <skinnedMesh
              name="Cube004_1"
              geometry={nodes.Cube004_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube004_1.skeleton}
            />
          </group>
          <skinnedMesh
            name="GEO_fanny_pack009"
            geometry={nodes.GEO_fanny_pack009.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_pack009.skeleton}
          />
          <skinnedMesh
            name="GEO_fanny_pack010"
            geometry={nodes.GEO_fanny_pack010.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_pack010.skeleton}
          />
          <skinnedMesh
            name="GEO_fanny_pack011"
            geometry={nodes.GEO_fanny_pack011.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_pack011.skeleton}
          />
          <skinnedMesh
            name="GEO_fanny_packstrapL004"
            geometry={nodes.GEO_fanny_packstrapL004.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_packstrapL004.skeleton}
          />
          <skinnedMesh
            name="GEO_fanny_packstrapL005"
            geometry={nodes.GEO_fanny_packstrapL005.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_packstrapL005.skeleton}
          />
          <group name="GEO_fanny_pack_buckle-F002">
            <skinnedMesh
              name="Cube001_1"
              geometry={nodes.Cube001_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Cube001_1.skeleton}
            />
          </group>
          <skinnedMesh
            name="GEO_fanny_pack_zipper_pull_sml004"
            geometry={nodes.GEO_fanny_pack_zipper_pull_sml004.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_pack_zipper_pull_sml004.skeleton}
          />
          <skinnedMesh
            name="GEO_fanny_pack_zipper_pull_sml005"
            geometry={nodes.GEO_fanny_pack_zipper_pull_sml005.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fanny_pack_zipper_pull_sml005.skeleton}
          />
          <skinnedMesh
            name="CRV_fishing_pole_trap001"
            geometry={nodes.CRV_fishing_pole_trap001.geometry}
            material={materials.MAT_fishing_pole_wrap}
            skeleton={nodes.CRV_fishing_pole_trap001.skeleton}
          />
          <group name="GEO_fishingPole">
            <skinnedMesh
              name="BézierCurve002"
              geometry={nodes.BézierCurve002.geometry}
              material={materials.MAT_reel_handle_dark}
              skeleton={nodes.BézierCurve002.skeleton}
            />
            <skinnedMesh
              name="BézierCurve002_1"
              geometry={nodes.BézierCurve002_1.geometry}
              material={materials.MAT_fishing_pole_handle}
              skeleton={nodes.BézierCurve002_1.skeleton}
            />
            <skinnedMesh
              name="BézierCurve002_2"
              geometry={nodes.BézierCurve002_2.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.BézierCurve002_2.skeleton}
            />
            <skinnedMesh
              name="BézierCurve002_3"
              geometry={nodes.BézierCurve002_3.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.BézierCurve002_3.skeleton}
            />
            <skinnedMesh
              name="BézierCurve002_4"
              geometry={nodes.BézierCurve002_4.geometry}
              material={materials["MAT_fishing_pole_wrap.001"]}
              skeleton={nodes.BézierCurve002_4.skeleton}
            />
          </group>
          <skinnedMesh
            name="GEO_fishingPole_handle_wrap_top"
            geometry={nodes.GEO_fishingPole_handle_wrap_top.geometry}
            material={materials["MAT_fishing_pole_wrap.002"]}
            skeleton={nodes.GEO_fishingPole_handle_wrap_top.skeleton}
          />
          <skinnedMesh
            name="GEO_fishingPole_ring"
            geometry={nodes.GEO_fishingPole_ring.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fishingPole_ring.skeleton}
          />
          <skinnedMesh
            name="GEO_fishingPole_string"
            geometry={nodes.GEO_fishingPole_string.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fishingPole_string.skeleton}
          />
          <skinnedMesh
            name="GEO_fishingPole_string001"
            geometry={nodes.GEO_fishingPole_string001.geometry}
            material={materials.PaletteMaterial001}
            skeleton={nodes.GEO_fishingPole_string001.skeleton}
          /> */}
          <group name="GEO_dash_body">
            <skinnedMesh
              name="Plane006"
              geometry={nodes.Plane006.geometry}
              material={materials.MAT_body2}
              skeleton={nodes.Plane006.skeleton}
              morphTargetDictionary={nodes.Plane006.morphTargetDictionary}
              morphTargetInfluences={nodes.Plane006.morphTargetInfluences}
            />
            <skinnedMesh
              name="Plane006_1"
              geometry={nodes.Plane006_1.geometry}
              material={materials.PaletteMaterial001}
              skeleton={nodes.Plane006_1.skeleton}
              morphTargetDictionary={nodes.Plane006_1.morphTargetDictionary}
              morphTargetInfluences={nodes.Plane006_1.morphTargetInfluences}
            />
          </group>
          <skinnedMesh
            name="GEO_dash_eyeL"
            geometry={nodes.GEO_dash_eyeL.geometry}
            material={materials.PaletteMaterial002}
            skeleton={nodes.GEO_dash_eyeL.skeleton}
            morphTargetDictionary={nodes.GEO_dash_eyeL.morphTargetDictionary}
            morphTargetInfluences={nodes.GEO_dash_eyeL.morphTargetInfluences}
          />
          <skinnedMesh
            name="GEO_dash_eyeR"
            geometry={nodes.GEO_dash_eyeR.geometry}
            material={materials.PaletteMaterial002}
            skeleton={nodes.GEO_dash_eyeR.skeleton}
            morphTargetDictionary={nodes.GEO_dash_eyeR.morphTargetDictionary}
            morphTargetInfluences={nodes.GEO_dash_eyeR.morphTargetInfluences}
          />
          <skinnedMesh
            name="GEO_dash_quills"
            geometry={nodes.GEO_dash_quills.geometry}
            material={materials["MAT_underQuills.001"]}
            skeleton={nodes.GEO_dash_quills.skeleton}
            morphTargetDictionary={nodes.GEO_dash_quills.morphTargetDictionary}
            morphTargetInfluences={nodes.GEO_dash_quills.morphTargetInfluences}
          />
          {/* Commented to avoid extra space in the Box3 size */}
          {/* <skinnedMesh
            name="GEO_fanny_pack_buckle-M002"
            geometry={nodes["GEO_fanny_pack_buckle-M002"].geometry}
            material={materials.PaletteMaterial003}
            skeleton={nodes["GEO_fanny_pack_buckle-M002"].skeleton}
            morphTargetDictionary={
              nodes["GEO_fanny_pack_buckle-M002"].morphTargetDictionary
            }
            morphTargetInfluences={
              nodes["GEO_fanny_pack_buckle-M002"].morphTargetInfluences
            }
          /> */}
        </group>
      </group>
    );
  },
);

useGLTF.preload("/models/dash-the-hedgehog.glb");

DashTheHedgehog.displayName = "Dash";

DashTheHedgehog.propTypes = {
  animation: PropTypes.object,
  onActionFinished: PropTypes.func,
};
