import { forwardRef, useState } from "react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

import { Button } from "@transfr-inc/dashboard-components/forms";

import { SearchBox } from "@features/ui/searchbox/searchbox";
import { useSearchCareers } from "../api/use-search-careers";

import "./preliminary-careers.scss";
import "@features/ui/question/question.scss";

export const PreliminaryCareers = forwardRef(({ onAnswer }, elementRef) => {
  const [selectedCareerOption, setSelectedCareerOption] = useState();
  const [searchTerm, setSearchTerm] = useState();

  const { data: careersFound, isFetching: fetchingCareers } =
    useSearchCareers(searchTerm);

  const onOptionSelected = (option) => {
    setSelectedCareerOption(option);
  };

  return (
    <motion.div
      ref={elementRef}
      className="interest-inventory-question preliminary-careers-question"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="question-container">
        <h1>Please select the career you would prefer.</h1>
        <SearchBox
          clearInputEnabled
          customOptionLabel={(r) => `${r?.title}`}
          loading={fetchingCareers}
          onOptionSelected={onOptionSelected}
          onTermChange={setSearchTerm}
          placeholder="Search careers..."
          results={careersFound}
        />
      </div>
      <div className="answer-container">
        <Button
          primary
          onClick={() => onAnswer({ answer: selectedCareerOption })}
          disabled={!selectedCareerOption}
        >
          Submit
        </Button>
      </div>
    </motion.div>
  );
});

PreliminaryCareers.displayName = "Preliminary Careers Question";

PreliminaryCareers.propTypes = {
  onAnswer: PropTypes.func,
};
