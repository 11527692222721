import clsx from "clsx";
import PropTypes from "prop-types";

import "./card.scss";
import { forwardRef } from "react";

export const Card = forwardRef(({ className, children }, ref) => {
  return (
    <div ref={ref} className={clsx("card", className)}>
      {children}
    </div>
  );
});

Card.displayName = "Card";

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
