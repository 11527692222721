import { isNullish } from "@lib/app.helpers";
import { NUM_RATINGS_REQUIRED } from "./constants";

export const isInitialDataLoaded = (ctx) =>
  ctx.preliminaryCareersLoaded &&
  ctx.surveyQuestionsLoaded &&
  ctx.careerRatingsLoaded;

export const isPrelimComplete = (ctx) =>
  ctx.preliminaryCareersSkipped ||
  ctx.preliminaryCareers.length > 0 ||
  ctx.surveyQuestions.some(({ answer }) => !isNullish(answer));

export const isSurveyComplete = (ctx) =>
  ctx.surveyQuestions.every(({ answer }) => !isNullish(answer));

export const isRatingComplete = (ctx) =>
  ctx.careerRatings.length >= NUM_RATINGS_REQUIRED;
