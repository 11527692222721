import clsx from "clsx";
import PropTypes from "prop-types";

import { useUpdateLinkTracking } from "@hooks/use-update-link-tracking";

import "./copyright-footer.scss";

const FIRST_YEAR = 2024;

export const CopyrightFooter = ({ className }) => {
  const currentYear = new Date().getFullYear();

  useUpdateLinkTracking();

  return (
    <div className={clsx("copyright-footer", className)}>
      <div>
        Copyright &copy; {FIRST_YEAR}
        {currentYear > FIRST_YEAR ? ` - ${currentYear}` : ""} Transfr Inc. All
        Rights Reserved.
      </div>
      <div>
        <a
          href="https://transfrinc.com/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>{" "}
        &bull;
        <a
          href="https://transfrinc.com/terms/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Use
        </a>
      </div>
    </div>
  );
};

CopyrightFooter.propTypes = {
  className: PropTypes.string,
};
