import PropTypes from "prop-types";

import { Section } from "@features/ui";

import { CareerExpertQuestionsList } from "./career-expert-questions.list";
import { careerExpertQuestionType } from "src/types";

import "./career-expert-questions.section.scss";

export const CareerExpertQuestionsSection = ({ transfrId, questions }) => (
  <Section className="career-expert-questions-section">
    <div className="instructions">
      *Tap the card to show and hide the answer.
    </div>
    <CareerExpertQuestionsList transfrId={transfrId} questions={questions} />
  </Section>
);

CareerExpertQuestionsSection.propTypes = {
  transfrId: PropTypes.string,
  questions: PropTypes.arrayOf(careerExpertQuestionType),
};
