import PropTypes from "prop-types";

export * from "@features/career-details/types";
export * from "@features/career-expert/types";
export * from "@features/skills/types";

export const apiResponseType = (responseType) =>
  PropTypes.shape({
    response: responseType,
    loading: PropTypes.bool,
    error: PropTypes.bool,
  });

export const queryResponseType = (responseType) =>
  PropTypes.shape({
    data: responseType,
    isPending: PropTypes.bool,
    isError: PropTypes.bool,
  });
