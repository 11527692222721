import { validate as validateEmail } from "email-validator";
import { saveAs } from "file-saver";
import PropTypes from "prop-types";
import { useState } from "react";

import { Share } from "@features/share";
import { Loader, Sheet } from "@features/ui";

import { useAuth } from "@hooks/use-auth";
import { useDownloadPathwayPdf } from "@pages/journey-map/api/use-download-pathway-pdf";

import { useCareerPlan } from "@features/career-details/api/use-career-plan";
import { formatDate } from "@lib/date.helpers";
import { HttpMethod } from "@lib/http.helpers";
import { httpClient } from "src/core-platform-api";

export const ShareSheet = ({ open, onClose, onShare }) => {
  const [error, setError] = useState();
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const { user } = useAuth();

  const { data: careerPlan, isPending: isCareerPlanPending } = useCareerPlan();
  const { mutateAsync: requestPdf, isPending: isDownloadingPdf } =
    useDownloadPathwayPdf(user.userId);

  const doValidateEmail = (email) => {
    if (!email || validateEmail(email)) {
      setError();
    } else {
      setError({ type: "email", msg: "Email formatted incorrectly." });
    }
  };

  const doClose = () => {
    setError();
    onClose();
  };

  const sendEmail = (email) => {
    const { userId, token } = user;

    setIsSendingEmail(true);
    requestPdf(undefined, {
      onSuccess: (pdfBlob) => {
        const { firstName, lastName } = user;
        const fullName = `${firstName} ${lastName}`;

        const data = new FormData();
        data.set("email", email);
        data.set("full_name", fullName);
        data.set("career_title", careerPlan.career.title);
        data.set("pdf", pdfBlob);

        httpClient
          .request({
            method: HttpMethod.Post,
            url: `user/${userId}/trek/share-pathway`,
            data,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .catch((error_) => {
            if (
              error_.response?.data?.detail?.some((detail) =>
                detail.loc.includes("email"),
              )
            ) {
              setError({ type: "email", msg: "Invalid email address" });
            }
          });

        // Note: we're intentionally not waiting for the email to send before
        // indicating success.
        setIsSendingEmail(false);
        onShare?.();
      },
      onError: () => {
        setError({
          type: "pdf",
          msg: "Error generating PDF. Please try again.",
        });
      },
    });
  };

  const downloadPdf = () => {
    setError();

    requestPdf(undefined, {
      onSuccess: (pdfBlob) => {
        const { firstName, lastName } = user;
        saveAs(
          pdfBlob,
          `Transfr Trek Pathway - ${firstName} ${lastName} - ${formatDate()}`,
        );
      },
      onError: () => {
        setError({
          type: "pdf",
          msg: "Error generating PDF. Please try again.",
        });
      },
    });
  };

  return (
    <Sheet open={open} className="share-sheet">
      <Loader show={isCareerPlanPending} text="Loading..." />
      <Loader
        show={!isSendingEmail && isDownloadingPdf}
        text="Generating PDF..."
      />
      <Share
        error={error}
        onClose={doClose}
        onDownload={downloadPdf}
        onShare={sendEmail}
        onValidateEmail={doValidateEmail}
      />
    </Sheet>
  );
};

ShareSheet.displayName = "Share Sheet";

ShareSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onShare: PropTypes.func,
};
