/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

import {
  DashTheHedgehog,
  FishingPole,
  Obstacle,
  Stream,
} from "@pages/journey-map/3d-models";
import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { JourneyPin } from "@pages/journey-map/components";
import {
  useDashTheHedgehog,
  useHoverAnimation,
} from "@pages/journey-map/hooks";
import { arrayToVector } from "@pages/journey-map/normalize";

export const MapTwo = ({
  onMapAnimationComplete,
  onMoveToStep,
  triggerMapAnimation,
}) => {
  const dashRef = useRef();
  const dashInitialPosition = [70, 28, -150];
  const matchesPathPoint = [0, 25, 200];
  const matchesPathPointVector = arrayToVector(matchesPathPoint);

  const { data: journeySteps } = useJourneySteps();
  const matchesHoverAnimation = useHoverAnimation();

  // References for the jumping points, they're listed from back to front
  const firstJumpDown = useRef();
  const secondJumpDown = useRef();
  const thirdJumpDown = useRef();
  const firstJumpUp = useRef();
  const secondJumpUp = useRef();
  const thirdJumpUp = useRef();

  // These points will build the curve where Dash can be moved
  const pathPoints = [
    [70, 28, -150],
    [60, 33, -100],
    [40, 38, -70],
    [20, 38, -45],
    [15, 38, -40],
    [5, 25, -40],
    [0, 25, -30],
    [-15, 20, -30],
    [-15, 14, -30],
    [-25, 12, -30],
    [-30, 12, -25],
    [-35, 5, -25],
    [-45, 5, -25],
    [-50, 2, -25],
    [-50, 3, -20],
    [-80, 3, -5],
    [-90, 3, 60],
    [-70, 2, 130],
    [-60, 2, 140],
    [-50, 7, 140],
    [-50, 7, 150],
    [-40, 10, 160],
    [-30, 12, 170],
    [-10, 25, 200],
    matchesPathPoint,
  ];

  const dash = useDashTheHedgehog({
    ref: dashRef,
    initialPosition: dashInitialPosition,
    pathPoints,
    obstacles: [
      firstJumpDown.current,
      secondJumpDown.current,
      thirdJumpDown.current,
      firstJumpUp.current,
      secondJumpUp.current,
      thirdJumpUp.current,
    ],
  });

  const goToMatches = () => {
    dash.setDestination({
      point: matchesPathPointVector,
      onDestinationReached: () =>
        onMoveToStep(journeySteps.stepsByCode.MATCHES),
    });
  };

  useEffect(() => {
    if (triggerMapAnimation) {
      if (dash.isNearlytoPoint(matchesPathPointVector)) {
        onMapAnimationComplete?.();
      } else {
        dash.setDestination({ point: matchesPathPointVector });
      }
    }
  }, [triggerMapAnimation]);

  return (
    <group>
      <DashTheHedgehog
        ref={dashRef}
        position={dash.position}
        scale={75}
        castShadow
        receiveShadow
        animation={dash.animation}
        onActionFinished={dash.onAnimationFinished}
      />
      <group position={[30, 48, 210]} onClick={goToMatches}>
        <JourneyPin
          size={[50, 20]}
          position={[0, 100, 0]}
          rotation={[-0.4, Math.PI * -0.05, 0]}
          text={"Matches"}
          fontSize={10}
          hoverAnimation={matchesHoverAnimation}
        />
        <FishingPole
          scale={80}
          rotation={[0, Math.PI * 0.2, 0]}
          hoverAnimation={matchesHoverAnimation}
        />
      </group>

      <Stream
        position={[20, 0, 50]}
        scale={90}
        rotation={[0, Math.PI * 0.6, 0]}
      />

      {/* Obstacle to trigger jump animation */}
      <Obstacle
        ref={firstJumpDown}
        dimensions={[20, 20, 20]}
        position={[-10, 45, -20]}
      />

      <Obstacle
        ref={secondJumpDown}
        dimensions={[20, 20, 20]}
        position={[-35, 45, -20]}
      />

      <Obstacle
        ref={thirdJumpDown}
        dimensions={[20, 20, 20]}
        position={[-50, 45, -20]}
      />

      <Obstacle
        ref={firstJumpUp}
        dimensions={[20, 20, 20]}
        position={[-40, 10, 165]}
      />
      <Obstacle
        ref={secondJumpUp}
        dimensions={[20, 20, 20]}
        position={[-20, 30, 190]}
      />
      <Obstacle
        ref={thirdJumpUp}
        dimensions={[20, 20, 20]}
        position={[0, 40, 175]}
      />
    </group>
  );
};

MapTwo.propTypes = {
  onMapAnimationComplete: PropTypes.func,
  onMoveToStep: PropTypes.func,
  triggerMapAnimation: PropTypes.bool,
};
