import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import PropTypes from "prop-types";
import { useReducer } from "react";

import { useUpdateLinkTracking } from "@hooks/use-update-link-tracking";
import { Button } from "@transfr-inc/dashboard-components/forms";
import { LabeledToggle } from "./labeled-toggle";

import "./form.scss";

const initialState = {
  isParentPermissionRequired: true,
  isParentPermissionGranted: false,
  isAgreementAccepted: false,
};

const reducer = (state, [flag, value]) => {
  const newState = { ...state, [flag]: value };
  if (
    state.isParentPermissionRequired !== newState.isParentPermissionRequired
  ) {
    newState.isParentPermissionGranted = false;
    newState.isAgreementAccepted = false;
  } else if (
    state.isParentPermissionGranted !== newState.isParentPermissionGranted
  ) {
    newState.isAgreementAccepted = false;
  }

  return newState;
};

const AnimatedLabeledToggle = motion(LabeledToggle);
const AnimatedButton = motion(Button);

export const Form = ({ onSubmit }) => {
  useUpdateLinkTracking();

  const [
    {
      isParentPermissionRequired,
      isParentPermissionGranted,
      isAgreementAccepted,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const dispatchToggleChange =
    (flag, invert = false) =>
    (value) =>
      dispatch([flag, invert ? !value : value]);

  const tacLabel = (
    <span>
      {isParentPermissionRequired
        ? "Does your parent or guardian agree to Transfr's "
        : "I agree to the "}
      <a
        className="terms-and-conditions-link"
        href="https://transfrinc.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms and Conditions
      </a>
      {isParentPermissionRequired && "?"}
    </span>
  );

  return (
    <div className="terms-and-conditions-form">
      <h1>Terms & Conditions</h1>
      <LayoutGroup>
        <LabeledToggle
          label="Are you 18 or older?"
          value={!isParentPermissionRequired}
          onChange={dispatchToggleChange("isParentPermissionRequired", true)}
        />
        <AnimatePresence initial={false} mode="popLayout">
          {isParentPermissionRequired && (
            <AnimatedLabeledToggle
              key="parentPermission"
              layout
              layoutScroll
              initial={{ scaleY: 0, opacity: 0 }}
              animate={{ scaleY: 1, opacity: 1 }}
              exit={{ scaleY: 0, opacity: 0 }}
              transition={{
                duration: 0.2,
              }}
              style={{ originY: 0 }}
              label="Do you have your parent's or guardian's permission to use this app?"
              value={isParentPermissionGranted}
              onChange={dispatchToggleChange("isParentPermissionGranted")}
            />
          )}
        </AnimatePresence>
        <AnimatedLabeledToggle
          layout
          className="terms-and-conditions-toggle"
          disabled={isParentPermissionRequired && !isParentPermissionGranted}
          label={tacLabel}
          value={isAgreementAccepted}
          onChange={dispatchToggleChange("isAgreementAccepted")}
        />
        <AnimatedButton
          layout
          className="sign-up-button"
          disabled={!isAgreementAccepted}
          onClick={() =>
            onSubmit({
              isParentPermissionRequired,
              isParentPermissionGranted: isParentPermissionRequired
                ? isParentPermissionGranted
                : undefined,
              isAgreementAccepted,
            })
          }
        >
          Sign Up
        </AnimatedButton>
      </LayoutGroup>
    </div>
  );
};

Form.propTypes = {
  onSubmit: PropTypes.func,
};
