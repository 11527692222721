import clsx from "clsx";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { forwardRef, useContext } from "react";

import { SlideshowContext } from "./slideshow";

import "./slide.scss";

const variants = {
  enter: ({ direction, value }) => {
    return {
      x: -direction * value,
      opacity: 0,
    };
  },
  center: {
    x: 0,
    opacity: 1,
  },
  exit: ({ direction, value }) => {
    return {
      x: direction * value,
      opacity: 0,
    };
  },
};

export const Slide = forwardRef(({ className, children }, elementRef) => {
  const { movement, transitionDuration } = useContext(SlideshowContext);

  return (
    <motion.div
      ref={elementRef}
      className={clsx("slide-container", className)}
      custom={movement}
      variants={variants}
      initial="enter"
      animate="center"
      exit="exit"
      transition={{
        type: "tween",
        ease: "linear",
        duration: transitionDuration,
      }}
    >
      {children}
    </motion.div>
  );
});

Slide.displayName = "Slide";

Slide.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};
