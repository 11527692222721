import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import PropTypes from "prop-types";

import { EmptyArray } from "@lib/app.helpers";
import { Tooltip } from "@features/ui/tooltip";

import "./salary-rate.scss";

const SalaryRateValues = [
  {
    hourlyPay: 35,
    description: "Very high",
    rate: 4,
  },
  {
    hourlyPay: 25,
    description: "High",
    rate: 3,
  },
  {
    hourlyPay: 15,
    description: "Medium",
    rate: 2,
  },
  {
    hourlyPay: 0,
    description: "Low",
    rate: 1,
  },
];

const getHourlyRateObject = (value) => {
  return SalaryRateValues.find((rate) => value >= rate.hourlyPay);
};

export const SalaryRate = ({ value }) => {
  const { description, rate } = getHourlyRateObject(value);
  const salaryRateDescription = `${description} salary compared with other careers.`;

  const rateIcons = useMemo(() => {
    return EmptyArray(rate).map((_, index) => (
      <FontAwesomeIcon
        key={`coin-icon.${index}`}
        icon={["fa-regular", "circle-dollar"]}
      />
    ));
  }, [rate]);

  return (
    <div className="income-rate-container">
      <Tooltip content={salaryRateDescription} className="income-rate-tooltip">
        {rateIcons}
      </Tooltip>
    </div>
  );
};

SalaryRate.displayName = "Salary Rate";

SalaryRate.propTypes = {
  value: PropTypes.number,
};
