/* eslint react/no-unknown-property: 0 */

import { useGLTF } from "@react-three/drei";
import { invalidate } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";

export const Cave = ({ hoverAnimation = {}, onClick, ...props }) => {
  const { nodes, materials } = useGLTF("/models/cave.glb");
  return (
    <group {...props}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.GEO_front_plant.geometry}
        material={materials["MAT_leaf-1.002"]}
        position={[0.034, -0.078, -0.124]}
        rotation={[0.036, 0.046, -0.846]}
        scale={0.479}
      />
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onClick={onClick}
        onUpdate={() => invalidate()}
        position={[-0.031, 0.064, 0.117]}
        rotation={[0, -0.025, 0]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve034_dmg003.geometry}
          material={materials["MAT_river_cliff-1"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.BézierCurve034_dmg003_1.geometry}
          material={materials.MAT_door_cutouts}
        />
      </motion.group>
    </group>
  );
};

useGLTF.preload("/models/cave.glb");

Cave.propTypes = {
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
