import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { JobOutlook, PaymentRange } from "../components";

import "./employment-stats.tab.scss";

export const EmploymentStatsTab = ({
  earnings,
  national,
  outlookIndicator,
}) => {
  if (!earnings || !outlookIndicator) {
    return (
      <div className="employment-stats-tab">
        <Skeleton height={"1.25rem"} width={"11.25rem"} />
        <Skeleton height={"3.75rem"} width={"19.125rem"} />
        <Skeleton height={"2.5rem"} width={"11.25rem"} />
      </div>
    );
  }

  return (
    <div className="employment-stats-tab">
      <PaymentRange
        min={earnings.min}
        max={earnings.max}
        value={earnings.average}
      />
      <JobOutlook value={outlookIndicator} national={national} />
    </div>
  );
};

EmploymentStatsTab.displayName = "Employment Stats Tab View";

EmploymentStatsTab.propTypes = {
  earnings: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
    average: PropTypes.number,
  }).isRequired,
  national: PropTypes.bool,
  outlookIndicator: PropTypes.number,
};
