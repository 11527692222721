import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation } from "@tanstack/react-query";

export const useRequestResetPasswordToken = (onSuccess) => {
  const mutationFn = useApiRequest({
    url: `auth/password`,
    method: HttpMethod.Post,
    unversioned: true,
  });

  return useMutation({
    mutationKey: ["request-reset-password-token"],
    mutationFn: (email) => mutationFn({ data: { email } }),
    onSuccess: () => onSuccess?.(),
  });
};
