import PropTypes from "prop-types";

import { Goals } from "@features/goals";
import { Sheet } from "@features/ui";

import "./goals.sheet.scss";

export const GoalsSheet = ({ open, onClose, onRemovePlan, onSubmit }) => {
  return (
    <Sheet open={open} className="goals-sheet">
      <Goals
        onClose={onClose}
        onRemovePlan={onRemovePlan}
        onSubmit={onSubmit}
      />
    </Sheet>
  );
};

GoalsSheet.displayName = "Goals Sheet";

GoalsSheet.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onRemovePlan: PropTypes.func,
  onSubmit: PropTypes.func,
};
