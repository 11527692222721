import clsx from "clsx";
import PropTypes from "prop-types";
import Skeleton from "react-loading-skeleton";

import { Badge, InfoIcon } from "@features/ui";
import { pathwayStepType } from "src/types";

import "./career-pathway-step.scss";

export const CareerPathwayStep = ({ step, className }) => {
  const { stepNum, label, description } = step ?? {};

  if (!step) {
    return (
      <div className={clsx("career-pathway-step-container", className)}>
        <Skeleton height={"1.25rem"} width={"1.25rem"} />
        <Skeleton height={"1.25rem"} width={"11.25rem"} />
      </div>
    );
  }

  return (
    <div className={clsx("career-pathway-step-container", className)}>
      <Badge>{stepNum}</Badge>
      {label}
      {description && <InfoIcon description={description} />}
    </div>
  );
};

CareerPathwayStep.displayName = "Career Pathway Step";

CareerPathwayStep.propTypes = {
  step: pathwayStepType,
  className: PropTypes.string,
};
