import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateUserJourney = (onSuccess) => {
  const queryClient = useQueryClient();
  const mutationFn = useApiRequest({
    url: "/users/me/journey",
    method: HttpMethod.Post,
  });

  return useMutation({
    mutationKey: ["create-user-journey"],
    mutationFn: () => mutationFn({}),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user-journey"],
        exact: true,
      });
      queryClient.invalidateQueries({
        queryKey: ["user-journey-status"],
        exact: true,
      });
      onSuccess?.();
    },
  });
};
