import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { flatMapPaginatedData } from "@lib/pagination.helpers";

export const useCareerDetails = (transfrId, index) => {
  const queryClient = useQueryClient();

  const getMinimalData = () => {
    const minimalData =
      (
        flatMapPaginatedData(queryClient.getQueryData(["career-matches"])) ??
        queryClient
          .getQueryData(["user-favorite-careers"])
          ?.map(({ career }) => career)
      )?.find((c) => c.transfrId == transfrId) ?? {};

    return {
      cluster: { name: "" },
      regional: undefined,
      national: undefined,
      ...minimalData,
    };
  };

  const minimalData = useMemo(() => getMinimalData(), [transfrId]);

  const queryFn = useApiRequest({
    url: `/careers/${transfrId}`,
    method: HttpMethod.Get,
    headers: {
      "trk-evt-meta": btoa(JSON.stringify({ list_rank: index })),
    },
  });

  const query = useQuery({
    queryKey: ["career-details", transfrId],
    queryFn: queryFn,
    placeholderData: getMinimalData,
  });

  return {
    ...query,
    minimalData,
  };
};
