import clsx from "clsx";
import PropTypes from "prop-types";

import { InfoIcon } from "@features/ui";
import { JobOutlookIcon, JobOutlookIconType } from "./job-outlook.icon";

import "./job-outlook.scss";

const outlookMessage = {
  veryHigh: {
    message: "Very Promising",
    description:
      "In the future, the demand for this career will grow much faster than average.",
    icon: JobOutlookIconType.arrowUp,
  },
  high: {
    message: "Promising",
    description:
      "In the future, the demand for this career will continue to grow.",
    icon: JobOutlookIconType.arrowUp,
  },
  normal: {
    message: "Will stay the same",
    description:
      "In the future, the demand for this career will stay where it is at now.",
  },
  low: {
    message: "likely to decline",
    icon: JobOutlookIconType.arrowDown,
    description:
      "In the future, this demand for this career will probably decrease.",
  },
  noData: {
    message: "No available data",
    description:
      "It looks like we don't have enough data to accurately predict. Check out “National” data to get a better sense of industry trends.",
    icon: JobOutlookIconType.ban,
  },
  noDataNational: {
    message: "No available data",
    description:
      "It looks like we don't have enough data to accurately predict.",
    icon: JobOutlookIconType.ban,
  },
};

const getOutlookMessage = (value, national) => {
  if (value === null) {
    return national ? outlookMessage.noDataNational : outlookMessage.noData;
  }

  if (value >= 2) {
    return outlookMessage.veryHigh;
  } else if (value >= 1.1) {
    return outlookMessage.high;
  } else if (value >= 0.9) {
    return outlookMessage.normal;
  } else {
    return outlookMessage.low;
  }
};

export const JobOutlook = ({ value, className, national }) => {
  const { icon, message, description } = getOutlookMessage(value, national);

  return (
    <div className={clsx("job-outlook-container", className)}>
      <div className="title-container">Job Opportunities</div>
      <div className="message-container">
        {icon && <JobOutlookIcon type={icon} />}
        <span>{message}</span>
        {description && <InfoIcon description={description} />}
      </div>
    </div>
  );
};

JobOutlook.displayName = "Job Outlook Icon";

JobOutlook.propTypes = {
  value: PropTypes.number,
  className: PropTypes.string,
  national: PropTypes.bool,
};
