import clsx from "clsx";
import PropTypes from "prop-types";

import { ListItem, OverflowText } from "@features/ui";
import { HeadsetRating } from "@pages/favorites/components/headset-rating";

import "./favorite-item.scss";

export const FavoriteItem = ({
  index,
  career,
  headsetRating,
  selected,
  onClick,
}) => {
  const { title, shortDescription } = career;

  return (
    <ListItem
      index={index}
      className={clsx("favorite-item", selected && "selected")}
      onClick={() => onClick(career)}
    >
      <div className="header">
        <span>{title}</span>
      </div>
      <OverflowText className="description" value={shortDescription} />
      {headsetRating && <HeadsetRating rating={headsetRating} />}
    </ListItem>
  );
};

FavoriteItem.propTypes = {
  index: PropTypes.number,
  career: PropTypes.object,
  headsetRating: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
