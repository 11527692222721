import { useNavigate } from "react-router-dom";

import { CopyrightFooter, Title } from "@features/ui";
import { HeaderImage } from "@pages/login/components/header-image";

import { ForgotPasswordForm } from "./components/forgot-password.form";
import { ForgotPasswordInstructions } from "./components/forgot-password.instructions";
import { useRequestResetPasswordToken } from "./api/use-send-reset-password-email";

import headerImageUrl from "@images/trek-logo.medium.svg?url";

import "./index.scss";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();

  const {
    mutateAsync: sendResetPasswordEmail,
    isPending,
    isSuccess: formSubmittedSuccesfully,
    error,
  } = useRequestResetPasswordToken();

  const onResetPassword = async (e, email) => {
    e.preventDefault();
    await sendResetPasswordEmail(email);
  };

  const onCancel = () => {
    navigate("/login", { replace: true });
  };

  return (
    <div className="forgot-password-page">
      <HeaderImage imageUrl={headerImageUrl} />
      <div className="tx-container">
        <Title>Forgot Password</Title>
        <ForgotPasswordInstructions formSubmitted={formSubmittedSuccesfully} />
      </div>
      {!formSubmittedSuccesfully && (
        <ForgotPasswordForm
          onResetPassword={onResetPassword}
          onCancel={onCancel}
          error={error}
          loading={isPending}
        />
      )}
      <CopyrightFooter />
    </div>
  );
};
