import PropTypes from "prop-types";

import { ErrorToast, Section, SubHeading } from "@features/ui";
import { EmptyArray } from "@lib/app.helpers";
import { pathwayStepType, queryResponseType } from "src/types";
import { CareerPathwayStep } from "../components";

import "./pathway.section.scss";

export const PathwaySection = ({ pathwaySteps }) => {
  const { data: steps, isPending, isError } = pathwaySteps;

  return (
    <Section className="pathway-section">
      <SubHeading>Pathway at a Glance</SubHeading>
      <div className="pathway-steps-container">
        {isPending ? (
          EmptyArray(6).map((_, index) => (
            <CareerPathwayStep key={`step.${index}`} />
          ))
        ) : isError ? (
          <ErrorToast open message="Error loading pathway" />
        ) : (
          steps?.map((step) => (
            <CareerPathwayStep key={`step.${step.stepNum}`} step={step} />
          ))
        )}
      </div>
    </Section>
  );
};

PathwaySection.propTypes = {
  pathwaySteps: queryResponseType(PropTypes.arrayOf(pathwayStepType)),
};
