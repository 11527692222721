import { HttpStatusCode } from "axios";
import { useQuery } from "@tanstack/react-query";

import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";

export const useInterestInventoryQueryOptions = () => {
  const queryFn = useApiRequest({
    url: "/users/me/survey",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["interest-inventory"],
    queryFn: async () => {
      return await queryFn({}).catch((error) => {
        const { status } = error;
        if (status === HttpStatusCode.NotFound) {
          return { notFound: status };
        }

        throw error;
      });
    },
    refetchOnMount: "always",
  };
};

export const useInterestInventory = () => {
  return useQuery(useInterestInventoryQueryOptions());
};
