import { useApiRequest } from "@hooks/use-api-request";
import { HttpMethod } from "@lib/http.helpers";
import { useMutation } from "@tanstack/react-query";

export const useValidateResetToken = (onSuccess) => {
  const mutationFn = useApiRequest({
    url: "auth/password",
    method: HttpMethod.Post,
    unversioned: true,
  });

  return useMutation({
    mutationKey: ["validate-reset-password-token"],
    mutationFn,
    onSuccess: () => onSuccess?.(),
  });
};
