import { useCallback } from "react";

import { httpClient } from "../api";

import { useAuth } from "@hooks/use-auth";

export const useApiRequest = ({
  url,
  method,
  apiVersion,
  unversioned = false,
  ...hookParams
}) => {
  const { user } = useAuth();

  const request = useCallback(
    async ({ data, params, ...requestParams } = {}) => {
      const requestConfig = {
        url,
        method,
        apiVersion,
        unversioned,
        data,
        params,
        ...hookParams,
        ...requestParams,
      };

      if (user?.token) {
        const { token, orgCode } = user;

        requestConfig.headers = {
          ...requestConfig.headers,
          Authorization: `Bearer ${token}`,
          Organization: orgCode,
          "Content-Type": "application/json",
        };
      }

      return httpClient.request(requestConfig);
    },
    [
      url,
      method,
      apiVersion,
      unversioned,
      user,
      hookParams.params,
      hookParams.data,
    ],
  );

  return request;
};
