import clsx from "clsx";
import PropTypes from "prop-types";

import "./title.scss";

export const Title = ({ children, className }) => {
  return <h2 className={clsx("tx-title", className)}>{children}</h2>;
};

Title.displayName = "Title";

Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
