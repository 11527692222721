/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { Html, useTexture } from "@react-three/drei";

import bottomRightIsland from "@images/journey-map/bottom-right-island.png";
import centerIsland from "@images/journey-map/center-island.png";
import goals from "@images/journey-map/goals.png";
import lock from "@images/journey-map/lock.png";
import share from "@images/journey-map/share.png";
import topLeftIsland from "@images/journey-map/top-left-island.png";
import colors from "@styles/colors.module.scss";

import { Tooltip } from "@features/ui/tooltip";
import {
  CubicBezierLines,
  JourneyButton,
  JourneyImage,
  JourneyTitle,
} from "@pages/journey-map/components";

import { useJourneySteps } from "@pages/journey-map/api/use-journey-steps";
import { useUserJourney } from "@pages/journey-map/api/use-user-journey";

import "./map-four.scss";

export const MapFour = ({ isPlanShared, unlockedSteps, onMoveToStep }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const textures = useTexture({
    bottomRightIsland,
    centerIsland,
    topLeftIsland,
    lock,
    goals,
    share,
  });

  const { data: journeySteps } = useJourneySteps();
  const { data: userJourney } = useUserJourney();

  const isShareLocked = !unlockedSteps[journeySteps.stepsByCode.SHARE.stepNum];

  useEffect(() => {
    if (!isShareLocked) {
      setShowTooltip(false);
    }
  }, [isShareLocked]);

  return (
    <group>
      <JourneyImage
        position={[-19, -211]}
        size={[145, 123]}
        texture={textures.topLeftIsland}
        transparent
      />
      <JourneyImage
        position={[44, -346]}
        size={[266, 231]}
        texture={textures.centerIsland}
        transparent
      >
        <Html
          position={[74.5, 42.5, 0]}
          zIndexRange={[1, 0]}
          wrapperClass="map-four-tooltip-wrapper"
        >
          <Tooltip
            className="dash-goals-tooltip"
            content="Set up goals for your hedge fund!"
            open={isShareLocked}
          >
            <div>&nbsp;</div>
          </Tooltip>
        </Html>
      </JourneyImage>
      <JourneyImage
        position={[212, -561]}
        size={[195, 171]}
        texture={textures.bottomRightIsland}
        transparent
      />
      <JourneyTitle
        description="Pathway:"
        careerTitle={userJourney.currentJourneyCareer?.title}
        backgroundColor={colors.paleGreen}
      />
      <JourneyButton
        position={[240, -264, 0.1]}
        size={[75, 52]}
        labelSize={[-1, 24]}
        labelOffset={[0, 58]}
        text="Share"
        textPositionAdjust={isShareLocked ? [8, 0] : [0, 0]}
        texture={textures.share}
        imageOpacity={isShareLocked ? 0.3 : 1}
        backgroundColor={colors.paleGreen}
        onClick={async () => {
          const didMove = await onMoveToStep(journeySteps.stepsByCode.SHARE);
          if (!didMove) {
            setShowTooltip((show) => !show);
          }
        }}
      >
        <Html
          position-x={37.5}
          zIndexRange={[1, 0]}
          wrapperClass="map-four-tooltip-wrapper"
        >
          <Tooltip
            className="share-tooltip"
            content={
              isShareLocked
                ? "Set up goals for your pathway before sharing."
                : "We've shared your plan!"
            }
            open={isPlanShared || (showTooltip && isShareLocked)}
          >
            <div>&nbsp;</div>
          </Tooltip>
        </Html>
      </JourneyButton>
      {isShareLocked && (
        <JourneyImage
          position={[248, -327, 0.3]}
          size={[12.5, 14]}
          texture={textures.lock}
          backgroundColor={colors.paleGreen}
          transparent
        />
      )}
      <JourneyButton
        position={[72.73, -576.66, 0.1]}
        size={[83.27, 66.34]}
        labelSize={[-1, 24]}
        labelOffset={[1.27, 60.34]}
        text="My Goals"
        texture={textures.goals}
        backgroundColor={colors.paleGreen}
        onClick={() => {
          onMoveToStep(journeySteps.stepsByCode.GOALS);
          setShowTooltip(false);
        }}
      />
      <group position={[220, -575, 0]}>
        <CubicBezierLines
          points={[
            [20, -100],
            [-20, -65],
            [15, -40],
            [10, 0],
          ]}
          segments={100}
          lineWidth={6}
          gapSize={13}
          dashSize={13}
          dashed
          color={colors.mediumLightGreenCyan}
        />
      </group>
      <group position={[120, -303, 0]}>
        <CubicBezierLines
          points={[
            [37, -60],
            [42, -45],
            [7, -13],
            [4, -2],
          ]}
          segments={100}
          lineWidth={6}
          gapSize={13}
          dashSize={13}
          dashed
          color={colors.mediumLightGreenCyan}
        />
      </group>
    </group>
  );
};

MapFour.propTypes = {
  isPlanShared: PropTypes.bool,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
  onMoveToStep: PropTypes.func,
};
