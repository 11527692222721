import { useState } from "react";

import { TextureLoader } from "three";
import { FontLibrary } from "three-mesh-ui";

export const useFonts = (fonts) => {
  const [loadingFonts, setLoadingFonts] = useState(new Set());
  const [availableFonts, setAvailableFonts] = useState(new Set());

  const newFonts = fonts.filter(
    (font) => !loadingFonts.has(font.name) && !availableFonts.has(font.name),
  );

  if (newFonts.length > 0) {
    const textureLoader = new TextureLoader();
    setLoadingFonts(new Set(newFonts.map((font) => font.name)));

    for (const font of newFonts) {
      textureLoader.load(font.texture, (texture) => {
        FontLibrary.addFont(font.name, font.family, texture);
        setAvailableFonts((f) => new Set(f).add(font.name));
      });
    }

    return true;
  }

  return fonts.some((font) => !availableFonts.has(font.name));
};
