/* eslint react/no-unknown-property: 0 */

import PropTypes from "prop-types";
import { forwardRef } from "react";

export const JourneyImage = forwardRef(
  (
    {
      children,
      opacity = 1,
      position: [x, y, z],
      scale: [scaleX, scaleY] = [1, 1],
      size: [width, height],
      texture,
      transparent,
      visible = true,
      onClick,
    },
    elementRef,
  ) => (
    <mesh
      ref={elementRef}
      // x/y positions need to be adjusted by the width/height of the image to
      // translate from center-based to top-left-based coordinates.
      position={[x + width / 2, y - height / 2, z ?? 0]}
      scale={[scaleX, scaleY, 1]}
      onClick={onClick}
    >
      <planeGeometry args={[width, height]} />
      <meshBasicMaterial
        transparent={transparent}
        map={texture}
        visible={visible}
        opacity={opacity}
      />
      {children}
    </mesh>
  ),
);

JourneyImage.displayName = "Journey Image";

JourneyImage.propTypes = {
  children: PropTypes.node,
  opacity: PropTypes.number,
  position: PropTypes.arrayOf(PropTypes.number),
  scale: PropTypes.arrayOf(PropTypes.number),
  size: PropTypes.arrayOf(PropTypes.number),
  texture: PropTypes.object,
  transparent: PropTypes.bool,
  visible: PropTypes.bool,
  onClick: PropTypes.func,
};
