import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

import { buttonTracking } from "@lib/tracking.helpers";

import "./breadcrumb.scss";

export const Breadcrumb = ({ className, onClick, children }) => {
  const navigate = useNavigate();

  return (
    <button
      className={clsx("breadcrumb-container", className)}
      type="button"
      onClick={() => (onClick ? onClick() : navigate(-1))}
      {...buttonTracking("breadcrumb", children)}
    >
      <FontAwesomeIcon icon="fa-regular fa-arrow-up" rotation={270} />
      {children}
    </button>
  );
};

Breadcrumb.displayName = "Breadcrumb";

Breadcrumb.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};
