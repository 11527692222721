import { isNullish } from "@lib/app.helpers";
import { reduce } from "robot3";

export const preliminaryCareersReducer = reduce((ctx, { data }) => ({
  ...ctx,
  preliminaryCareers: [...ctx.preliminaryCareers, data],
}));

export const surveyQuestionsReducer = reduce((ctx) => ({
  ...ctx,
  currentSurveyQuestionIndex: ctx.surveyQuestions.findIndex((q) =>
    isNullish(q.answer),
  ),
}));

export const surveyAnswersReducer = reduce((ctx, { data: { id, answer } }) => {
  const surveyQuestions = ctx.surveyQuestions.map((q) => {
    if (q.id === id) {
      q.answer = answer;
    }

    return q;
  });

  return surveyQuestionsReducer.fn({ ...ctx, surveyQuestions });
});

export const careerRatingsReducer = reduce((ctx) => {
  const { careerMatches, careerRatings } = ctx;
  const ratedCareers = new Set(careerRatings.map(({ careerId }) => careerId));
  const currentCareerMatchIndex = careerMatches.findIndex(
    (career) => !ratedCareers.has(career.id),
  );

  return {
    ...ctx,
    careerRatings,
    currentCareerMatchIndex,
  };
});

export const careerRatingsAnswerReducer = reduce(
  (ctx, { data: { careerId, rating } }) => {
    const careerRatings = [...ctx.careerRatings, { careerId, rating }];

    return careerRatingsReducer.fn({ ...ctx, careerRatings });
  },
);
