import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Skeleton from "react-loading-skeleton";

import { Badge, Card, CardBody, CardHeader } from "@features/ui";

import { useUserFavoriteCareers } from "@pages/favorites/api/use-user-favorite-careers";
import { FavoritesList } from "@pages/favorites/components/favorites.list";

import "./favorites.scss";

export const Favorites = ({ onFavoriteClick }) => {
  const {
    data: favorites,
    isError: favoritesErrored,
    isFetching: fetchingFavorites,
  } = useUserFavoriteCareers();

  return (
    <Card className="favorites-card">
      <CardHeader
        title={
          <>
            <h1>
              <FontAwesomeIcon icon={["fa-regular", "stars"]} />
              <span>Favorites</span>
              {fetchingFavorites ? (
                <Skeleton width="1.5rem" height="2rem" />
              ) : (
                favorites?.length > 0 && <Badge>{favorites.length}</Badge>
              )}
            </h1>
            <div className="subheader">
              We collect careers that you have starred in Trek below.
            </div>
          </>
        }
        className="favorites-card-header"
      />
      <CardBody className="favorites-card-body">
        {fetchingFavorites || favorites?.length > 0 ? (
          <FavoritesList
            favorites={favorites}
            isError={favoritesErrored}
            isLoading={fetchingFavorites}
            onFavoriteClick={onFavoriteClick}
          />
        ) : (
          <div className="no-favorites">
            You don’t have any favorited careers.
          </div>
        )}
      </CardBody>
    </Card>
  );
};

Favorites.propTypes = {
  onFavoriteClick: PropTypes.func,
};
