import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Tag } from "./tag";

import "./hidden.tag.scss";

export const HiddenTag = () => {
  return (
    <Tag
      className="tx-hidden-tag"
      value={
        <>
          <FontAwesomeIcon icon={["fasr", "eye-slash"]} />
          <span>hidden</span>
        </>
      }
    />
  );
};
