/* eslint react/no-unknown-property: 0 */

import { useAnimations, useGLTF } from "@react-three/drei";
import { invalidate, useFrame } from "@react-three/fiber";
import { motion } from "framer-motion-3d";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";

const FloatingAnimationName = "ANIM_pack_float";

export const FannyPack = ({
  hoverAnimation = {},
  onClick,
  animated,
  ...props
}) => {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF("/models/fanny-pack.glb");
  const { actions } = useAnimations(animations, group);

  useFrame(({ invalidate }) => {
    actions[FloatingAnimationName].isRunning() && invalidate();
  });

  useEffect(() => {
    const animation = actions[FloatingAnimationName];
    if (animated) {
      animation?.play();
    } else {
      animation?.stop();
    }
  }, [animated]);

  return (
    <group ref={group} {...props}>
      <motion.group
        onPointerOver={hoverAnimation.onPointerOver}
        onPointerOut={hoverAnimation.onPointerOut}
        variants={hoverAnimation.variants}
        animate={hoverAnimation.animate}
        onUpdate={() => invalidate()}
        onClick={onClick}
        name="Scene"
      >
        <group
          name="GEO_fanny_pack"
          position={[-0.194, 0.447, 0.057]}
          rotation={[0.206, -0.128, -0.528]}
        >
          <mesh
            name="Cube004"
            castShadow
            receiveShadow
            geometry={nodes.Cube004.geometry}
            material={materials.PaletteMaterial001}
          />
          <mesh
            name="Cube004_1"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_1.geometry}
            material={materials.PaletteMaterial002}
          />
          <mesh
            name="Cube004_2"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_2.geometry}
            material={materials.PaletteMaterial003}
          />
          <mesh
            name="Cube004_3"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_3.geometry}
            material={materials.PaletteMaterial004}
          />
          <mesh
            name="Cube004_4"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_4.geometry}
            material={materials.PaletteMaterial005}
          />
          <mesh
            name="Cube004_5"
            castShadow
            receiveShadow
            geometry={nodes.Cube004_5.geometry}
            material={materials.PaletteMaterial006}
          />
        </group>
        <mesh
          name="GEO_log"
          castShadow
          receiveShadow
          geometry={nodes.GEO_log.geometry}
          material={materials.Wood}
          position={[0.074, 0.103, -0.005]}
        />
        <mesh
          name="GEO_log_leaf"
          castShadow
          receiveShadow
          geometry={nodes.GEO_log_leaf.geometry}
          material={materials["MAT_leaf-1"]}
          position={[0.15, 0.208, -0.069]}
        />
      </motion.group>
    </group>
  );
};

useGLTF.preload("/models/fanny-pack.glb");

FannyPack.propTypes = {
  animated: PropTypes.bool,
  hoverAnimation: PropTypes.object,
  onClick: PropTypes.func,
};
