import clsx from "clsx";
import PropTypes from "prop-types";

import "./course.list.scss";
import Skeleton from "react-loading-skeleton";

export const CourseList = ({ courses, courseType, className }) => {
  return (
    <div className={clsx("course-list", className)}>
      <span className="header">{courseType} courses</span>
      <ul>
        {courses.map((c, index) =>
          c ? (
            <li key={`${c.type}.${c.id}`}>{c.name}</li>
          ) : (
            <Skeleton
              key={`${courseType}.${index}`}
              containerClassName="tx-skeleton-container"
              width={index % 2 === 1 ? "11rem" : "17.875rem"}
            />
          ),
        )}
      </ul>
    </div>
  );
};

CourseList.propTypes = {
  courses: PropTypes.array,
  courseType: PropTypes.string,
  className: PropTypes.string,
};
