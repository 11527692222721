/* eslint react/no-unknown-property: 0 */
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { extend, invalidate, useFrame, useThree } from "@react-three/fiber";
import { useMotionValue } from "framer-motion";
import { motion } from "framer-motion-3d";
import ThreeMeshUI from "three-mesh-ui";

import poppinsRegularFamily from "@fonts/Poppins-Regular-msdf.json";
import poppinsRegularTexture from "@fonts/Poppins-Regular.png";
import poppinsSemiBoldFamily from "@fonts/Poppins-SemiBold-msdf.json";
import poppinsSemiBoldTexture from "@fonts/Poppins-SemiBold.png";

import { useFonts } from "@pages/journey-map/hooks/use-fonts";
import { MapFour } from "./map-four";
import { MapOne } from "./map-one";
import { MapThree } from "./map-three";
import { MapTwo } from "./map-two";

import { STEP_TO_MAP } from "@context/journey.context";

import {
  PROTO_DIMENSIONS,
  normalizePosition,
  normalizeSize,
} from "../../normalize";

extend(ThreeMeshUI);

export const JourneyMapCanvas = ({
  dimensions,
  mapToTriggerAnimation,
  onMapAnimationComplete,
  onMoveToMap,
  onMoveToStep,
  scrollAnimationDuration,
  showMap,
  unlockedSteps,
  isPlanShared,
}) => {
  const [fontsLoaded, setFontsLoaded] = useState(false);
  const isLoadingFonts = useFonts([
    {
      name: "Poppins-Regular",
      family: poppinsRegularFamily,
      texture: poppinsRegularTexture,
    },
    {
      name: "Poppins-SemiBold",
      family: poppinsSemiBoldFamily,
      texture: poppinsSemiBoldTexture,
    },
  ]);
  const { viewport } = useThree();
  useFrame(() => {
    if (!isLoadingFonts && !fontsLoaded) {
      // If we try to update as soon as fonts are loaded they sometimes don't
      // render. This _seems_ to fix the problem...
      setFontsLoaded(true);
    } else {
      ThreeMeshUI.update();
    }
  });

  const { top, bottom, height: mapHeight } = dimensions;
  const { height: vHeight } = viewport;
  // Force a 1:1.5 aspect ratio.
  const mapWidth = mapHeight * 0.6667;

  const mapYPosition = useMotionValue(0);

  // Since we do on-demand updates, we need to tell R3F to re-render when
  // animating.
  useEffect(
    () => mapYPosition.on("change", () => invalidate()),
    [mapYPosition],
  );

  if (isLoadingFonts) {
    return <mesh />;
  }

  const visibleHeight =
    ((vHeight + top + bottom) / mapHeight) * PROTO_DIMENSIONS.height;

  return (
    // Put the top of the map right below the header, centered horizontally.
    <group
      position={[0, vHeight / 2 - mapHeight / 2 - top, 0]}
      // Scale the map up to match the desired height
      scale={[mapWidth, mapHeight, 1]}
    >
      {/* Scale everything to normalize the coordinates against the prototype
      dimensions. Including `-142` in the position normalization here is simply
      a convenience so that the coordinates used in the maps can be taken
      directly from the prototype which has a header height of 142 pixels. */}
      <group position={normalizePosition(0, -142)} scale={normalizeSize(1, 1)}>
        <motion.group
          position-y={mapYPosition}
          animate={{
            y: -(showMap - 1) * visibleHeight,
          }}
          transition={{ duration: scrollAnimationDuration }}
        >
          <group position-y={3 * visibleHeight}>
            <MapFour
              isPlanShared={isPlanShared}
              onMoveToStep={onMoveToStep}
              unlockedSteps={unlockedSteps}
            />
          </group>
          <group position-y={2 * visibleHeight}>
            <MapThree onMoveToStep={onMoveToStep} />
          </group>
          <group position-y={visibleHeight}>
            <MapTwo onMoveToStep={onMoveToStep} />
          </group>
          <group>
            <MapOne
              onMoveToStep={onMoveToStep}
              onMoveToMap={onMoveToMap}
              triggerMapAnimation={
                mapToTriggerAnimation === STEP_TO_MAP.INT_INV
              }
              onMapAnimationComplete={onMapAnimationComplete}
            />
          </group>
        </motion.group>
      </group>
    </group>
  );
};

JourneyMapCanvas.defaultProps = {
  scrollAnimationDuration: 0.5,
};

JourneyMapCanvas.propTypes = {
  dimensions: PropTypes.shape({
    top: PropTypes.number,
    bottom: PropTypes.number,
    height: PropTypes.number,
  }),
  mapToTriggerAnimation: PropTypes.number,
  onMapAnimationComplete: PropTypes.func,
  onMoveToMap: PropTypes.func,
  onMoveToStep: PropTypes.func,
  scrollAnimationDuration: PropTypes.number,
  showMap: PropTypes.number,
  unlockedSteps: PropTypes.arrayOf(PropTypes.bool),
  isPlanShared: PropTypes.bool,
};
