import { HttpStatusCode } from "axios";
import { useQuery } from "@tanstack/react-query";

import { HttpMethod } from "@lib/http.helpers";
import { useApiRequest } from "@hooks/use-api-request";

const placeholderData = { currentJourneyStep: { code: "BEGIN", stepNum: 0 } };

export const useUserJourneyQueryOptions = () => {
  const queryFn = useApiRequest({
    url: "/users/me/journey",
    method: HttpMethod.Get,
  });

  return {
    queryKey: ["user-journey"],
    queryFn: async () => {
      return await queryFn({}).catch((error) => {
        const { status } = error;
        if (status === HttpStatusCode.NotFound) {
          return { ...placeholderData, status };
        }

        throw error;
      });
    },
    placeholderData,
  };
};

export const useUserJourney = () => {
  return useQuery(useUserJourneyQueryOptions());
};
